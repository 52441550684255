import { useSelector } from 'react-redux';
const useViewPermission = (menu) => {
  const role = useSelector((state) => state.user.role);

  const isOwn = () => {
    return getPermission('own');
  };

  const isBranch = () => {
    return getPermission('branch');
  };

  const isAll = () => {
    return getPermission('all');
  };

  const getPermission = (type) => {
    const foundPermission = role.role.view_permission.root.children.find((e) => e.name === menu);
    const permit = foundPermission?.children.find((e) => e.name === type && e.status);
    return permit;
  };
  return {
    isAll,
    isOwn,
    isBranch,
  };
};

export default useViewPermission;
