import i18n from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import * as lang from './lang';

/**
 *  TODO tsa - anyone - if you want to add some value or keyword,plz notice and sort the value alphabetically
 *  use this link
 *  easy link to sort json :  https://novicelab.org/jsonabc/
 */

export const FONT_CN = 'zh';
export const FONT_EN = 'en';

export const LANGUAGES = [
  {
    name: 'English',
    value: FONT_EN,
    icon: '/flags/en.jpg',
  },
  {
    name: '中文',
    value: FONT_CN,
    icon: '/flags/china.png',
  },
];

i18n.use(LanguageDetector).init({
  resources: {
    en: {
      translation: {
        header: {
          title_text: 'Logistics Management System',
        },
        sidebar: lang.sidebar_en,
        waybill_entry: lang.waybill_entry_en,

        /**
         * information
         */
        information_other_item_type: lang.information_other_item_type_en,
        information_other_express_type: lang.information_other_express_type_en,
        information_other_carrieer: lang.information_other_carrieer_en,
        informtaion_other_payment_method: lang.information_other_payment_method_en,
        information_employee_maintenance: lang.information_employee_maintenance_en,
        information_branch: lang.information_branch_en,
        information_other_shipping_mode: lang.information_other_shipping_mode_en,
        information_other_currency: lang.information_other_currency_en,
        information_other_truck: lang.information_other_truck_en,
        information_container: lang.information_other_container_en,
        position_management: lang.posistion_management_en,
        collection_management: lang.collection_management_en,
        address_book: lang.address_book_en,

        /**
         * Announcement
         */
        announcement_list: lang.announcement_list_en,
        announcement_maintain: lang.announcement_maintain_en,

        /**
         * Cargo diversion center
         */
        cargo_loading: lang.cargo_loading_en,
        cargo_unloading: lang.cargo_unloading_en,
        distinction_inventory: lang.distinction_inventory_en,
        shipping_inventory: lang.shipping_inventory_en,

        /**
         * Sign
         */
        sign: lang.sign_en,
        /**
         * Finance
         */
        finance: lang.finance_en,

        /**
         * system
         */
        system_user_management: lang.system_user_management_en,
        system_role_management: lang.system_role_management_en,
        customer_service: lang.customer_service_en,

        /** formik */
        formik: lang.formik_en,

        /**
         * common
         */
        branch: 'branch',
        distination: 'destination',
        country: 'country',
        region: 'region',
        district: 'district',
        township: 'township',
        payment_group: 'Payment Group',
        submit: 'Submit',
        cancel: 'cancel',
        upload_file: 'Upload File',
        add: 'Add New',
        duration: 'Duration (Days)',
        refresh: 'Refresh',
        back: 'Back',
        close: 'Close',
        clear: 'Clear',
        search: 'Search',
        update: 'Update',
        delete: 'Delete',
        reset: 'Reset',
        print: 'Print',
        export: 'Export',
        select: 'Select',
        logout: 'Logout',
        updated_at: 'Updated At',
        created_at: 'Created At',
        updated_by: 'Updated By',
        created_by: 'Created By',
        no: 'No.',
        truck_no: 'truck_no',
        type: 'type',
        size: 'size',
        id: 'ID Number',
        '-': '-',
        open: 'Open',
        confirm: 'Confirm',
        confirm_delete: 'Are you sure want to delete',
        name: 'Name',
        phonenumber: 'phonenumber',
        date: 'Date',
        nodata: 'No data',
        loading: 'Loading...',
        order: 'Order',
        order_status: 'received',
        total: 'Total QTY',
        qty: 'Qty',
        amount: 'Amount',
        totalCount: 'Total',
        cbm: 'CBM',
        description: 'Description',
        is_required: 'is required',
        is_invalid: 'is invalid',
        remark: 'Remark',
        edit: 'Edit',
        code: 'Code',
        position: 'Position',
        employee: 'Employee',
        address: 'Address',
        actions: 'Actions',
        customer: 'Customer',
        choose: 'Choose',
        information: 'Information',
        basic: 'Basic',
        company: 'Company',
        voucher_type: 'Voucher Type',
        confirm_by: 'Confirm By',
        start_date: 'Start Date',
        end_date: 'End Date',
        activate: 'Activate',
        marker: 'Marker',
        list: 'List',
        row_per_view: 'Row Per Page',
        load: 'Load',
        unload: 'Unload',
        status: 'Status',
        success: 'Success',
        total_waybill: 'Total Waybill',
        new_users: 'Total Users',
        total_sales: 'Total Sales',
        transcations: 'Transcations',
        total_cost: 'Total Cost',
        estimated_arrival_date: 'Estimated Arrival Date',
        sale_value: 'Sale Value',
        overview: 'Overview',
        performance: 'Performance',
        total_orders: 'Total Orders',
        verified: 'Verified',
        menu_permision: 'Menu Permission',
        action_permission: 'Action Permission',
        view_permissiion: 'View Permission',
        cargo: 'Cargo',
        ok: 'Ok',
        prefer_contact: 'Preferred Contact Method',
        business_description: 'Business Description',
        limit_value: 'Limited Value',
      },
    },

    zh: {
      translation: {
        header: {
          title_text: '物流管理系统',
        },
        sidebar: lang.sidebar_zh,
        waybill_entry: lang.waybill_entry_zh,

        /**
         * information
         */
        information_other_item_type: lang.information_other_item_type_zh,
        information_other_express_type: lang.information_other_express_type_zh,
        information_other_carrieer: lang.information_other_carrieer_zh,
        informtaion_other_payment_method: lang.information_other_payment_method_zh,
        information_employee_maintenance: lang.information_employee_maintenance_zh,
        information_branch: lang.information_branch_zh,
        information_other_shipping_mode: lang.information_other_shipping_mode_zh,
        information_other_currency: lang.information_other_currency_zh,
        information_other_truck: lang.information_other_truck_zh,
        information_container: lang.information_other_container_zh,
        position_management: lang.posistion_management_zh,
        collection_management: lang.collection_management_zh,
        address_book: lang.address_book_zh,
        /**
         * Announcement
         */
        announcement_list: lang.announcement_list_zh,
        announcement_maintain: lang.announcement_maintain_zh,

        /**
         * Cargo Diversion Center
         */
        cargo_loading: lang.cargo_loading_zh,
        cargo_unloading: lang.cargo_unloading_zh,
        distinction_inventory: lang.distinction_inventory_zh,
        shipping_inventory: lang.shipping_inventory_zh,
        customer_service: lang.customer_service_zh,
        /**
         * Sign
         */
        sign: lang.sign_zh,

        /**
         * finance
         */
        finance: lang.finance_zh,

        /**
         * system
         */
        system_user_management: lang.system_user_management_zh,
        system_role_management: lang.system_role_management_zh,

        /** formik */
        formik: lang.formik_zh,

        /**
         * common
         */
        branch: '网点',
        distination: '目的地',
        country: '国',
        region: '省',
        district: '区',
        estimated_arrival_date: 'Estimated Arrival Date',
        township: '市',
        submit: '提交',
        cancel: '取消',
        upload_file: '上传文件',
        add: '增加',
        refresh: '刷新',
        back: '返回',
        close: '关闭',
        clear: '清除',
        search: '搜索',
        update: '更新',
        delete: '删除',
        reset: '重启',
        print: '打印',
        payment_group: '支付集团',
        export: '导出',
        select: '选择',
        updated_at: '更新时间',
        created_at: '创建时间',
        updated_by: '更新人',
        created_by: '创建人',
        id: '身份证号',
        logout: '登出',
        no: '运单。',
        unload: 'Unload',
        truck_no: '卡车号',
        type: '类型',
        size: '尺寸',
        '-': '-',
        confirm: '确定',
        open: '打开',
        confirm_delete: '确定删除吗？',
        name: '姓名',
        phonenumber: '电话',
        date: '日期',
        nodata: '没有数据',
        loading: '加载 ...',
        order: '订单',
        order_status: '已收到',
        total: 'Total QTY',
        totalCount: '全部的',
        cbm: 'CBM',
        description: '描述',
        is_required: '是必须的',
        is_invalid: '是无效的',
        remark: '评论',
        edit: '编辑',
        code: '代码',
        position: '位置',
        employee: '员工',
        address: '地址',
        actions: '行动',
        customer: '顾客',
        choose: '选择',
        information: '信息',
        company: '公司',
        basic: '基本的',
        voucher_type: '凭证类型',
        confirm_by: '确认人',
        start_date: '开始日期',
        duration: 'Duration (Days)',
        end_date: '结束日期',
        activate: '启用',
        marker: '标记',
        row_per_view: '每页行',
        load: '加载',
        status: '地位',
        success: '成功',
        total_waybill: '总运单',
        list: '列表',
        new_users: '新用户',
        total_sales: '总销售额',
        transcations: '交易',
        total_cost: '总花费',
        sale_value: '销售价值',
        overview: '概述',
        performance: '表现',
        total_orders: '总订单',
        verified: '已验证',
        qty: '数量',
        amount: '額',
        menu_permision: '菜单权限',
        action_permission: '操作权限',
        cargo: '货物',
        ok: 'Ok',
        prefer_contact: 'Preferred Contact Method',
        business_description: 'Business Description',
        limit_value: '有限的价值',
        view_permissiion: 'View Permission',
      },
    },
  },
  debug: true,
  ns: ['translation'],
  defaultNS: 'translation',
  fallbackLng: FONT_EN,
  keySeparator: '.',
  interpolation: {
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
});

export default i18n;
