/* eslint-disable no-useless-escape */
export const passwordValidateArr = (currentPassword) => [
  {
    content: 'At least 6 characters ( minimum )',
    isValid: currentPassword?.toString()?.length >= 6,
  },
  {
    content: 'At least 1 upper case letter (A..Z)',
    isValid: /[A-Z]/.test(currentPassword),
  },
  {
    content: 'At least 1 lower case letter (a..z)',
    isValid: /[a-z]/.test(currentPassword),
  },
  {
    content: 'At least 1 number (0..9)',
    isValid: /[0-9]/.test(currentPassword),
  },
  {
    content: 'At least 1 special character',
    isValid: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/i.test(currentPassword),
  },
];
