import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import TextBox from 'components/Inputs/TextBox';
import { t } from 'i18next';
function FilterBox({ onSearch }) {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };
  const branches = useSelector((state) => state.information.branches);
  let branchOptions = [];
  if (branches.map)
    branchOptions = branches?.map((e) => {
      return {
        label: e.label,
        value: e._id,
      };
    });
  const positions = useSelector((state) => state.information.positions);
  const positionOptions = positions?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });
  const [searchType, setSearchType] = React.useState('branch');
  const [search, setSearch] = React.useState();
  return (
    <Row>
      <Col md='5'>
        <Select
          styles={customStyles}
          defaultValue={{ value: 'branch', label: t('branch') }}
          onChange={(val) => {
            setSearch('');
            setSearchType(val.value);
          }}
          options={[
            { value: 'branch', label: t('branch') },
            { value: 'position', label: t('position') },
            { value: 'name', label: t('name') },
            { value: 'phonenumber', label: t('phonenumber') },
          ]}
        />
      </Col>
      <Col md='5'>
        {searchType === 'branch' && (
          <Select styles={customStyles} options={branchOptions} onChange={(value) => setSearch(value.value)} />
        )}
        {searchType === 'position' && (
          <Select styles={customStyles} options={positionOptions} onChange={(value) => setSearch(value.value)} />
        )}
        {(searchType === 'name' || searchType === 'phonenumber') && (
          <TextBox onChange={(e) => setSearch(e.target.value)} style={{ height: 34 }} />
        )}
      </Col>

      {searchType && (
        <Button
          size='sm'
          color='success'
          style={{ height: 35, position: 'relative', left: 0 }}
          onClick={() => {
            if (search) {
              setSearch(null);
              const searchData = {};
              searchData[searchType] = search;
              onSearch(searchData);
            }
          }}
        >
          <i className='fa fa-search'></i>
        </Button>
      )}
    </Row>
  );
}

export default FilterBox;
