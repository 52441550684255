import React from 'react';
import { Modal, ModalBody, Button, Form, Row, Col } from 'reactstrap';
// import * as EmployeeAction from 'store/actions/employee';
// import { getCountryOption } from 'store/actions/location';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
// import NumberInput from 'components/Inputs/NumberInput';
import './add-dialog.scss';
import TextBox from 'components/Inputs/TextBox';
import DatePicker from 'components/Inputs/DatePicker';
import SelectBox from 'components/SelectBox';
import { t } from 'i18next';
import { customRound } from 'utli';
import { NotificationManager } from 'react-notifications';

export default function AddDialog({ title, modalOpen, onToggle, onSubmit, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    getValues,
    watch,
  } = useForm();

  const [currency, setCurrency] = React.useState('');

  React.useEffect(() => {
    setValue('billingAmount', data?.remaining_balance);
    setValue('rbc', data?.remaining_balance);
    setValue('name', data?.receiver?.name);
    setValue('phonenumber', data?.receiver?.phone);
  }, [data]);

  const submitData = async (values) => {
    if (Number(values.paidAmount) + Number(values.discount) > customRound(Number(getValues('billingAmount')))) {
      NotificationManager.error('Paid Amount and discount is greather than remaning balance');
      return;
    }

    reset();
    values.purchase_date = values.purchase_date || new Date();
    if (!values.exchangeRate) {
      values.exchangeRate = 0;
    }

    if (!values.paid_in_currency) {
      values.paid_in_currency = values.paidAmount;
    }

    setCurrency('');
    onSubmit(values);
  };

  const collections = useSelector((state) => state.other_maintenance.collections);
  const collectionTypesOptions = collections?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  return (
    <Modal
      isOpen={modalOpen}
      size='lg'
      style={{
        maxWidth: '600px',
        width: '75%',
        minWidth: 400,
      }}
      backdrop={true}
    >
      <ModalBody style={{ maxHeight: '90vh', overflowY: 'auto' }} className='finance-modal'>
        <Form onSubmit={handleSubmit(submitData)} className='pb-5'>
          <h2 className='mb-3'>{title}</h2>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.biller_name')}</label>
            </Col>
            <Col md='8'>
              <TextBox
                className='form-control'
                placeholder={t('finance.biller_name')}
                type='text'
                registerProps={register('name', {
                  required: `${t('finance.biller_name')} ${t('is_required')}`,
                })}
                errors={errors.name}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.biller_phonenumber')}</label>
            </Col>
            <Col md='8'>
              <TextBox
                className='form-control'
                placeholder={t('finance.biller_phonenumber')}
                type='text'
                registerProps={register('phonenumber', {
                  required: `${t('finance.biller_phonenumber')} ${t('is_required')}`,
                })}
                errors={errors.phonenumber}
              />
            </Col>
          </Row>
          <Row>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.purchase_date')}</label>
            </Col>
            <Col md='8'>
              <DatePicker
                onChange={(date) => {
                  setValue('purchase_date', date);
                }}
                initialValue={new Date()}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.reference_number')}</label>
            </Col>
            <Col md='8'>
              <TextBox
                className='form-control'
                placeholder={t('finance.reference_number')}
                type='text'
                registerProps={register('reference_number')}
                errors={errors.reference_number}
              />
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.billing_amount')}</label>
            </Col>
            <Col md='8'>
              <TextBox
                className='form-control'
                placeholder={t('finance.billing_amount')}
                type='text'
                value={customRound(getValues('billingAmount'))}
                disabled={true}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.collection_type')}</label>
            </Col>
            <Col md='8'>
              <SelectBox
                options={collectionTypesOptions}
                label={t('finance.collection_type')}
                control={control}
                name='collection'
                rules={{ required: true }}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.currency')}</label>
            </Col>
            <Col md='8'>
              <Row className='mb-3'>
                <Col xs={6}>
                  <SelectBox
                    options={[
                      { label: 'CNY', value: 'CNY' },
                      { label: 'MMK', value: 'MMK' },
                    ]}
                    label={t('finance.currency')}
                    control={control}
                    name='currency'
                    rules={{ required: true }}
                    defaultValue={'CNY'}
                    onValueChange={(value) => {
                      setCurrency(value);
                      setValue('paid_in_currency', 0);
                      setValue('exchangeRate', 0);
                      setValue('paidAmount', 0);
                      setValue('discount', 0);
                      setValue('rbc', customRound(data?.remaining_balance));
                    }}
                  />
                </Col>
                <Col xs={6}>
                  <TextBox
                    className='form-control'
                    outputPrepend={getValues('currency') === 'MMK' ? 'MMK' : 'Yuan'}
                    value={getValues('paid_in_currency')}
                    onChange={(e) => {
                      const value = e.target.value;
                      let veriredValue = value.replace(',', '');
                      veriredValue = veriredValue.trim();
                      setValue('paidAmount', Number(veriredValue));
                      setValue('paid_in_currency', Number(veriredValue));

                      setValue('rbc', customRound(parseInt(getValues('billingAmount')) - parseInt(veriredValue)));
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.exchange_rate')}</label>
            </Col>
            <Col md='8'>
              <TextBox
                className='form-control'
                outputPrepend={'Yuan'}
                placeholder={t('finance.exchange_rate')}
                type='number'
                registerProps={register('exchangeRate')}
                defaultValue={0}
                errors={errors.exchangeRate}
                disabled={currency !== 'MMK'}
                onChange={(e) => {
                  const value = e.target.value;
                  let exchangeRate = value === '' ? 0 : Number(value);
                  let currency = getValues('paid_in_currency');

                  let paidAmount = customRound(currency / exchangeRate);
                  setValue('paidAmount', Number(paidAmount));
                  //setValue('exchangeRate', exchangeRate)

                  setValue(
                    'rbc',
                    customRound(
                      parseInt(customRound(getValues('billingAmount'))) - (paidAmount === Infinity ? 0 : paidAmount)
                    )
                  );
                }}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.paid_amount')}</label>
            </Col>
            <Col md='8'>
              <TextBox
                outputPrepend={'Yuan'}
                className='form-control'
                placeholder={t('finance.paid_amount')}
                type='text'
                value={customRound(watch('paidAmount'))}
                disabled
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.discount')}</label>
            </Col>
            <Col md='8'>
              <TextBox
                outputPrepend={'Yuan'}
                className='form-control'
                placeholder={t('finance.discount')}
                type='text'
                registerProps={register('discount')}
                defaultValue={0}
                onChange={(event) => {
                  const rbc =
                    customRound(Number(getValues('billingAmount')) - Number(getValues('paidAmount'))) -
                    Number(event.target.value);
                  setValue('rbc', rbc);
                }}
              />
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.remaining_balance')}</label>
            </Col>
            <Col md='8'>
              <TextBox
                outputPrepend={'Yuan'}
                className='form-control'
                placeholder={t('finance.remaining_balance')}
                value={customRound(watch('rbc'))}
                disabled={true}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>Remark</label>
            </Col>
            <Col md='8'>
              <TextBox
                className='form-control'
                placeholder={'Remark'}
                type='text'
                registerProps={register('remark')}
                errors={errors.remark}
              />
            </Col>
          </Row>

          <div className='custom-modal-footer text-center'>
            <Button color='success' type='button' onClick={() => onToggle(!modalOpen)}>
              {t('cancel')}
            </Button>
            <Button color='primary' type='submit'>
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
