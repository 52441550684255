import React from 'react';
import { AiTwotoneCheckCircle } from 'react-icons/ai';
import { GiCheckMark } from 'react-icons/gi';

const ValidatePassword = ({ content = '', isValid = false }) => {
  return (
    <span
      style={{
        fontSize: '14px',
        display: 'block',
        color: isValid ? 'green' : 'gray',
      }}
    >
      {isValid ? <GiCheckMark /> : <AiTwotoneCheckCircle size='8px' />} {'  '}
      {content}
    </span>
  );
};

export default ValidatePassword;
