import * as CurrencyService from 'services/currency';
import { SET_CURRENCY } from 'store/types/other_maintenance';
export const getAll = (query, refresh) => {
  return async (dispatch, getState) => {
    if (getState().other_maintenance.currencies.length > 0 && !refresh) {
      return;
    }
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await CurrencyService.getAll(query);
    dispatch({
      type: SET_CURRENCY,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const save = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await CurrencyService.save(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const update = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await CurrencyService.update(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteData = (id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await CurrencyService.deleteData(id);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};
