import * as RoleService from 'services/roleService';
import { SET_ROLE, SET_USERS } from 'store/types/role';
export const getRole = (refresh = false) => {
  return async (dispatch, getState) => {
    if ((getState().role.roles.length > 0) & !refresh) {
      return;
    }
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await RoleService.getRole();
    dispatch({
      type: SET_ROLE,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const getUser = (values = {}) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });

    let response = await RoleService.getUsers(values);
    dispatch({
      type: SET_USERS,
      payload: response.docs,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const createUser = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    let response = await RoleService.createUser(values);
    dispatch({
      type: SET_USERS,
      payload: response.docs,
    });
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const createRole = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await RoleService.createRole(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const updateRoleName = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await RoleService.updateRoleName(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await RoleService.deleteUser(id);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const updateUserRole = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await RoleService.updateUserRole(values);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};
export const resetUserPassword = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await RoleService.resetUserPassword(values);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const changeUserActiveStatus = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await RoleService.updateUserActiveStatus(values);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const updateUserWaybillPermission = (permisions, id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await RoleService.updateUserWaybillPermission(permisions, id);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};
