import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import TextBox from 'components/Inputs/TextBox';
import DatePicker from 'components/Inputs/DatePicker';
import { t } from 'i18next';
function FilterBox({ onSearch }) {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };

  const branches = useSelector((state) => state.information.branches);
  let branchOptions = [];
  if (branches.map) {
    branchOptions = branches?.map((e) => {
      return {
        label: e.label,
        value: e._id,
      };
    });
  }

  const paymentGroupOptions = [
    {
      label: 'Cash',
      value: 'cash',
    },
    {
      label: 'Credit',
      value: 'credit',
    },
  ];

  const [searchType, setSearchType] = React.useState('waybillnumber');
  const [search, setSearch] = React.useState();
  const [shippingDate, setShippingDate] = React.useState();
  const [signDate, setSignDate] = React.useState();
  const [payment_group, setPaymentGroup] = React.useState('');
  return (
    <>
      <Row>
        <Col md='2'>
          <Select
            styles={customStyles}
            options={paymentGroupOptions}
            onChange={(value) => setPaymentGroup(value.value)}
            value={paymentGroupOptions.find((b) => b._id === payment_group)}
          />
        </Col>
        <Col md='3' sm='6'>
          {searchType === 'destination' && (
            <Select
              styles={customStyles}
              options={branchOptions}
              onChange={(value) => setSearch(value.value)}
              value={branchOptions.find((b) => b._id === search)}
            />
          )}
          {['customer_code', 'waybillnumber', 'sender_name', 'receiver_name'].includes(searchType) && (
            <TextBox
              onChange={(e) => setSearch(e.target.value)}
              style={{ height: 34 }}
              value={typeof search === 'object' ? '' : search}
              placeholder={`Search by ${searchType}`}
            />
          )}
        </Col>
        <Col md='2' sm='6'>
          <Select
            styles={customStyles}
            defaultValue={{
              value: 'waybillnumber',
              label: t('finance.waybill'),
            }}
            onChange={(val) => {
              setSearch('');
              if (val.value === 'signed_waybill') {
                setSearch(true);
              }
              setSearchType(val.value);
            }}
            options={[
              { value: 'waybillnumber', label: t('finance.waybill') },
              { value: 'signed_waybill', label: t('finance.signed_waybill') },
              { value: 'customer_code', label: t('finance.customer_code') },
              { value: 'sender_name', label: t('finance.sender_name') },
              { value: 'receiver_name', label: t('finance.receiver_name') },
              { value: 'payment_group', label: 'Payment Group' },
            ]}
          />
        </Col>

        <Col md='2' sm='3' xs='5' style={{ height: 35 }}>
          <DatePicker
            placeholder='Shipping Date'
            onChange={(date) => {
              setShippingDate(date);
            }}
            value={shippingDate}
          />
        </Col>
        <Col md='2' sm='3' xs='5' style={{ height: 35 }}>
          <DatePicker
            placeholder='Sign Date'
            onChange={(date) => {
              setSignDate(date);
            }}
            initialValue={''}
            value={signDate}
          />
        </Col>

        {searchType && (
          <Button
            size='sm'
            color='success'
            style={{ height: 35 }}
            onClick={() => {
              if (search || shippingDate || signDate || payment_group) {
                setShippingDate('');
                setSignDate('');
                const searchData = {};
                if (search) searchData[searchType] = typeof search === 'string' ? search.trim() : search;
                if (shippingDate) {
                  searchData.shipping_date = shippingDate;
                }
                if (signDate) {
                  searchData.sign_date = new Date(signDate);
                }

                if (payment_group) {
                  searchData.payment_group = payment_group;
                }

                onSearch(searchData);
              }
            }}
          >
            <i className='fa fa-search' />
          </Button>
        )}
      </Row>
    </>
  );
}

export default FilterBox;
