import React from 'react';
import { Button } from 'reactstrap';
import AddNewButton from 'components/ActionButtons/addNewButton';
import List from './list';
import { getRole, createRole, updateRoleName } from 'store/actions/role';
import { useDispatch, useSelector } from 'react-redux';
import ManageDialog from './manageDialog';
import ActionManageDialog from './actionManageDialog';
import ViewManageDialog from './viewManageDialog';
import AddDialog from './addDialog';
import { updatePermission, updateActionPermission, updateViewPermissionAction } from 'services/roleService';
import { useTranslation } from 'react-i18next';
import useActionPermission from 'hooks/useActionPermission';
function RoleManagment() {
  const headers = [
    '',
    'no',
    'name',
    'created_at',
    'updated_at',
    'menu_permision',
    'action_permission',
    'view_permissiion',
  ];
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const roles = useSelector((state) => state.role.roles);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [addModalOpen, setAddModelOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [editActionData, setEditActionData] = React.useState(null);
  const [modalActionOpen, setModalActionOpen] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState([]);
  const { canEdit } = useActionPermission('role');

  const [modelViewPermission, setModalViewPermissionOpen] = React.useState(null);
  const [viewPermissionData, setEditViewPermissionData] = React.useState(null);

  React.useEffect(() => {
    dispatch(getRole(false));
  }, [dispatch, getRole]);

  const update_Permission = async (permission, status) => {
    const body = {
      role: editData?._id,
      permission,
      status,
    };
    const response = await updatePermission(body);
    setEditData(response.data);
    setTimeout(() => dispatch(getRole(true)), 1000);
  };

  const updateViewPermission = async (permission, status) => {
    const body = {
      role: viewPermissionData?._id,
      permission,
      status,
    };
    const response = await updateViewPermissionAction(body);
    setEditViewPermissionData(response.data);
    setTimeout(() => dispatch(getRole(true)), 1000);
  };

  const update_ActionPermission = async (permission, status) => {
    const body = {
      role: editActionData?._id,
      permission,
      status,
    };
    const response = await updateActionPermission(body);
    setEditActionData(response.data);
    setTimeout(() => dispatch(getRole(true)), 1000);
  };

  return (
    <>
      <List
        headers={headers}
        data={roles}
        selectedData={selectedData}
        onEdit={(data) => {
          setEditData(data);
          setModalOpen(true);
        }}
        onActionEdit={(data) => {
          setEditActionData(data);
          setModalActionOpen(true);
        }}
        onViewPermissionEdit={(data) => {
          setEditViewPermissionData(data);
          setModalViewPermissionOpen(true);
        }}
        onSelect={(e) => {
          if (selectedData.find((s) => s._id === e._id)) setSelectedData(selectedData.filter((s) => s._id !== e._id));
          else setSelectedData([...selectedData, e]);
        }}
        buttons={
          <>
            <AddNewButton
              type='role'
              onClick={() => {
                setAddModelOpen(true);
                setEditData(null);
              }}
            />
            {canEdit() && (
              <Button
                style={{ width: 100 }}
                size='sm'
                disabled={selectedData.length !== 1}
                onClick={() => {
                  setEditData(selectedData[0]);
                  setAddModelOpen(true);
                }}
              >
                <i className='fa fa-edit mr-2' /> {t('edit')}
              </Button>
            )}

            <Button style={{ width: 95 }} size='sm' onClick={() => dispatch(getRole(true))}>
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
          </>
        }
      />
      <ManageDialog
        onToggle={() => window.location.reload()}
        modalOpen={modalOpen}
        data={editData}
        update_Permission={(permission, status) => update_Permission(permission, status)}
      />
      <ActionManageDialog
        onToggle={() => window.location.reload()}
        modalOpen={modalActionOpen}
        data={editActionData}
        update_Permission={(permission, status) => update_ActionPermission(permission, status)}
      />
      <ViewManageDialog
        onToggle={() => window.location.reload()}
        modalOpen={modelViewPermission}
        data={viewPermissionData}
        updateViewPermission={(permission, status) => updateViewPermission(permission, status)}
      />
      <AddDialog
        title={
          editData ? `${t('edit')} ${t('sidebar.role_management')}` : `${t('add')} ${t('sidebar.role_management')}`
        }
        editData={editData}
        modalOpen={addModalOpen}
        onToggle={(open) => setAddModelOpen(open)}
        onSubmit={(values) => {
          setAddModelOpen(false);
          if (editData) dispatch(updateRoleName({ ...values, _id: editData?._id }));
          else dispatch(createRole(values));
          setTimeout(() => dispatch(getRole({}, true)), 1000);
        }}
      />
    </>
  );
}

export default RoleManagment;
