import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
function AddNewButton({ onClick, type, buttonType = 'icon' }) {
  const role = useSelector((state) => state.user.role);
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    if (type !== 'unknown') {
      const foundPermission = role.role.action_permission.root.children.find((e) => e.name === type);
      const showButton = foundPermission?.children.find((e) => e.name === 'create' && e.status);
      setShow(showButton);
    }
    setShow(true);
  }, [role.role.action_permission.root.children, type]);

  if (show)
    if (buttonType === 'icon')
      return (
        <Button style={{ width: 95 }} size='sm' onClick={onClick}>
          <i className='fa fa-plus mr-2' /> {t('add')}
        </Button>
      );

  return <></>;

  // return <></>;
}

export default AddNewButton;
