import { sendData, getData } from './apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';
export async function getAll() {
  try {
    let response = await getData('branch/get-all-refresh');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function save(values) {
  try {
    return await sendData('branch/create', values);
  } catch (e) {
    if (e.response.data.message) NotificationManager.error(e.response?.data?.message);
    else if (e.response.data.errors) {
      NotificationManager.error(e.response?.data?.errors[0]);
    }
    return false;
  }
}

export async function deleteData(_id) {
  try {
    let response = await sendData(`branch/${_id}`, null, 'DELETE');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function update(values) {
  try {
    return await sendData('branch/update', values);
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}
