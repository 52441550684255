import React from 'react';
import EntryGrid from 'components/Inputs/EntryGrid';
import CollapseBox from 'components/CollapseBox';
import { useSelector } from 'react-redux';
// import useActionPermission from 'hooks/useActionPermission';

function BranchList({ onRefresh, onAddOpen, onBranchClick, onAddSubBranch, onEditOpen }) {
  const branches = useSelector((state) => state.information.branches);
  const getChildBranch = (_id) => {
    return branches.child.filter((e) => e.parentBranch === _id);
  };

  // const { canAdd, canEdit } = useActionPermission('branch');

  return (
    <>
      <EntryGrid
        title={
          <div className='d-flex align-items-center pb-2 pt-2'>
            <i
              className='fa fa-plus-square mt-1 text-default'
              style={{ fontSize: 23, cursor: 'pointer' }}
              onClick={onAddOpen}
            ></i>

            <i
              className='fa fa-edit ml-4 text-success'
              style={{ fontSize: 22, cursor: 'pointer' }}
              onClick={onEditOpen}
            ></i>

            <i
              className='fa fa-spinner ml-4 text-secondary'
              style={{ fontSize: 22, cursor: 'pointer' }}
              onClick={onRefresh}
            ></i>
          </div>
        }
      >
        {branches?.parent?.map((e, index) => (
          <CollapseBox
            style={{ paddingTop: 16, paddingBottom: 16 }}
            header={
              <div>
                <i
                  className='fa fa-plus text-default mr-3'
                  style={{ cursor: 'pointer' }}
                  onClick={() => onAddSubBranch(e)}
                ></i>

                <span onClick={() => onBranchClick(e)} style={{ cursor: 'pointer' }}>
                  {e.name}
                </span>
              </div>
            }
            key={`branch-list-${index}`}
            child={getChildBranch(e._id).map((c, cIndex) => (
              <h3
                className='ml-3'
                style={{ cursor: 'pointer' }}
                onClick={() => onBranchClick(c)}
                key={`child-branch-list-${cIndex}`}
              >
                {c.name}
              </h3>
            ))}
          />
        ))}
      </EntryGrid>
    </>
  );
}
export default BranchList;
