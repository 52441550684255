import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import queryString from 'query-string';
import AddNewButton from 'components/ActionButtons/addNewButton';
import { getTownship, saveTownship, updateTownship, getCountryOption, deleteTownship } from 'store/actions/location';
import List from './list';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import AddDialog from './addDialog';
import { useHistory } from 'react-router';
import { t } from 'i18next';

function Township() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);
  const dispatch = useDispatch();
  const router = useHistory();
  const townships = useSelector((state) => state.location.townships);
  const countries = useSelector((state) => state.location.countries);

  const headers = ['no', 'name', 'district', 'region', 'country', 'created_at', 'created_by', ''];
  React.useEffect(() => {
    dispatch(getCountryOption());
    dispatch(getTownship(queryString.parse(router.location.search), true));
  }, [getCountryOption, getTownship, dispatch, router.location.search]);

  return (
    <>
      <List
        headers={headers}
        data={townships}
        onEdit={(data) => {
          setModalOpen(true);
          setEditData(data);
        }}
        onDelete={(data) => {
          setShowDelete(true);
          setEditData(data);
        }}
        buttons={
          <>
            <AddNewButton
              onClick={() => {
                setEditData(null);
                setModalOpen(true);
              }}
              type='township'
            />

            <Button style={{ width: 95 }} size='sm' onClick={() => dispatch(getTownship({}, true))}>
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
          </>
        }
      />
      <AddDialog
        title={editData ? `${t('edit')} ${t('township')}` : `${t('add')} ${t('township')}`}
        editData={editData}
        modalOpen={modalOpen}
        onToggle={(open) => setModalOpen(open)}
        options={{ countries: countries }}
        onSubmit={(values) => {
          setEditData(null);
          setModalOpen(false);
          if (editData) dispatch(updateTownship({ ...values, _id: editData?._id }));
          else dispatch(saveTownship(values));
          setTimeout(() => dispatch(getTownship({}, true)), 1000);
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`${t('delete')} ${t('township')}`}
        body={`Are you sure to delete ${editData?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(deleteTownship(editData?._id));
          setTimeout(() => dispatch(getTownship({}, true)), 1000);
        }}
      />
    </>
  );
}

export default Township;
