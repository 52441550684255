import React from 'react';
import { Document, Page, StyleSheet, View, Text, Font, Image } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import logo from 'assets/img/brand/company_logo.png';
import { getCargoType } from 'utli';
import FONT from 'assets/fonts/FZHei.ttf';

Font.register({
  src: FONT,
  family: 'FZHei',
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFF',
    fontSize: 8,
    fontFamily: 'FZHei',
  },
  container: {
    margin: 25,
  },
  header_view: {
    // display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logo_img: {
    width: 120,
    height: 'auto',
  },
  order_text: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  section_container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },
  item_container: {
    display: 'flex',
    flexDirection: 'row',
  },
  section: {
    width: '50%',
    flexGrow: 1,
  },
  view_header: {
    marginTop: 20,
    paddingBottom: 5,
    marginBottom: 5,
    borderBottom: '1px solid #000',
  },
  txt_header: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  txt_address: {},

  cost_item_container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 20,
  },
  cost_item_value: {
    textAlign: 'right',
  },
});

const MyDoc = ({ data = null }) => {
  if (!data) return null;

  const { sender, receiver, item, createdBranch, cost } = data;

  const CostItemView = ({ label = '', value }) =>
    value ? (
      <View style={styles.cost_item_container}>
        <View>
          <Text>{label}</Text>
        </View>
        <View style={styles.cost_item_value}>
          <Text>{value ? value : 0}</Text>
        </View>
      </View>
    ) : null;

  return (
    <Document>
      <Page size='A5' style={styles.page}>
        <View style={styles.container}>
          <View style={styles.cost_item_container}>
            <Image src={logo} style={styles.logo_img} />
            <Text style={styles.order_text}>Customer Order Form</Text>
          </View>
          <View style={styles.section_container}>
            <View style={styles.section}>
              <View style={styles.view_header}>
                <Text style={styles.txt_header}>Shipper Information</Text>
              </View>
              <Text>Name : {sender?.name}</Text>
              <Text>Company : {sender?.company}</Text>
              <Text>Phone : {sender?.phone}</Text>
              <Text>
                Address : {sender?.address} {sender?.township?.name}, {sender?.region?.name}
              </Text>
            </View>
            <View style={styles.section}>
              <View style={styles.view_header}>
                <Text style={styles.txt_header}>Consignee Information</Text>
              </View>
              <Text>Name : {receiver?.name}</Text>
              <Text>Company : {receiver?.company}</Text>
              <Text>Phone : {receiver?.phone}</Text>
              <Text>
                Address : {receiver?.address} {receiver?.township?.name}, {receiver?.region?.name}
              </Text>
            </View>
          </View>

          <View style={styles.section_container}>
            <View style={styles.section}>
              <View style={styles.view_header}>
                <Text style={styles.txt_header}>Basic Information</Text>
              </View>
              <View style={styles.item_container}>
                <View style={styles.section}>
                  <Text>Origin: {createdBranch?.name}</Text>
                  <Text>Shipping type: {data?.shipment_type?.name}</Text>
                  <Text>Delivery mode: {data?.delivery_mode}</Text>
                  <Text>Customs Clearence: {data?.custom_clearence?.name}</Text>
                </View>
                <View style={styles.section}>
                  <Text>Place of Receipt: {data?.createdBranch?.name}</Text>
                  <Text>Place of Delivery: {data?.destination?.name}</Text>
                  <Text>Shipping mode: {data?.shipping_mode?.name}</Text>

                  <Text>Order Receipt Date: {dayjs(data?.shipping_date).format('DD/MM/YYYY')}</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.section_container}>
            <View style={styles.section}>
              <View style={styles.view_header}>
                <Text style={styles.txt_header}>Cargo Information</Text>
              </View>
              <View style={styles.item_container}>
                <View style={styles.section}>
                  <Text>Cargo Group: {item?.itemtype?.name}</Text>
                  <Text>Cargo Type: {getCargoType(item?.weight / item?.volume)}</Text>
                  <Text>Quantity: {item?.qty}</Text>
                  <Text>Measurement (CBM): {item?.volume}</Text>
                  <Text>Remark: {item?.remark}</Text>
                </View>
                <View style={styles.section}>
                  <Text>Cargo name: {item?.itemname}</Text>
                  <Text>Gross Weight(KGS): {item?.weight}</Text>
                  <Text>Cargo Value: {item?.item_value}</Text>
                  <Text>{}</Text>
                </View>
              </View>
            </View>
          </View>

          <View>
            <View style={styles.view_header}>
              <Text style={styles.txt_header}>Cost Information</Text>
            </View>
            <CostItemView label='Insurance Fee' value={cost?.insurance_fee} />
            <CostItemView label='Prepaid for client' value={cost?.prepaid_for_client} />
            <CostItemView label='Pickup Fee' value={cost?.pickup_fee} />
            <CostItemView label='Clearance Fee' value={cost?.clearence_fee} />
            <CostItemView label='Storage Fee' value={cost?.storage_fee} />
            <CostItemView label='Loading Fee' value={cost?.loading_fee} />
            <CostItemView label='Packaging Fee' value={cost?.packaging_fee} />
            <CostItemView label='Behalf of Collection' value={cost?.behalf_of_collection} />
            <CostItemView label='Basic Freight' value={Math.round(cost?.basic_freight)} />
            <View
              style={{
                borderTop: '1px solid #000',
                paddingTop: 5,
                marginTop: 5,
                fontWeight: 'bold',
              }}
            >
              <CostItemView label='Total Cost' value={Math.round(cost?.total)} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MyDoc;
