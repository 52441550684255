import React from 'react';
import FilterBox from './filter';
import * as WaybillAction from 'store/actions/waybill';
import { useDispatch, useSelector } from 'react-redux';
import List from './list';
import './index.scss';
import { t } from 'i18next';

function WaybillTrackingManagement() {
  const dispatch = useDispatch();
  const [wayBillNumber, setWayBillNumber] = React.useState('');
  const onSearch = (values) => {
    setWayBillNumber(values.waybill_number);
    dispatch(WaybillAction.getLog(values.waybill_number.trim()));
  };

  const waybill_logs = useSelector((state) => state.waybill.waybill_logs);
  const loading = useSelector((state) => state.loading.loading);

  const headers = ['customer_service.status', 'customer_service.action_person', 'customer_service.updated_at'];

  return (
    <>
      <div>
        <FilterBox onSearch={onSearch} />
      </div>
      {!loading && waybill_logs.length > 0 && (
        <div className='mt-4  justify-content-center'>
          <h3>
            {t('customer_service.waybill_number')} : {wayBillNumber}{' '}
          </h3>
          <List data={waybill_logs} headers={headers} />
        </div>
      )}
      {!loading && waybill_logs.length === 0 && (
        <div className='mt-5 d-flex justify-content-center'>
          <h3>{t('customer_service.waybill_notfound')}</h3>
        </div>
      )}
    </>
  );
}

export default WaybillTrackingManagement;
