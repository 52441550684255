import React from 'react';
import { Button } from 'reactstrap';
import Table from 'components/Table';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { moneyFormatter, moneyDecimalFormatter, getCargoType, customRound } from 'utli';
import CheckBox from 'components/Inputs/CheckBox';
import { t } from 'i18next';
function List({
  headers,
  selectedData = [],
  data,
  onSelect,
  title,
  buttons,
  filter,
  showDetail,
  onPageChange,
  onChoose,
}) {
  return (
    <div className='address-table'>
      <h3>{title}</h3>
      <Table
        data={data}
        subtitle={t('sidebar.order_query')}
        filter={filter}
        onPageChange={onPageChange}
        headers={headers.map((e) => (
          <th
            className={clsx('headers', e.className)}
            key={`thead-${Math.random()}`}
            style={{ width: e.width, left: e.left, right: e.right }}
          >
            {t(e.text)}
          </th>
        ))}
        buttons={buttons}
        rows={data?.docs?.map((e, index) => (
          <tr key={`table-row-${index}-${Math.random()}`}>
            <td className='fixed-cell left'>
              <CheckBox
                padding='mr-0'
                onChange={() => onSelect(e)}
                checked={selectedData.find((s) => s._id === e._id)}
              />
            </td>
            <td style={{ left: 55 }} className='fixed-cell left'>
              <span style={{ cursor: 'pointer' }} onClick={() => showDetail(e)}>
                {e.waybillnumber}
              </span>
            </td>
            <td style={{ left: 165 }} className='fixed-cell left'>
              {dayjs(e.shipping_date).format('DD/MM/YYYY')}
            </td>
            <td>{e.shipment_type?.name}</td>
            <td>{e.shipping_mode?.name}</td>
            <td>{e.delivery_mode}</td>
            <td>{e.custom_clearence?.name}</td>
            <td>{e.createdBranch?.name}</td>
            <td>{e.destination?.name}</td>
            <td>{e.address_code}</td>
            <td>{e.sender?.company}</td>
            <td>{e.sender?.name}</td>
            <td>{e.sender?.phone}</td>
            <td>{e.receiver?.company}</td>
            <td>{e.receiver?.name}</td>
            <td>{e.receiver?.phone}</td>
            <td>{getCargoType(e.item?.weight / e.item?.volume)}</td>
            <td>{e.item?.itemtype.name}</td>
            <td>{e.item?.itemname}</td>
            <td>{moneyDecimalFormatter(e.item?.qty)}</td>
            <td>{moneyDecimalFormatter(e.item?.weight)}</td>
            <td>{moneyDecimalFormatter(e.item?.volume)}</td>
            <td>{e.item?.item_value}</td>
            <td>{e.item?.remark}</td>
            <td>{moneyDecimalFormatter(customRound(e.cost?.total.toFixed(1)))}</td>

            <td className='fixed-cell' style={{ right: 220 }}>
              {e.sale_person?.name}
            </td>
            <td className='fixed-cell' style={{ right: 80 }}>
              {e.is_used && e.waybill_date ? dayjs(e.waybill_date).format('DD/MM/YYYY') : '-'}
            </td>
            <td className='fixed-cell' style={{ right: 0 }}>
              {e.is_used ? 'Received' : '-'}
            </td>
            {onChoose && (
              <td className='fixed-cell'>
                <Button size='sm' color='success' onClick={() => onChoose(e)}>
                  <i className='fa fa-check-circle' />
                </Button>
              </td>
            )}
          </tr>
        ))}
        footer={
          <>
            <td>-</td>
            <td>{t('waybill_entry.total')}</td>
            <td colSpan={17}></td>
            <td style={{ textAlign: 'right' }}>{data?.totalSum?.item?.qty}</td>
            <td style={{ textAlign: 'right' }}>{moneyDecimalFormatter(data?.totalSum?.item?.weight?.toFixed(2))}</td>
            <td style={{ textAlign: 'right' }}>{moneyDecimalFormatter(data?.totalSum?.item?.volume?.toFixed(2))}</td>
            <td></td>
            <td></td>
            <td style={{ textAlign: 'right' }}>
              {' '}
              {moneyFormatter(customRound(data?.totalSum?.remaining_balance?.toFixed(2)))}
            </td>
            <td colSpan={4}></td>
          </>
        }
      />
    </div>
  );
}

export default List;
