import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { getCountry, saveCountry, updateCountry, deleteCountry } from 'store/actions/location';
import List from './list';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import AddDialog from './addDialog';
import { t } from 'i18next';
import AddNewButton from 'components/ActionButtons/addNewButton';

function Country() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);

  const dispatch = useDispatch();
  const router = useHistory();
  const countries = useSelector((state) => state.location.countries);

  const headers = ['no', 'name', 'code', 'created_at', 'created_by', ''];
  React.useEffect(() => {
    dispatch(getCountry(queryString.parse(router.location.search), true));
  }, [getCountry, dispatch, router.location.search]);

  return (
    <>
      <List
        headers={headers}
        countries={countries}
        onEdit={(data) => {
          setModalOpen(true);
          setEditData(data);
        }}
        onDelete={(data) => {
          setShowDelete(true);
          setEditData(data);
        }}
        buttons={
          <>
            <AddNewButton
              type='country'
              onClick={() => {
                setModalOpen(true);
                setEditData(null);
              }}
            />
            <Button style={{ width: 95 }} size='sm' onClick={() => dispatch(getCountry({}, true))}>
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
          </>
        }
      />
      <AddDialog
        title={editData ? `${t('edit')} ${t('country')}` : `${t('add')} ${t('country')}`}
        editData={editData}
        modalOpen={modalOpen}
        onToggle={(open) => setModalOpen(open)}
        onSubmit={(values) => {
          setModalOpen(false);
          if (editData) dispatch(updateCountry({ ...values, _id: editData?._id }));
          else dispatch(saveCountry(values));

          setTimeout(() => dispatch(getCountry({}, true)), 1000);
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`${t('delete')} ${t('country')}`}
        body={`Are you sure to delete ${editData?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(deleteCountry(editData?._id));
          setTimeout(() => dispatch(getCountry({}, true)), 1000);
        }}
      />
    </>
  );
}

export default Country;
