import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { getHeaders } from './headers';
import FilterBox from './filter';
import List from './list';
import { useDispatch, useSelector } from 'react-redux';
import * as OrderAction from 'store/actions/order';
import * as InformationAction from 'store/actions/information';
import { t } from 'i18next';

function SelectModal({ modalOpen, onSelect, onToggle }) {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.waybill.orders);
  const [searchValue, setSearchValue] = React.useState(null);
  const [query, setQuery] = React.useState({});

  React.useEffect(() => {
    dispatch(InformationAction.getBranch());
  }, [dispatch, InformationAction.getBranch]);

  React.useEffect(() => {
    loadData();
  }, [OrderAction.getAll, query]);

  const loadData = (value = null) => {
    setSearchValue(value);
    let queryParam = { limit: 10, page: 1 };
    if (query) {
      queryParam = query;
    }
    dispatch(
      OrderAction.getAll(
        queryParam,
        value ? value : searchValue ? { ...searchValue, table_view: false } : { table_view: false }
      )
    );
  };

  return (
    <Modal
      isOpen={modalOpen}
      size='lg'
      style={{ maxWidth: '1300px', width: '75%', top: '1%', maxHeight: 400 }}
      backdrop={true}
    >
      <div className=' modal-header' style={{ padding: 0, paddingTop: 2, paddingLeft: 10 }}>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <List
          headers={getHeaders(true)}
          data={orders}
          title={`${t('order')} ${t('list')}`}
          filter={<FilterBox onSearch={(value) => loadData(value)} />}
          onPageChange={(activePage, rowPerPage) => {
            setQuery({ limit: rowPerPage, page: activePage });
          }}
          buttons={
            <>
              <Button size='sm' onClick={() => loadData({})}>
                {t('refresh')}
              </Button>
            </>
          }
          onChoose={onSelect}
        />
      </ModalBody>
    </Modal>
  );
}
export default SelectModal;
