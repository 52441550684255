import React from 'react';
import Table from 'components/Table';
import clsx from 'clsx';
import { isExpired } from 'utli';
import CheckBox from 'components/Inputs/CheckBox';
import { t } from 'i18next';
function List({
  headers,
  selectedData = [],
  data,
  onSelect,

  buttons,
  filter,

  onPageChange,
}) {
  return (
    <div className='address-table'>
      <Table
        data={data}
        title={t('sidebar.sign')}
        subtitle={t('sidebar.sign_confirmation')}
        filter={filter}
        onPageChange={onPageChange}
        headers={headers.map((e) => (
          <th
            className={clsx('headers', e.className)}
            key={`thead-${Math.random()}`}
            style={{ width: e.width, left: e.left }}
          >
            {t(e.text)}
          </th>
        ))}
        buttons={buttons}
        rows={data?.map((e, index) => (
          <tr key={`table-row-${index}-${Math.random()}`} className={clsx(isExpired(e.expire_date) && 'deactive')}>
            <td className='fixed-cell left'>
              <CheckBox
                padding='mr-0'
                onChange={() => onSelect(e)}
                checked={selectedData.find((s) => s._id === e._id)}
              />
            </td>
            <td style={{ left: 55 }} className='fixed-cell left'>
              <span style={{ cursor: 'pointer' }}>{e.waybill_number}</span>
            </td>
            <td>{e.createdBranch?.name}</td>
            <td>{e.destination?.name}</td>
            <td>{e.customer_code}</td>
            <td>{e.sender?.name}</td>
            <td>{e.receiver?.name}</td>
            <td>{e.original_info?.itemname}</td>
            <td>{e.qty}</td>
            <td>{e.weight}</td>
            <td>{e.volume}</td>
            <td></td>
          </tr>
        ))}
      />
    </div>
  );
}

export default List;
