import Home from 'pages/Home';
import Login from 'pages/Login';
import ResetPassword from 'pages/ResetPassword';

import Country from 'pages/location/country';
import Region from 'pages/location/region';
import District from 'pages/location/district';
import Township from 'pages/location/township';
import RoleManagement from 'pages/system/role';
import UserManagement from 'pages/system/user';
import ItemType from 'pages/othermaintenance/itemtype';
import ShippingType from 'pages/othermaintenance/shippingtype';
import ShippingMode from 'pages/othermaintenance/shippingmode';
import PaymentType from 'pages/othermaintenance/paymenttype';
import Currency from 'pages/othermaintenance/currency';
import Collection from 'pages/othermaintenance/collection';
import Container from 'pages/othermaintenance/container';
import Suggestion from 'pages/othermaintenance/suggestion';
import Quotes from 'pages/othermaintenance/quotes';

import Position from 'pages/information/position';
import CustomClearence from 'pages/information/customClearence';
import EstimatedTime from 'pages/information/estimatedTime';

import Employee from 'pages/information/employee';
import AddressBook from 'pages/information/addressbook';
import Branch from 'pages/information/branch';
import BasicFreightValue from 'pages/information/basicFreightValue';
import OrderQuery from 'pages/order';
import WaybillQuery from 'pages/waybill/query';
import WaybillEntry from 'pages/waybill/entry';

import CargoLoading from 'pages/cargo/loading';
import CargoUnloading from 'pages/cargo/unloading';
import SignConfirmation from 'pages/signConfirmation';
import SignQuery from 'pages/signQuery';
import Finance from 'pages/finance';
// import PayWithCredit from 'pages/finance/credit';
// import PayWithCash from 'pages/finance/cash';
import Transcation from 'pages/transcation';
import AdditionalWaybill from 'pages/customerService/additionalWaybill';
import WaybillTracking from 'pages/customerService/tracking';
import WaybillTrackingManagement from 'pages/customerService/trackingManagement';
import SaleReport from 'pages/report/sale';
// import Report2 from 'pages/report/report2';

const routes = [
  {
    path: '/login',
    name: 'login',
    icon: 'fas fa-home text-primary',
    component: Login,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '/reset-password',
    name: 'login',
    icon: 'fas fa-home text-primary',
    component: ResetPassword,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '',
    name: 'home',
    icon: 'fas fa-home text-cyan',
    component: Home,
    layout: '/',
  },
  {
    collapse: true,
    name: 'order',
    icon: '	fa fa-file-word text-green',
    state: 'orderCollapse',
    views: [
      {
        path: 'order-query',
        name: 'order_query',
        miniName: 'OQ',
        component: OrderQuery,
        layout: '/',
      },
    ],
  },
  {
    collapse: true,
    name: 'document',
    icon: '	fa fa-file-excel text-blue',
    state: 'waybillCollapse',
    views: [
      {
        path: 'waybill-entry',
        name: 'waybill_entry',
        miniName: 'WET',
        component: WaybillEntry,
        layout: '/',
      },
      {
        path: 'waybill-query',
        name: 'waybill_query',
        miniName: 'WQ',
        component: WaybillQuery,
        layout: '/',
      },
    ],
  },
  {
    collapse: true,
    name: 'cargo_diversion_center',
    icon: '	fa fa-building text-blue',
    state: 'shippingInventoryCollapse',
    views: [
      // {
      //   path: 'shipping-inventory',
      //   name: 'shipping_inventory',
      //   miniName: 'SIT',
      //   component: ShippingInventory,
      //   layout: '/',
      // },
      {
        path: 'cargo-loading',
        name: 'cargo_loading',
        miniName: 'CLI',
        component: CargoLoading,
        layout: '/',
      },
      {
        path: 'cargo-unloading',
        name: 'cargo_unloading',
        miniName: 'CLI',
        component: CargoUnloading,
        layout: '/',
      },
      {
        path: 'sign-query',
        name: 'sign_query',
        miniName: 'SQY',
        component: SignQuery,
        layout: '/',
      },
    ],
  },
  {
    collapse: true,
    name: 'sign',
    icon: '	fa fa-sign text-pink',
    state: 'signCollapse',
    views: [
      {
        path: 'sign-confirmation',
        name: 'sign_confirmation',
        miniName: 'SCF',
        component: SignConfirmation,
        layout: '/',
      },
      {
        path: 'sign-query',
        name: 'sign_query',
        miniName: 'SQY',
        component: SignQuery,
        layout: '/',
      },
    ],
  },
  {
    collapse: true,
    name: 'customer_service',
    icon: '	fa fa-user text-indigo',
    state: 'addWaybillCollapse',
    views: [
      {
        path: 'customer-service',
        name: 'waybill_tracking',
        miniName: 'PWC',
        component: WaybillTracking,
        layout: '/',
      },
      {
        path: 'waybill_tracking_management',
        name: 'waybill_tracking_management',
        miniName: 'WTM',
        component: WaybillTrackingManagement,
        layout: '/',
      },
      {
        path: 'additional_waybill_status',
        name: 'waybill_comment',
        miniName: 'PWC',
        component: AdditionalWaybill,
        layout: '/',
      },
      {
        path: 'suggestion',
        name: 'suggestion',
        miniName: 'CM',
        component: Suggestion,
        layout: '/',
      },
      {
        path: 'quotes',
        name: 'quotes',
        miniName: 'CMQ',
        component: Quotes,
        layout: '/',
      },
    ],
  },
  {
    collapse: true,
    name: 'finance',
    icon: '	fa fa-calculator text-indigo',
    state: 'financeCollapse',

    views: [
      {
        path: 'pay-by-cash',
        name: 'cash',
        miniName: 'PWC',
        component: Finance,
        layout: '/',
      },
      {
        path: 'transaction-records',
        name: 'transaction',
        miniName: 'PWC',
        component: Transcation,
        layout: '/',
      },
    ],
  },

  {
    collapse: true,
    name: 'information',
    icon: '	fa fa-info-circle text-brown',
    state: 'informationCollapse',
    views: [
      {
        path: 'position-management',
        name: 'position_management',
        miniName: 'POIT',
        component: Position,
        layout: '/',
      },
      {
        path: 'employee-maintainence',
        name: 'employee_maintainence',
        miniName: 'EMT',
        component: Employee,
        layout: '/',
      },
      {
        path: 'address-book',
        name: 'address_book',
        miniName: 'ADB',
        component: AddressBook,
        layout: '/',
      },
      {
        path: 'branch-maintainence',
        name: 'branch_maintainence',
        miniName: 'BMT',
        component: Branch,
        layout: '/',
      },
      {
        path: 'custom_clearence',
        name: 'custom_clearence',
        miniName: 'CCL',
        component: CustomClearence,
        layout: '/',
      },
      {
        path: 'estimated_time',
        name: 'estimated_time',
        miniName: 'EST',
        component: EstimatedTime,
        layout: '/',
      },
      {
        path: 'basic_reight_value',
        name: 'basic_reight_value',
        miniName: 'BFV',
        component: BasicFreightValue,
        layout: '/',
      },
    ],
  },
  {
    collapse: true,
    name: 'other_maintenance',
    icon: 'fa fa fa-briefcase text-blue',
    state: 'otherMaintenanceCollapse',
    views: [
      {
        path: 'other-maintenance-itemtype',
        name: 'Item Type Maintenance',
        miniName: 'OMIT',
        component: ItemType,
        layout: '/',
      },
      {
        path: 'other-maintenance-shippingtype',
        name: 'Shipping Type Maintenance',
        miniName: 'ST',
        component: ShippingType,
        layout: '/',
      },
      {
        path: 'other-maintenance-shippingmode',
        name: 'Shipping Mode Maintenance',
        miniName: 'SM',
        component: ShippingMode,
        layout: '/',
      },
      {
        path: 'other-maintenance-paymenttype',
        name: 'Payment Type Maintenance',
        miniName: 'PT',
        component: PaymentType,
        layout: '/',
      },
      {
        path: 'other-maintenance-currency',
        name: 'Currency Maintenance',
        miniName: 'CR',
        component: Currency,
        layout: '/',
      },
      {
        path: 'other-collection-management',
        name: 'Collection Management',
        miniName: 'CL',
        component: Collection,
        layout: '/',
      },
      {
        path: 'container-maintainence',
        name: 'container',
        miniName: 'CM',
        component: Container,
        layout: '/',
      },
    ],
  },
  {
    collapse: true,
    name: 'reporting',
    icon: 'fa fa-archive text-yellow',
    state: 'reportCollapse',
    path: 'reporting',
    views: [
      {
        path: 'sale_report',
        name: 'SaleReport',
        miniName: 'RP1',
        component: SaleReport,
        layout: '/',
      },
    ],
  },
  {
    collapse: true,
    name: 'location',
    icon: 'fa fa-map-marker text-yellow',
    state: 'locationCollapse',
    views: [
      {
        path: 'country',
        name: 'country',
        miniName: 'C',
        component: Country,
        layout: '/',
      },
      {
        path: 'region',
        name: 'region',
        miniName: 'R',
        component: Region,
        layout: '/',
      },
      {
        path: 'district',
        name: 'district',
        miniName: 'D',
        component: District,
        layout: '/',
      },
      {
        path: 'township',
        name: 'township',
        miniName: 'T',
        component: Township,
        layout: '/',
      },
    ],
  },

  {
    collapse: true,
    name: 'system',
    icon: 'fa fa-cog text-pink',
    state: 'systemCollapse',
    views: [
      {
        path: 'role-management',
        name: 'role_management',
        miniName: 'RM',
        component: RoleManagement,
        layout: '/',
      },
      {
        path: 'user-management',
        name: 'user_management',
        miniName: 'UM',
        component: UserManagement,
        layout: '/',
      },
    ],
  },
];

const getAllRoutes = () => {
  const allRoutes = [];
  routes.map((e) => {
    if (e.collapse) {
      e.views.map((v) => allRoutes.push(v));
    } else allRoutes.push(e);
  });
  return allRoutes;
};
const allRoutes = getAllRoutes();

export { routes, allRoutes };
