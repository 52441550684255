import React from 'react';
import { getCache } from 'utli/cache';
import WordImg from 'assets/img/images/word_img.png';
import PdfImg from 'assets/img/images/pdf_img.png';
import XclImg from 'assets/img/images/excel_img.png';
import { apiEndpoint } from 'services/apiService';
import './index.scss';
function NetworkFileThumbnail({ e, setPreviewImgUrl, onDelete, showUpload }) {
  let token = getCache('access_token');
  const renderItem = (file) => {
    let fileSplists = file.split('.');
    let ext = fileSplists[fileSplists.length - 1];
    if (ext === 'pdf') {
      return <img src={PdfImg} alt={file} />;
    } else if (ext === 'docx' || ext === 'doc') {
      return <img src={WordImg} alt={file} />;
    } else if (ext === 'xlsx' || ext === 'csv' || ext === 'xlsm' || ext === 'xls') {
      return <img src={XclImg} alt={file} />;
    } else {
      return <img src={`${apiEndpoint}files/waybill-single-image/${file}?auth=${token}`} alt={file} />;
    }
  };

  const previewFile = (file) => {
    let fileSplists = file.split('.');
    let ext = fileSplists[fileSplists.length - 1];
    if (ext === 'docx' || ext === 'doc' || ext === 'xlsx' || ext === 'xls' || ext === 'csv' || ext === 'xlsm') {
      window.location.href = `${apiEndpoint}files/waybill-single-image/${file}?auth=${token}`;
    } else {
      setPreviewImgUrl({
        name: file,
        url: `${apiEndpoint}files/waybill-single-image/${file}?auth=${token}`,
      });
    }
  };

  return (
    <div className='file-thumbnail'>
      <div onClick={() => previewFile(e)}>{renderItem(e)}</div>
      {showUpload && (
        <span className='bg-green' onClick={onDelete}>
          x
        </span>
      )}
    </div>
  );
}

export default NetworkFileThumbnail;
