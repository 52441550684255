import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddNewButton from 'components/ActionButtons/addNewButton';
import { Button } from 'reactstrap';
import List from './list';
import AddDialog from './addDialog';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import * as InformationAction from 'store/actions/information';
import * as EstimatedTimeAction from 'store/actions/estimatedTime';
import * as ShippingModeAction from 'store/actions/shipping_mode';
import { t } from 'i18next';

function CustomClearence() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);

  const dispatch = useDispatch();

  const estimatedTimes = useSelector((state) => state.information.estimatedTimes);

  const headers = [
    'no',
    'shipping_inventory.departure_branch',
    'shipping_inventory.destination_branch',
    'information_other_shipping_mode.shipping_mode',
    'duration',
  ];
  React.useEffect(() => {
    dispatch(EstimatedTimeAction.getAll({}));
    dispatch(ShippingModeAction.getAll({}));
    dispatch(InformationAction.getBranch());
  }, [EstimatedTimeAction.getAll, dispatch]);

  return (
    <>
      <List
        headers={headers}
        data={estimatedTimes}
        onEdit={(data) => {
          setModalOpen(true);
          setEditData(data);
        }}
        onDelete={(data) => {
          setShowDelete(true);
          setEditData(data);
        }}
        buttons={
          <>
            <AddNewButton
              type='custom_clearnece'
              onClick={() => {
                setModalOpen(true);
                setEditData(null);
              }}
            />
            <Button style={{ width: 100 }} size='sm' onClick={() => dispatch(EstimatedTimeAction.getAll({}, true))}>
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
          </>
        }
      />
      <AddDialog
        title={editData ? `${t('edit')} ${t('sidebar.estimated_time')}` : `${t('add')} ${t('sidebar.estimated_time')}`}
        editData={editData}
        modalOpen={modalOpen}
        onToggle={(open) => setModalOpen(open)}
        onSubmit={(values) => {
          setModalOpen(false);
          if (editData) dispatch(EstimatedTimeAction.update({ ...values, _id: editData?._id }));
          else dispatch(EstimatedTimeAction.save(values));
          setTimeout(() => dispatch(EstimatedTimeAction.getAll({}, true)), 1000);
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`${t('delete')} ${t('sidebar.estimated_time')}`}
        body={`${t('confirm_delete')} ?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(EstimatedTimeAction.deleteData(editData?._id));
          setTimeout(() => dispatch(EstimatedTimeAction.getAll()), 1000);
        }}
      />
    </>
  );
}

export default CustomClearence;
