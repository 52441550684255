import * as OrderService from 'services/order';
import { SET_ORDERS } from 'store/types/waybill';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
const maxFileSize = 5242880; // 5 Mb

export const getAll = (query, body) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await OrderService.getAll(query, body);
    dispatch({
      type: SET_ORDERS,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const activate = (id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await OrderService.activate(id);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteImage = (file, waybillnumber, type) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await OrderService.deleteImage(file, waybillnumber, type);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const exportData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });

    const response = await OrderService.exportData(data);
    const blob = await response;

    const temp_date = dayjs(new Date()).format('DD/MM/YYYY');
    saveAs(blob, `order-${temp_date}.csv`);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const save = (values, files) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    const result = await OrderService.save(values);
    const { orderImageFiles } = files;
    const fileTooLarge1 = orderImageFiles.find((img) => img.size > maxFileSize);

    if (fileTooLarge1) {
      alert('file too large');
      return;
    }

    let formData = new FormData();

    orderImageFiles.forEach((img) => {
      formData.append('upload-all-order-file', img);
    });

    await OrderService.uploadFile(formData, values.waybillnumber ? values.waybillnumber : values.basicinfo.waybill_no);

    if (result)
      setTimeout(() => {
        window.location.reload();
      }, 1000);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const update = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await OrderService.update(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteData = (id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await OrderService.deleteData(id);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteAllData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await OrderService.deleteAllData(data);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};
