import React from 'react';
import { Button } from 'reactstrap';
import List from './list';
import FilterBox from './filter';
import {
  getUser,
  getRole,
  createUser,
  deleteUser,
  resetUserPassword,
  updateUserRole,
  changeUserActiveStatus,
  updateUserWaybillPermission,
} from 'store/actions/role';
import { getEmployee, getBranch } from 'store/actions/information';
// import useActionPermission from 'hooks/useActionPermission';
import { useDispatch, useSelector } from 'react-redux';
import AddDialog from './addDialog';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import WaybillPermssionDialog from './waybillPermissionDialog';
import { useTranslation } from 'react-i18next';
import AddNewButton from 'components/ActionButtons/addNewButton';

function UserManagement() {
  const headers = [
    '',
    'no',
    'system_user_management.name',
    'system_user_management.phonenumber',
    'system_user_management.branch',
    'system_user_management.role',
    'system_user_management.temp_pwd',
    'system_user_management.created_at',
    'system_user_management.verified',
    'system_user_management.active',
    '',
  ];
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [waybillPermissionModal, setWaybillPermissionModal] = React.useState(false);
  // const { canEdit } = useActionPermission();
  const [editData, setEditData] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.role.users);

  const [selectedData, setSelectedData] = React.useState([]);

  React.useEffect(() => {
    dispatch(getUser());
    dispatch(getEmployee());
    dispatch(getBranch());
    dispatch(getRole());
  }, [dispatch, getUser, getEmployee]);

  return (
    <>
      <List
        headers={headers}
        selectedData={selectedData}
        data={users}
        onDelete={(data) => {
          setShowDelete(true);
          setEditData(data);
        }}
        onEdit={(data) => {
          setEditData(data);
          setModalOpen(true);
        }}
        onWaybillPermssionChange={(data) => {
          setEditData(data);
          setWaybillPermissionModal(true);
        }}
        onActiveChange={(values) => {
          dispatch(changeUserActiveStatus(values));

          setTimeout(() => dispatch(getUser()), 1000);
        }}
        onSelect={(e) => {
          if (selectedData.find((s) => s._id === e._id)) setSelectedData(selectedData.filter((s) => s._id !== e._id));
          else setSelectedData([...selectedData, e]);
        }}
        filter={<FilterBox onSearch={(value) => dispatch(getUser(value))} />}
        buttons={
          <>
            <AddNewButton
              type='user'
              onClick={() => {
                setEditData(null);
                setModalOpen(true);
              }}
            />
            <Button
              style={{ width: 100 }}
              size='sm'
              disabled={selectedData.length !== 1}
              onClick={() => {
                if (selectedData.length === 1) {
                  setModalOpen(true);
                  setEditData(selectedData[0]);
                }
              }}
            >
              <i className='fa fa-edit mr-2' /> {t('edit')}
            </Button>

            <Button
              style={{ width: 150 }}
              size='sm'
              disabled={selectedData.length !== 1}
              onClick={() => {
                dispatch(
                  resetUserPassword({
                    _id: selectedData[0]._id,
                    phonenumber: selectedData[0].phonenumber,
                  })
                );
                setSelectedData([]);
                setTimeout(() => {
                  dispatch(getUser());
                }, 1000);
              }}
            >
              <i className='fa fa-lock mr-2' /> {t('reset')} {t('password')}
            </Button>
            <Button style={{ width: 90 }} size='sm' onClick={() => dispatch(getUser())}>
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
          </>
        }
      />
      <AddDialog
        modalOpen={modalOpen}
        title={editData ? t('system_user_management.edit_user') : t('system_user_management.add_new_user')}
        onToggle={() => {
          setModalOpen(!modalOpen);
          dispatch(getUser());
        }}
        editData={editData}
        onSubmit={(values) => {
          setModalOpen(false);
          if (editData) dispatch(updateUserRole({ ...values, _id: editData?._id }));
          else dispatch(createUser(values));

          setTimeout(() => dispatch(getUser()), 1000);
        }}
        onPermissionChange={(permissions) => {
          dispatch(updateUserWaybillPermission(permissions, editData?._id));
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={t('system_user_management.delete_user')}
        body={`${t('confirm_delete')} ${editData?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(deleteUser(editData?._id));
          setTimeout(() => dispatch(getUser()), 1000);
        }}
      />
      <WaybillPermssionDialog
        editData={editData}
        modalOpen={waybillPermissionModal}
        onToggle={() => {
          setWaybillPermissionModal(false);
          dispatch(getUser());
        }}
        onPermissionChange={(permissions) => {
          dispatch(updateUserWaybillPermission(permissions, editData?._id));
        }}
      />
    </>
  );
}
export default UserManagement;
