/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import { t } from 'i18next';
import PropTypes from 'prop-types';
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';

import { moneyFormatter } from 'utli';

function CardsHeader({ count }) {
  return (
    <>
      <div className='header pb-6 pt-6'>
        <Container fluid>
          <div className='header-body'>
            <Row>
              <Col md='6' xl='3'>
                <Card className='card-stats'>
                  <CardBody>
                    <Row>
                      <div className='col'>
                        <CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
                          {t('total_waybill')}
                        </CardTitle>
                        <span className='h2 font-weight-bold mb-0'>{moneyFormatter(count?.waybill_count)}</span>
                      </div>
                      <Col className='col-auto'>
                        <div className='icon icon-shape bg-gradient-red text-white rounded-circle shadow'>
                          <i className='ni ni-active-40' />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md='6' xl='3'>
                <Card className='card-stats'>
                  <CardBody>
                    <Row>
                      <div className='col'>
                        <CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
                          {t('new_users')}
                        </CardTitle>
                        <span className='h2 font-weight-bold mb-0'>{moneyFormatter(count?.customer_count)}</span>
                      </div>
                      <Col className='col-auto'>
                        <div className='icon icon-shape bg-gradient-orange text-white rounded-circle shadow'>
                          <i className='ni ni-chart-pie-35' />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md='6' xl='3'>
                <Card className='card-stats'>
                  <CardBody>
                    <Row>
                      <div className='col'>
                        <CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
                          {t('total_sales')}
                        </CardTitle>
                        <span className='h2 font-weight-bold mb-0'>
                          {moneyFormatter(Math.round(count?.sale_total))}
                        </span>
                      </div>
                      <Col className='col-auto'>
                        <div className='icon icon-shape bg-gradient-green text-white rounded-circle shadow'>
                          <i className='ni ni-money-coins' />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md='6' xl='3'>
                <Card className='card-stats'>
                  <CardBody>
                    <Row>
                      <div className='col'>
                        <CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
                          {t('transcations')}
                        </CardTitle>
                        <span className='h2 font-weight-bold mb-0'> {moneyFormatter(count?.transaction_count)}</span>
                      </div>
                      <Col className='col-auto'>
                        <div className='icon icon-shape bg-gradient-primary text-white rounded-circle shadow'>
                          <i className='ni ni-chart-bar-32' />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;
