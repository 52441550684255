import React from 'react';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import WaybillDetailContent from './content';
import OrderDetailContent from 'pages/order/detailDialog/content';
import './index.scss';
import clsx from 'clsx';

function DetailDialog({
  data,
  modalOpen,
  onToggle,
  customItemName = '',
  customPaymentMethod = '',
  showSign = false,
  signInfo,
}) {
  return (
    <Modal isOpen={modalOpen} size='lg' backdrop={true} style={{ top: '1%', maxWidth: '85%' }}>
      <div className='pt-3 pb-3 pr-2' style={{ background: '#e9e9ef' }}>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <ModalBody className='waybill-detail-dialog'>
        {data?.order_id && <h1 className='text-center'>{data?.waybillnumber}</h1>}

        <Row>
          <Col md={6} className={clsx(data?.order_id && 'add-border')}>
            <WaybillDetailContent
              data={data}
              showSign={showSign}
              order_id={data?.order_id}
              signInfo={signInfo}
              customItemName={customItemName}
              customPaymentMethod={customPaymentMethod}
            />
          </Col>
          <Col md={6}>
            {data?.order_id && <OrderDetailContent data={data?.order_id} showWaybillNumber={!data?.order_id} />}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}
export default DetailDialog;
