import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import AddNewButton from 'components/ActionButtons/addNewButton';
import List from './list';
import AddDialog from './addDialog';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import * as PaymentTypeAction from 'store/actions/payment_type';
import { t } from 'i18next';

function PaymentType() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);

  const dispatch = useDispatch();

  const payment_types = useSelector((state) => state.other_maintenance.payment_types);

  const headers = ['no', 'name', 'payment_group', 'created_at', 'updated_at'];
  React.useEffect(() => {
    dispatch(PaymentTypeAction.getAll({}));
    dispatch(PaymentTypeAction.getPaymentGroup({}));
  }, [PaymentTypeAction.getAll, dispatch]);

  return (
    <>
      <List
        headers={headers}
        data={payment_types}
        onEdit={(data) => {
          setModalOpen(true);
          setEditData(data);
        }}
        onDelete={(data) => {
          setShowDelete(true);
          setEditData(data);
        }}
        buttons={
          <>
            <AddNewButton
              onClick={() => {
                setModalOpen(true);
                setEditData(null);
              }}
              type='payment_type'
            />
            <Button style={{ width: 95 }} size='sm' onClick={() => dispatch(PaymentTypeAction.getAll({}, true))}>
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
          </>
        }
      />
      <AddDialog
        title={
          editData
            ? t('informtaion_other_payment_method.add_new_payment_method')
            : t('informtaion_other_payment_method.edit_new_payment_method')
        }
        editData={editData}
        modalOpen={modalOpen}
        onToggle={(open) => setModalOpen(open)}
        onSubmit={(values) => {
          setModalOpen(false);
          if (editData)
            dispatch(
              PaymentTypeAction.update({
                ...values,
                paymentTypeId: editData?._id,
              })
            );
          else dispatch(PaymentTypeAction.save(values));
          setTimeout(() => dispatch(PaymentTypeAction.getAll({}, true)), 1000);
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={t('informtaion_other_payment_method.delete_new_payment_method')}
        body={`${t('delete_confirm')} ${editData?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(PaymentTypeAction.deleteData(editData?._id));
          setTimeout(() => dispatch(PaymentTypeAction.getAll({}, true)), 1000);
        }}
      />
    </>
  );
}

export default PaymentType;
