import React from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
function DeleteButton({ onClick, type }) {
  const role = useSelector((state) => state.user.role);
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    if (!type) {
      setShow(true);
      return;
    }
    const foundPermission = role.role.action_permission.root.children.find((e) => e.name === type);
    const showButton = foundPermission?.children.find((e) => e.name === 'delete' && e.status);
    setShow(showButton);
  }, []);

  if (show)
    return (
      <Button size='sm' color='danger' onClick={onClick}>
        <i className='fa fa-trash' />
      </Button>
    );

  return <></>;
}

export default DeleteButton;
