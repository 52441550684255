import React from 'react';
import { Modal, ModalBody, Row, Col, Button, FormGroup, Form } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import TextBox from 'components/Inputs/TextBox';
import SelectBox from 'components/SelectBox';
import { t } from 'i18next';

export default function AddDialog({ modalOpen, onToggle, title, onSubmit, editData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();

  const payment_groups = useSelector((state) => state.other_maintenance.payment_groups);
  const paymentGroupOptions = payment_groups.map((e) => {
    return {
      label: e.label,
      value: e.value,
    };
  });

  const submitData = async (values) => {
    reset();
    onSubmit(values);
  };

  React.useEffect(() => {
    if (editData) {
      setValue('name', editData?.name);
      setValue('payment_group', editData?.payment_group);
      setValue('decription', editData?.decription);
    } else {
      reset();
    }
  }, [editData]);

  return (
    <Modal isOpen={modalOpen} style={{ top: '25%' }} backdrop={true}>
      <div className=' modal-header'>
        <h5 className=' modal-title'>
          {title} {editData?.name}
        </h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitData)}>
          <Row>
            <Col>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('informtaion_other_payment_method.payment_group')}</label>
                <SelectBox
                  control={control}
                  name='payment_group'
                  options={paymentGroupOptions}
                  value={editData?.payment_group}
                  registerProps={register('payment_group', {
                    required: `${t('informtaion_other_payment_method.payment_group')} ${t('is_required')}`,
                  })}
                  label={t('informtaion_other_payment_method.payment_group')}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('name')}</label>
                <TextBox
                  className='form-control'
                  placeholder={t('name')}
                  type='text'
                  registerProps={register('name', {
                    required: `${t('name')} ${t('is_required')}`,
                  })}
                  errors={errors.name}
                  defaultValue={editData?.name}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup className={classnames('mb-3')}>
            <label>{t('description')}</label>
            <TextBox
              className='form-control'
              placeholder={t('description')}
              type='text'
              registerProps={register('description')}
              errors={errors.description}
              defaultValue={editData?.description}
            />
          </FormGroup>

          <div className='text-center'>
            <Button classNameå='my-4' color='success' type='button' onClick={() => onToggle(!modalOpen)}>
              {t('cancel')}
            </Button>
            <Button
              className='my-4'
              color='primary'
              type='submit'
              // disabled={loading}
            >
              {/* {loading && (
                <Spinner size="sm" role="status" className="mr-2"></Spinner>
              )} */}
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
