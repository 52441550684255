import React from 'react';
import './a5_en_print_style.scss';
import Barcode from 'react-barcode';
import moment from 'moment';
import { moneyDecimalFormatter, customRound } from 'utli';
class A5CNPrintComponent extends React.Component {
  render() {
    const { data } = this.props;

    if (!data) return null;

    return (
      <div className={'Finance-Print'}>
        <div className={'Container'}>
          <div className={'LeftContainer'}>
            {/** Left Side */}
            <div className={'ContainerHeader'}>
              <div className={'Container'}>
                {/** Logo and Barcode */}
                <div className={'LogoContainer'}></div>
                <div className='BarCodeContainer'>
                  <h2>INVOICE</h2>
                  <Barcode
                    fontOptions='bolder'
                    value={data?.waybillnumber}
                    width={2}
                    height={50}
                    fontSize={34}
                    margin={3}
                  />
                </div>
              </div>

              <div className='CodesContainer'>
                <h4>Customer code: {data.address_code}</h4>
                <h4>Salesperson code: {data.sale_person?.name}</h4>
              </div>
            </div>
            <div className={'ContainerItem'}>
              <div className={'Container'}>
                {/**container */}
                <h4 className={'Header'}>
                  {/**header */}
                  托运人信息
                </h4>
                <h4 className={'Header'}>
                  {/**header */}
                  收货人信息
                </h4>
              </div>
              {/* <div className={'Divider'} /> */}
              <div className={'Container'}>
                {/**container */}
                <div className={'Item'}>
                  <p>{data?.sender?.company}</p>
                  <p>{data?.sender.name}</p>
                  <p>{data?.sender.phone}</p>
                  <p>{data?.sender.address}</p>
                </div>
                <div className={'Item'}>
                  <p>{data?.receiver?.company}</p>
                  <p>{data?.receiver.name}</p>
                  <p>{data?.receiver.phone}</p>
                  <p>{data?.receiver.address}</p>
                </div>
              </div>
            </div>
            <div className={'ContainerItem'}>
              <div className={'Container'}>
                {/**container */}
                <h4 className={'Header'}>
                  {/**header */}
                  基本信息
                </h4>
              </div>
              {/* <div className={'Divider'} /> */}
              <div className={'Container'}>
                {/**container */}
                <div className={'Item'}>
                  <p>寄件网点 : {data?.createdBranch?.name}</p>
                  <p>运输类型 : {data?.shipment_type.name}</p>
                  <p>交货方式 : {data?.delivery_mode ? data?.delivery_mode : '-'} </p>
                  <p>转运单号 : {data?.transfer_no ? data?.transfer_no : '-'} </p>
                </div>
                <div className={'Item'}>
                  <p>目的网点 : {data?.destination?.name}</p>
                  <p>运输方式 : {data?.shipping_mode.name}</p>
                  <p>托运日期 : {moment(Date.parse(data?.shipping_date)).format('DD-MM-YYYY')}</p>
                  <p>转运公司 : {data?.transfer_company ? data?.transfer_company : '-'} </p>
                </div>
              </div>
            </div>
            <div className={'ContainerItem'}>
              <div className={'Container'}>
                {/**container */}
                <h4 className={'Header'}>
                  {/**header */}
                  物品信息
                </h4>
              </div>
              {/* <div className={'Divider'} /> */}
              <div className={'Container'}>
                {/**container */}
                <div className={'Item'}>
                  <p>物品类型 : {data?.item.itemtype.name}</p>
                  <p>数量 : {data?.item.qty}</p>
                  <p>体积(CBM) : {data?.item.volume === null ? '-' : data?.item.volume} </p>
                  <p>备注 : {data?.item.remark === null ? '-' : data?.item.remark} </p>
                </div>
                <div className={'Item'}>
                  <p>物品名称 : {data?.item.itemname === '' ? '-' : data?.item.itemname}</p>
                  <p>重量(KGS) : {data?.item.weight}</p>
                  <p>价值: {moneyDecimalFormatter(data?.item.item_value)}</p>
                  <p>评论: {data?.item?.remark}</p>
                </div>
              </div>
            </div>
            <div className={'ContainerItem'}>
              <div className={'Container'}>
                {/**container */}
                <h4 className={'Item'}>
                  {/**header */}
                  费用信息
                </h4>
                <h4 className={'Item'}>Payment method: {data?.cost.paymentType.name}</h4>
              </div>
              <div className={'Divider'} />

              {/* {data?.cost.insurance_fee > 0 ? (
                <div className={'Container'}>
                  <p>保费</p>
                  <p>{moneyDecimalFormatter(data?.cost.insurance_fee)}</p>
                </div>
              ) : (
                ''
              )}
              {data?.cost.pickup_fee > 0 ? (
                <div className={'Container'}>
                  <p>提货费</p>
                  <p>{moneyDecimalFormatter(data?.cost.pickup_fee)}</p>
                </div>
              ) : (
                ''
              )}
              {data?.cost.storage_fee > 0 ? (
                <div className={'Container'}>
                  <p>仓储费</p>
                  <p>{moneyDecimalFormatter(data?.cost.storage_fee)}</p>
                </div>
              ) : (
                ''
              )}
              {data?.cost.clearence_fee > 0 ? (
                <div className={'Container'}>
                  <p>过关费</p>
                  <p>{moneyDecimalFormatter(data?.cost.clearence_fee)}</p>
                </div>
              ) : (
                ''
              )}
              {data?.cost.loading_fee > 0 ? (
                <div className={'Container'}>
                  <p>装卸费</p>
                  <p>{moneyDecimalFormatter(data?.cost.loading_fee)}</p>
                </div>
              ) : (
                ''
              )}
              {data?.cost.prepaid_for_client > 0 ? (
                <div className={'Container'}>
                  <p>垫付款</p>
                  <p>{moneyDecimalFormatter(data?.cost.prepaid_for_client)}</p>
                </div>
              ) : (
                ''
              )}
              {data?.cost?.packaging_fee > 0 ? (
                <div className={'Container'}>
                  <p>包装费</p>
                  <p>{data?.cost.packaging_fee}</p>
                </div>
              ) : (
                ''
              )} */}
              {/* <div className={'Container'}>
                <p>基础运费</p>
                <p>
                  {moneyDecimalFormatter(data?.cost.org_basic_freight)}
                </p>
                <p>
                  {moneyDecimalFormatter((data?.cost.qty_basic_freight))}<span> </span>
                  {getCargoType(data?.item?.weight / data?.item?.volume) === "Light Cargo" ? "Cbm" : "Kg"}
                </p>
                <p>
                  {moneyDecimalFormatter(data?.cost.basic_freight)}
                </p>
              </div> */}
            </div>
            {/* <div className={'Divider'} /> */}
            <div className={'Total'}>
              <h3>总费 (THB): &nbsp; {moneyDecimalFormatter(customRound(data?.cost.total?.toFixed(2)))}</h3>
            </div>
            {/* <div className={'Footer'}>
              <h3 style={{ textAlign: 'center' }}>
                付款方式: {data?.cost.paymentType.name}
              </h3>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default A5CNPrintComponent;
