import { getData, sendData } from './apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';
export async function getAll(query) {
  try {
    let response = await getData(`container/get-all-refresh?${new URLSearchParams(query).toString()}`);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function save(values) {
  try {
    return await sendData('container/create', values);
  } catch (e) {
    NotificationManager.error(e.response.data.errors[0].msg);
    return false;
  }
}

export async function deleteData(_id) {
  try {
    let response = await sendData('container/delete', { id: _id });
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function update(values) {
  try {
    return await sendData('container/update', values, 'PATCH');
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}
