import React from 'react';
import EditButton from 'components/ActionButtons/editButton';
import DeleteButton from 'components/ActionButtons/deleteButton';
import Table from 'components/Table';
import dayjs from 'dayjs';
import { t } from 'i18next';
function List({ headers, data, onEdit, buttons, onDelete }) {
  return (
    <Table
      title={t('sidebar.other_maintenance')}
      subtitle={t('information_other_currency.currency_maintenance')}
      headers={headers.map((e) => (
        <th scope='col' key={`thead-${Math.random()}`}>
          {t(e)}
        </th>
      ))}
      buttons={buttons}
      data={data}
      rows={data.map((e, index) => (
        <tr key={`table-row-${Math.random()}`}>
          <td>{index + 1}</td>
          <td>{e.name}</td>
          <td>{dayjs(e.createdAt).format('DD/MM/YYYY HH:ss')}</td>
          <td>
            <EditButton onClick={() => onEdit(e)} />
            <DeleteButton onClick={() => onDelete(e)} />
          </td>
        </tr>
      ))}
    />
  );
}

export default List;
