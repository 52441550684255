import { getData, getForBlobData } from './apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';

export async function getAll(query) {
  try {
    let response = await getData(`transaction/get-all?${new URLSearchParams(query).toString()}`);

    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function exportData(query) {
  return await getForBlobData(`transaction/csv-export?${new URLSearchParams(query).toString()}`, {
    responseType: 'blob',
  });
}
