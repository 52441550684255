import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as InformationAction from 'store/actions/information';
import * as CustomClearenceAction from 'store/actions/customClearence';
import * as FinanceService from 'services/finance';
import * as EmployeeAction from 'store/actions/employee';
import { Button } from 'reactstrap';
import List from './list';
import AddDialog from './../addDialog';
import DeleteAllConfirm from './deleteAllConfirm';
import DetailDialog from './detailDialog';
import WaybillLabelExport from '../WaybillLabelExport';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import PrintButton from '../A5PrintComponent';
import useViewPermission from 'hooks/useViewPermission';
import FilterBox from './filter';
import * as WaybillAction from 'store/actions/waybill';
import * as WaybillService from 'services/waybill';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { NotificationManager } from 'react-notifications';
import { t } from 'i18next';
import useActionPermission from 'hooks/useActionPermission';

function WaybillQueryContent({ saleReport = false }) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);
  const [showDetail, setShowDetail] = React.useState(false);
  const [showDeleteAll, setShowDeleteAll] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState(null);
  const [selectedData, setSelectedData] = React.useState([]);
  const [searchType, setSearchType] = React.useState('waybillnumber');
  const [search, setSearch] = React.useState();
  const [dateSearch, setDateSearch] = React.useState();
  const { canEdit, canDelete } = useActionPermission('document');
  const { isOwn, isBranch } = useViewPermission(!saleReport ? 'document' : 'sale_report');

  const dispatch = useDispatch();
  const router = useHistory();

  const waybills = useSelector((state) => state.waybill.waybills);
  const actionLoading = useSelector((state) => state.loading.actionLoading);

  const headers = [
    {
      text: '',
      width: 40,
      className: 'fixed-cell left',
    },

    {
      text: 'waybill_entry.waybill',
      className: 'fixed-cell left',
      left: 55,
    },
    {
      text: 'waybill_entry.shipping_date',
      className: 'fixed-cell left',
      left: 160,
    },
    {
      text: 'waybill_entry.shipment_type',
    },
    {
      text: 'waybill_entry.shipping_mode',
    },
    {
      text: 'waybill_entry.delivery_mode',
    },
    {
      text: 'sidebar.custom_clearence',
    },
    {
      text: 'waybill_entry.delivery_place',
    },
    {
      text: 'waybill_entry.destination',
    },
    {
      text: 'address_book.code',
    },
    {
      text: 'waybill_entry.sender_company',
    },
    {
      text: 'address_book.sender',
    },
    {
      text: 'address_book.phonenumber',
    },
    {
      text: 'waybill_entry.receiver_company',
    },
    {
      text: 'address_book.receiver',
    },
    {
      text: 'address_book.phonenumber',
    },
    {
      text: 'waybill_entry.cargo_type',
    },
    {
      text: 'waybill_entry.item_type',
    },
    {
      text: 'waybill_entry.item_name',
    },
    {
      text: 'waybill_entry.qty',
    },
    {
      text: 'waybill_entry.weight',
    },
    {
      text: 'waybill_entry.volume',
    },
    {
      text: 'waybill_entry.value',
    },
    {
      text: 'waybill_entry.remark',
    },
    {
      text: 'waybill_entry.total',
    },
    {
      text: !saleReport ? 'waybill_entry.operator' : 'waybill_entry.sale_person',
      className: 'fixed-cell',
      right: 180,
    },
    {
      text: 'waybill_entry.order_date',
      className: 'fixed-cell',
      right: 80,
    },
    {
      text: 'waybill_entry.status',
      className: 'fixed-cell',
      right: 0,
    },
  ];

  React.useEffect(() => {
    dispatch(InformationAction.getBranch());
    dispatch(EmployeeAction.getMarketingPerson());
    dispatch(CustomClearenceAction.getAll({}, true));
  }, [dispatch, InformationAction.getBranch, CustomClearenceAction.getAll]);

  React.useEffect(() => {
    loadData(searchValue);
  }, [WaybillAction.getAll, router.location.search]);

  const loadData = (value = null) => {
    setSearchValue(value);
    let query = { limit: 10, page: 1 };
    if (router.location.search) query = queryString.parse(router.location.search);

    if (isOwn()) query.isOwn = !!isOwn();
    if (isBranch()) query.isBranch = !!isBranch();

    if (saleReport) query.saleReport = true;

    dispatch(WaybillAction.getAll(query, value ? value : searchValue ? searchValue : {}));
  };

  return (
    <>
      <List
        saleReport
        headers={headers}
        selectedData={selectedData}
        data={waybills}
        onDelete={async (data) => {
          let response = await FinanceService.getTranscationCount(data.waybillnumber);
          if (response.length > 0) {
            NotificationManager.error('This waybill has been used in transcation');
          } else {
            setShowDelete(true);
            setEditData(data);
          }
        }}
        onSelect={(e) => {
          if (selectedData.find((s) => s._id === e._id)) setSelectedData(selectedData.filter((s) => s._id !== e._id));
          else setSelectedData([...selectedData, e]);
        }}
        showDetail={(e) => {
          setShowDetail(true);
          setEditData(e);
        }}
        filter={
          <FilterBox
            searchType={searchType}
            setSearch={setSearch}
            search={search}
            setSearchType={setSearchType}
            setDateSearch={setDateSearch}
            dateSearch={dateSearch}
            onSearch={(value) => {
              setSelectedData([]);
              loadData(value);
            }}
          />
        }
        buttons={
          <>
            {canEdit() && (
              <Button
                style={{ width: 100 }}
                size='sm'
                disabled={selectedData.length === 0}
                onClick={async () => {
                  if (selectedData.length === 1) {
                    const transcations = await WaybillService.getTranscationWaybill(selectedData[0].waybillnumber);
                    if (transcations.length > 0) {
                      NotificationManager.error('This waybill has been used in transcation');
                    } else {
                      let cargoCountResponse = await WaybillService.getWaybillCargoCount(selectedData[0].waybillnumber);
                      if (cargoCountResponse > 0) {
                        NotificationManager.error('This waybill has been used in cargo');
                      } else {
                        setModalOpen(true);
                        setEditData(selectedData[0]);
                      }
                    }
                  } else {
                    NotificationManager.error('Please choose only one orwaybillder to edit');
                  }
                }}
              >
                <i className='fa fa-edit mr-2' /> {t('edit')}
              </Button>
            )}
            <Button
              style={{ width: 100 }}
              size='sm'
              disabled={actionLoading}
              onClick={() => {
                const query = {};
                if (isOwn()) query.isOwn = !!isOwn();
                if (isBranch()) query.isBranch = !!isBranch();
                if (saleReport) query.saleReport = true;
                dispatch(WaybillAction.exportData(searchValue ? { ...searchValue, ...query } : query));
              }}
            >
              {actionLoading ? <i className='fa fa-spinner mr-2' /> : <i className='fa fa-file-excel mr-2' />}
              {t('export')}
            </Button>
            <PrintButton selectedData={selectedData} />
            <WaybillLabelExport selectedData={selectedData} />
            <Button
              style={{ width: 100 }}
              size='sm'
              onClick={() => {
                window.location = '/waybill-query';
              }}
            >
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
            {canDelete() && (
              <Button
                style={{ width: 100 }}
                size='sm'
                disabled={selectedData.length === 0}
                onClick={() => {
                  setShowDeleteAll(true);
                }}
              >
                <i className='fa fa-trash mr-2' /> {t('delete')}
              </Button>
            )}
          </>
        }
      />
      <AddDialog
        title={editData ? `${t('edit')} ${t('waybill_entry.waybill')}` : `${t('add')} ${t('waybill_entry.waybill')}`}
        editData={selectedData[0]}
        modalOpen={modalOpen}
        onToggle={(open) => setModalOpen(open)}
        onSubmit={(values, uploadFiles) => {
          setModalOpen(false);

          if (editData) {
            values.original_waybill_id = editData._id;
            values.waybillnumber = editData.basicinfo?.waybill_no;
          }
          dispatch(WaybillAction.save(values, uploadFiles, true));
          setSelectedData([]);
          setTimeout(() => loadData(), 1000);
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`${t('delete')} ${t('waybill_entry.waybill')}`}
        body={`${t('confirm_delete')} ${editData?.waybillnumber}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(WaybillAction.deleteData(editData?._id));
          setTimeout(() => loadData(), 1000);
        }}
      />
      <DeleteAllConfirm
        data={selectedData}
        modalOpen={showDeleteAll}
        title={`${t('delete')} ${t('waybill_entry.waybill')}`}
        body={`${t('confirm_delete')}?`}
        onToggle={() => setShowDeleteAll(false)}
        onConfirm={() => {
          setShowDeleteAll(false);
          setSelectedData([]);
          dispatch(WaybillAction.deleteAllData({ waybill_id_list: selectedData }));
          setTimeout(() => loadData(), 1000);
        }}
        onRemoveSelected={(e) => {
          setSelectedData(selectedData.filter((s) => s._id !== e._id));
        }}
      />
      <DetailDialog
        data={editData}
        modalOpen={showDetail}
        onToggle={() => setShowDetail(false)}
        customItemName={editData?.item?.itemtype?.name}
        customPaymentMethod={editData?.cost.paymentType.name}
      />
    </>
  );
}

export default WaybillQueryContent;
