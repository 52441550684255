import * as EstimatedTimeService from 'services/estimatedTime';
import { SET_ESTIMATED_TIMES } from 'store/types/information';
export const getAll = (query) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await EstimatedTimeService.getAll(query);
    dispatch({
      type: SET_ESTIMATED_TIMES,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const save = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await EstimatedTimeService.save(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const update = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await EstimatedTimeService.update(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteData = (id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await EstimatedTimeService.deleteData(id);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};
