/* eslint-disable react/jsx-key */
import React, { useMemo } from 'react';
import FilterBox from './filter';
import * as WaybillService from 'services/waybill';

import { ReactComponent as OrderSvg } from 'assets/img/icons/Order.svg';
import { ReactComponent as ShipSvg } from 'assets/img/icons/Ship.svg';
import { ReactComponent as SignSvg } from 'assets/img/icons/Sign.svg';
import { ReactComponent as WarehouseSvg } from 'assets/img/icons/Warehouse.svg';
import { ReactComponent as WaybillSvg } from 'assets/img/icons/Waybill.svg';
import { ReactComponent as ArrowSvg } from 'assets/img/icons/arrow.svg';
import dayjs from 'dayjs';
import { Container, Col, Row } from 'reactstrap';

import './track-page.css';
import { t } from 'i18next';

import moment from 'moment';
import CargoInfo from './CargoInfo';
import CustomerInfo from './CustomerInfo';
import WaybillInfo from './WaybillInfo';

function WaybillTracking() {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [notFound, setNotFound] = React.useState(false);

  const getCalculatedDay = (data) => {
    var result = new Date(data.waybill_info?.createdAt);
    result.setDate(result.getDate() + Number(data.estimatedTime?.remark));
    return dayjs(result).format('YYYY-MM-DD');
  };

  const isAlreadySigned = useMemo(() => {
    if (!data) {
      return false;
    }
    if (data.logs.find((step) => step.status === 'SIGNED')) {
      return true;
    }
    return false;
  }, [data]);

  const getStepTitle = (status) => {
    switch (status) {
      case 'ORDERCREATED':
        return t('customer_service.order_created_label');

      case 'WAYBILLCREATED':
        return t('customer_service.waybill_created_label');

      case 'LOADED':
        return t('customer_service.way_started_label');

      case 'UNLOADED':
        return t('customer_service.way_ended_label');

      case 'SIGNED':
        return t('customer_service.signed_label');
      case 'COMMENT':
        return t('customer_service.waybill_status');

      default:
        return '';
    }
  };

  const getTrackingDate = (status, stepData) => {
    switch (status) {
      case 'ORDERCREATED':
        return moment(data.waybill_info?.order_id?.shipping_date).format('D/MMM/YY');

      case 'WAYBILLCREATED':
        return moment(data.waybill_info?.shipping_date).format('D/MMM/YY');

      case 'LOADED':
        return moment(stepData.way?.date).format('D/MMM/YY');

      case 'UNLOADED':
        return moment(stepData.way?.date).format('D/MMM/YY');

      case 'SIGNED':
        return moment(stepData.sign?.date).format('D/MMM/YY');

      default:
        return '';
    }
  };

  const getStepIcon = (status) => {
    switch (status) {
      case 'ORDERCREATED':
        return <OrderSvg style={{ width: 35, height: 35 }} />;

      case 'WAYBILLCREATED':
        return <WaybillSvg style={{ width: 35, height: 35 }} />;

      case 'LOADED':
        return <ShipSvg style={{ width: 35, height: 35 }} />;
      case 'UNLOADED':
        return <WarehouseSvg style={{ width: 35, height: 35 }} />;

      case 'SIGNED':
        return <SignSvg style={{ width: 35, height: 35 }} />;
      case 'COMMENT':
        return <SignSvg style={{ width: 35, height: 35 }} />;

      default:
        return '';
    }
  };

  const getSubStepTitle = (status, stepData) => {
    switch (status) {
      case 'ORDERCREATED':
        return data.createdBranch?.name;

      case 'WAYBILLCREATED':
        return data.createdBranch?.name;

      case 'LOADED':
        return '';

      case 'UNLOADED':
        return stepData?.info?.destination?.name;

      case 'SIGNED':
        return `${t('customer_service.signed_label')} ${stepData?.info?.sign_info?.name}`;

      case 'COMMENT':
        return stepData.content;

      default:
        return '';
    }
  };

  const onSearch = async (values) => {
    const data = await WaybillService.getTracking(values.waybill_number.trim());
    if (data) {
      if (data.logs[1]?.status === 'SIGNED') {
        data.logs = data.logs.filter((e) => e.status !== 'COMMENT');
      }
      setData(data);
      setLoading(false);
      setNotFound(false);
    } else {
      setData(null);
      setLoading(false);
      setNotFound(true);
    }
  };

  const generatedLogs = useMemo(() => {
    const existed = [];
    const logs = [];
    data?.logs?.reverse()?.forEach((log) => {
      if (!existed.find((e) => e.status === log.status)) {
        // existed.push(log)
        logs.push(log);
      }
    });
    return logs;
  }, [data]);

  return (
    <>
      <div>
        <FilterBox onSearch={onSearch} />
      </div>
      <div className='pl-5 pr-5'>
        {loading ? (
          <Container style={{ paddingTop: 20, paddingBottom: 20, textAlign: 'center' }}>{t('loading')}</Container>
        ) : (
          <>
            {!notFound && data && (
              <div className='title-box'>
                <h1>
                  {t('customer_service.waybill_number')} : {data.waybillnumber}
                </h1>
                <div className='info-box'>
                  <div>
                    <h3>{data.sender.name}</h3>
                    <h4>{data.createdBranch?.name}</h4>
                  </div>
                  <div style={{ flex: 1 }}>
                    <ArrowSvg className='arrow-svg' />
                  </div>
                  <div>
                    <h3>{data.receiver.name}</h3>
                    <h4>{data.destination?.name}</h4>
                  </div>
                </div>
                {data.estimatedTime && !isAlreadySigned && (
                  <div>
                    <h3>
                      {t('estimated_arrival_date')} : {getCalculatedDay(data)}
                    </h3>
                  </div>
                )}
              </div>
            )}
            <div className='mt-5'>
              {notFound && (
                <div className='d-flex justify-content-center'>
                  <h2 style={{ color: 'red' }}>{t('customer_service.waybill_notfound')}</h2>
                </div>
              )}

              {!notFound && data && (
                <>
                  <>
                    <Row className={'info_details'}>
                      <Col md={7} className={'info_details_left'}>
                        <Row className='mb-4'>
                          <WaybillInfo data={data} />
                        </Row>
                        <Row className='mb-3'>
                          <CargoInfo data={data} />
                        </Row>
                        <Row>
                          <CustomerInfo data={data} />
                        </Row>
                      </Col>
                      <Col md={5} className={'info_details_right'}>
                        <nav className='nav nav-pills flex-column'>
                          {generatedLogs.map((step) => (
                            <a className='nav-link' href='#'>
                              <div className='nav-container'>
                                <div className='icon'>{getStepIcon(step.status)}</div>
                              </div>
                              <div className='right-info'>
                                <span style={{ fontSize: 14 }}>
                                  {getStepTitle(step.status)} - {getSubStepTitle(step.status, step)}
                                </span>
                                <p style={{ fontSize: 14 }}>{getTrackingDate(step.status, step)}</p>
                              </div>
                            </a>
                          ))}
                        </nav>
                      </Col>
                    </Row>
                  </>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default WaybillTracking;
