import { getData } from './apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';
export async function getEmployee() {
  try {
    let response = await getData('employee/get-option');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getBranch() {
  try {
    let response = await getData('branch/get-option');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}
