import React from 'react';
import EntryForm from './inputForm';

function WaybillEntry() {
  return (
    <>
      <EntryForm />
    </>
  );
}
export default WaybillEntry;
