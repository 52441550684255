/* eslint-disable indent */
import React from 'react';
import * as styles from './print_waybill_label_component.module.css';

class PrintWayBillLabelCompnent extends React.Component {
  // componentDidMount() {
  //   const { data } = this.props
  //   const maxLength = 24

  //   if (data?.receiver?.name && data.receiver.name.length > maxLength) {
  //     let updatedName = data.receiver.name
  //     const regex = new RegExp(`^(.{1,${maxLength}})(\\s+|$)`)
  //     const match = updatedName.match(regex)

  //     if (match) {
  //       updatedName = match[1] + '<br />' + updatedName.slice(match[0].length)
  //     }

  //     this.nameRef.innerHTML = updatedName
  //   }
  // }

  render() {
    const data = this.props.data;

    if (!data) return null;

    // const { createdBranch, destination, address_code, shipping_date } = data

    return (
      <div className={styles.container}>
        <table className={styles.package}>
          <tr>
            <td style={{ textAlign: 'left' }}>
              <h1 className={styles.first}>Label</h1>
            </td>
            <td style={{ textAlign: 'right' }}>
              <h1 className={styles.firstGreater}>{data?.waybillnumber}</h1>
            </td>
          </tr>
          <tr>
            <td className={styles.second}>
              收货人
              <br />
              CONSIGNEE
            </td>
            <td>{data?.receiver?.name?.substr(0, 15)}</td>
          </tr>
          <tr>
            <td className={styles.second}>
              目的地站点
              <br />
              DESTINATION BRANCH
            </td>
            <td>{data?.destination?.name?.substr(0, 15)}</td>
          </tr>
          <tr>
            <td className={styles.second}>
              运输方式
              <br />
              SHIPPING MODE
            </td>
            <td>{data?.shipping_mode?.name?.substr(0, 15)}</td>
          </tr>
          <tr>
            <td className={styles.second}>
              包装数量
              <br />
              PACKING QUANTITY
            </td>
            <td>{data?.item?.qty}</td>
          </tr>
          <tr>
            <td colSpan='2'>
              <span className={styles.third}>
                {data?.address_code} - {data?.sale_person?._id?.name?.substr(0, 15)}
              </span>
            </td>
          </tr>
        </table>
        <div style={{ textAlign: 'left' }}>
          <span style={{ color: 'red', fontSize: '37px' }}> {data?.createdBranch?.address},</span>
        </div>
      </div>

      // <div className={styles.container}>
      //   <div className={styles.sub_container}>
      //     <img src={logo} alt="Company Logo" width={220} height={80} />
      //     <h3>PACKAGE {data?.item?.qty}</h3>
      //   </div>
      //   <h1>
      //     {createdBranch?.branchCode} - {destination?.branchCode}
      //   </h1>
      //   <div>
      //     <Barcode
      //       value={data?.waybillnumber}
      //       fontSize={36}
      //       fontFamily={'Roboto'}
      //       width={3}
      //       height={70}
      //     />
      //   </div>
      //   <div className={styles.sub_container}>
      //     <h3>EMP-ID: {data?.modifiedBy._id?.employee?.id_number}</h3>
      //     <h3>{dayjs(shipping_date).format('DD/MM/YYYY')}</h3>
      //   </div>
      // </div>
    );
  }
}

export default PrintWayBillLabelCompnent;
