import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ component: Component, ...restProps }) => {
  const userInfo = useSelector((state) => state.user.userInfo);
  return (
    <Route
      {...restProps}
      render={(props) => {
        if (userInfo) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/auth/login',
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
