import React from 'react';
// import logo from 'assets/img/brand/tag-bar-logo.jpg';
import Barcode from 'react-barcode';

import { getCargoType, moneyDecimalFormatter } from 'utli';
import './a5_en_print_style.scss';
import moment from 'moment';

class A5ENPrintComponent extends React.Component {
  render() {
    const { data } = this.props;

    if (!data) return null;

    return (
      <div>
        <div className={'Container'}>
          <div className={'LeftContainer'}>
            {/** Left Side */}
            <div className={'ContainerHeader'}>
              <div className={'LogoContainerNew'}>
                {/* <div className={'TitleContainer'}>
                  <img className={'Logo'} src={logo} alt='Company Logo' style={{ width: 100, height: 50 }} />
                </div> */}
                <Barcode value={data?.waybillnumber} width={1} height={50} fontSize={20} />
              </div>

              <div className='CodesContainer my-2'>
                <h4>Customer code: {data.address_code}</h4>
                <h4>Salesperson code: {data.sale_person?.name}</h4>
              </div>
            </div>
            <div className={'ContainerItem'}>
              <div className={'Container'}>
                {/**container */}
                <h4 className={'Header'}>
                  {/**header */}
                  Shipper Information
                </h4>
                <h4 className={'Header'}>
                  {/**header */}
                  Consignee Information
                </h4>
              </div>
              {/* <div className={'Divider'} /> */}
              <div className={'Container'}>
                {/**container */}
                <div className={'Item'}>
                  <p>{data?.sender?.company}</p>
                  <p>{data?.sender.name}</p>
                  <p>{data?.sender.phone}</p>
                  <p>{data?.sender.address}</p>
                </div>
                <div className={'Item'}>
                  <p>{data?.receiver?.company}</p>
                  <p>{data?.receiver.name}</p>
                  <p>{data?.receiver.phone}</p>
                  <p>{data?.receiver.address}</p>
                </div>
              </div>
            </div>
            <div className={'ContainerItem'}>
              <div className={'Container'}>
                {/**container */}
                <h4 className={'Header'}>
                  {/**header */}
                  Basic Information
                </h4>
              </div>
              {/* <div className={'Divider'} /> */}
              <div className={'Container'}>
                {/**container */}
                <div className={'Item'}>
                  <p>Departure branch: {data?.createdBranch?.name}</p>
                  <p>Shipping type: {data?.shipment_type.name}</p>
                  <p>Delivery mode: {data?.delivery_mode ? data?.delivery_mode : '-'} </p>
                  <p>Transfer no: {data?.transfer_no ? data?.transfer_no : '-'} </p>
                  <p>Shipping date: {moment(Date.parse(data?.shipping_date)).format('DD-MM-YYYY')}</p>
                </div>
                <div className={'Item'}>
                  <p>Destination Branch: {data?.destination?.name}</p>
                  <p>Shipping mode: {data?.shipping_mode.name}</p>
                  <p>Customs Clearence type：{data?.custom_clearence?.name}</p>
                  <p>Transfer company: {data?.transfer_company ? data?.transfer_company : '-'} </p>
                </div>
              </div>
            </div>
            <div className={'ContainerItem'}>
              <div className={'Container'}>
                {/**container */}
                <h4 className={'Header'}>
                  {/**header */}
                  Cargo Information
                </h4>
              </div>
              {/* <div className={'Divider'} /> */}
              <div className={'Container'}>
                {/**container */}
                <div className={'Item'}>
                  <p>Cargo name : {data?.item.itemname === '' ? '-' : data?.item.itemname}</p>
                  <p>Packing quantity : {data?.item.qty}</p>
                  <p>
                    Measurement:
                    {data?.item.volume === null ? '-' : data?.item.volume}{' '}
                  </p>
                  <p>Cargo Group：{data?.item.itemtype.name}</p>
                </div>
                <div className={'Item'}>
                  <p>Weight(KGS) : {data?.item.weight}</p>
                  <p>Cargo value: {moneyDecimalFormatter(data?.item.item_value)}</p>
                  <p>Cargo type: {getCargoType(data?.item?.weight / data?.item?.volume)}</p>
                </div>
              </div>
            </div>
            <h4 className='mt-2'>Sign</h4>
            <div className='Divider mb-2'></div>
            <span className='mt-2'>
              Your signature indicates that you have received the package and confirmed that the product information is
              correct, the packaging is intact, there are no scratch and damage on the surface of the product.
            </span>
          </div>
          <div className={'RightContainer'}>
            <div className={'ContainerHeader'}>
              <div className={'Container'}>
                {/** Logo and Barcode */}
                {/* <div className={'LogoContainer'}>
                  <img className={'Logo'} src={logo} alt='Company Logo' style={{ width: 100, height: 50 }} />
                  <h1>EASY FREIGHT MYANMAR CO.,LTD.</h1>
                  <span>
                    No.H-7,MayKha Housing
                    <br />
                    Thingaungyun Township,Yangon Division,Myanmar
                  </span>
                </div> */}
              </div>
              <div className='my-2'>
                <h2 className='text-center'>WAYBILL - {data?.waybillnumber}</h2>
              </div>
              <div className='my-2'>
                <h4>Customer Code - {data.address_code}</h4>
              </div>
              <div className={'ContainerItem mb-3'}>
                <div className={'Container'}>
                  {/**container */}
                  <h4 className={'Header'}>
                    {/**header */}
                    Receiver Information
                  </h4>
                </div>
                <div className='Item'>
                  <p>{data?.receiver?.company}</p>
                  <p>{data?.receiver?.name}</p>
                  <p>{data?.receiver?.phone}</p>
                </div>
              </div>
              <div className={'ContainerItem mb-3'}>
                <div className={'Container'}>
                  {/**container */}
                  <h4 className={'Header'}>
                    {/**header */}
                    Basic Information
                  </h4>
                </div>
                <div className='Item'>
                  <p>Departure Branch: {data?.createdBranch?.name}</p>
                  <p>Destination Branch: {data?.destination?.name}</p>
                  <p>Shipping mode: {data?.shipping_mode.name}</p>
                  <p>Delivery mode: {data?.delivery_mode ? data?.delivery_mode : '-'} </p>
                  <p>Shipping date: {moment(Date.parse(data?.shipping_date)).format('DD-MM-YYYY')}</p>
                </div>
              </div>
              <div className={'ContainerItem mb-3'}>
                <div className={'Container'}>
                  {/**container */}
                  <h4 className={'Header'}>
                    {/**header */}
                    Cargo Information
                  </h4>
                </div>
                <div className='Item'>
                  <p>Cargo name: {data?.item.itemname === '' ? '-' : data?.item.itemname}</p>
                  <p>Packing quantity: {data?.item.qty}</p>
                  <p>Weight: {data?.item.weight}</p>
                  <p>Measurement: {data?.item.volume === null ? '-' : data?.item.volume} </p>
                </div>
              </div>
              <div className='Divider'></div>

              <p className='mt-5'>
                Destination branch:
                {data?.destination?.address},{data?.destination?.township?.name},{data?.destination?.district?.name},
                {data?.destination?.region?.name}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default A5ENPrintComponent;
