/* eslint-disable no-undef */
import axios from 'axios';
import { getCache, removeCache } from 'utli/cache';
import { NotificationManager } from 'react-notifications';
import { t } from 'i18next';
export const HOST = process.env.REACT_APP_API_ENDPOINT;
export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + '/api/v1/';
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export async function getData(url, params) {
  let token = getCache('access_token');
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  axios.defaults.headers = headers;

  let response = await axios.get(`${apiEndpoint}${url}`, { params: params }).catch((e) => {
    if (e.response.status === 401) {
      NotificationManager.info('You need to login again');
      removeCache('access_token');
      window.location = '/';
    }
  });

  return response.data;
}

export async function sendData(url, data, type = 'POST', showNoti = true, multipart = false) {
  let token = getCache('access_token');
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  axios.defaults.headers = headers;
  let response;

  if (type === 'POST')
    response = await axios.post(
      `${apiEndpoint}${url}`,
      data,
      multipart && {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        },
      }
    );
  else if (type === 'DELETE') {
    response = await axios.delete(`${apiEndpoint}${url}`, { data: data });
  } else if (type === 'PUT') response = await axios.put(`${apiEndpoint}${url}`, data);
  else if (type === 'PATCH') response = await axios.patch(`${apiEndpoint}${url}`, data);

  if ((response.status === 200 || response.status === 201) && showNoti) {
    NotificationManager.success(t('success'));
  }

  return response.data;
}

export async function sendForBlobData(url, data, options) {
  let token = getCache('access_token');
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  axios.defaults.headers = headers;
  let response = await axios.post(`${apiEndpoint}${url}`, data, options);
  return response.data;
}

export async function getForBlobData(url, options) {
  let token = getCache('access_token');
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  axios.defaults.headers = headers;
  let response = await axios.get(`${apiEndpoint}${url}`, options);
  return response.data;
}
