import * as AddressBookService from 'services/addressBook';
import { SET_ADDRESSBOOKS } from 'store/types/information';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
export const getAll = (query, body) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await AddressBookService.getAll(query, body);
    dispatch({
      type: SET_ADDRESSBOOKS,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const save = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await AddressBookService.save(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const update = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await AddressBookService.update(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteData = (id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await AddressBookService.deleteData(id);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const exportData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });

    const response = await AddressBookService.exportData(data);
    const blob = await response;

    const temp_date = dayjs(new Date()).format('DD/MM/YYYY');
    saveAs(blob, `addressbook-${temp_date}.csv`);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};
