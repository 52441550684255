import React from 'react';
import { Button } from 'reactstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { NotificationManager } from 'react-notifications';
import ShippingMarkerPrint from './print_component';
import { t } from 'i18next';

function ShippingMaker({ selectedData }) {
  const onPrintClick = () => {
    setTimeout(() => {
      const input = document.getElementById('pdf_print');
      if (!input) return;
      input.style.display = 'block';
      html2canvas(input, { scale: 1.3 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'landscape',
          compress: false,
          unit: 'px',
          format: 'a5',
          floatPrecision: 'smart',
          precision: 1,
        });
        pdf.addImage(imgData, 'JPEG', 10, 20);
        pdf.save(`M${selectedData[0]?.waybillnumber}.pdf`);
      });
      input.style.display = 'none';
    }, 200);
  };
  return (
    <>
      <Button
        style={{ width: 100 }}
        size='sm'
        disabled={selectedData.length === 0}
        onClick={() => {
          if (selectedData.length === 1) {
            onPrintClick();
          } else {
            NotificationManager.error('Please choose only one order');
          }
        }}
      >
        <i className='fa fa-file-archive mr-2' />
        {t('waybill_entry.shipping_marker')}
      </Button>
      {selectedData.length > 0 && <ShippingMarkerPrint data={selectedData[0]} />}
    </>
  );
}

export default ShippingMaker;
