/* eslint-disable react/jsx-key */
import CheckBox from 'components/Inputs/CheckBox';
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import CollapseBox from 'components/CollapseBox';

export default function ActionManageDialog({ modalOpen, onToggle, data, update_Permission }) {
  return (
    <Modal isOpen={modalOpen} backdrop={true} size='lg' style={{ maxWidth: '1000px', width: '100%' }}>
      <div className=' modal-header' style={{ paddingBottom: 5 }}>
        <h5 className=' modal-title'>{data?.rolename}</h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody style={{ paddingTop: 5 }}>
        {data?.action_permission?.root.children.map((e, mainIndex) => (
          <div className='mb-3'>
            <CollapseBox
              header={
                <span
                  style={{
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                  }}
                >
                  {e.name}
                </span>
              }
              child={
                <div className='d-flex mt-2 pl-2 flex-wrap'>
                  {e.children.map((sub, subIndex) => (
                    <div className='ml-3'>
                      <CheckBox
                        key={Math.random()}
                        label={sub.name}
                        checked={sub.status}
                        onChange={() =>
                          update_Permission(`root.children.${mainIndex}.children.${subIndex}`, !sub.status)
                        }
                        rootKey={`sub-${subIndex}-${mainIndex}`}
                      />
                    </div>
                  ))}
                </div>
              }
            />
          </div>
        ))}
      </ModalBody>
    </Modal>
  );
}
