/* eslint-disable react/jsx-key */
import React from 'react';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import * as OrderAction from 'store/actions/order';
import FileThumbnail from './FileThumbnail';
import NetworkFileThumbnailComponent from './NetworkFileThumbnailComponent';
import FullScreenImagePreviewComponent from './FullScreenImagePreviewComponent';
import './index.scss';
import { useDispatch } from 'react-redux';
function ImageUploadContainer({
  files = [],
  networkFiles = [],
  setFiles,
  showUpload = false,
  title,
  waybillnumber = null,
  type,
}) {
  const dispatch = useDispatch();
  const [previewImgUrl, setPreviewImgUrl] = React.useState(null);
  const [error, setError] = React.useState('');
  const [availableCount, setAvailableCount] = React.useState(10);
  const [networkImg, setNetworkImg] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [filename, setFilename] = React.useState('');
  const [deletedIndex, setDeletedIndex] = React.useState(null);

  const handlefilechange = (e) => {
    let uploadedFiles = e.target.files;
    if (uploadedFiles.length > 10) {
      setError('Only 10 files will be accepted');
      return;
    }

    if (uploadedFiles.length + files.length > 10) {
      setError('Only 10 files will be accepted');
      return;
    }

    if (uploadedFiles.length + files.length + networkImg.length > 10) {
      setError('Only 10 files will be accepted');
      return;
    }

    setAvailableCount(availableCount - uploadedFiles.length);
    setFiles((prevArray) => [...prevArray, ...uploadedFiles]);
    setError('');
  };

  React.useEffect(() => {
    setNetworkImg(networkFiles);
    setAvailableCount(availableCount - networkFiles.length);
  }, [networkFiles]);

  return (
    <div className='pt-1 pb-2'>
      <p>{title}</p>
      {showUpload && <input type='file' onChange={handlefilechange} multiple name='photos' id='photos' />}
      <div className='image-container'>
        {networkImg.map((file, i) => {
          return (
            <NetworkFileThumbnailComponent
              key={i}
              i={i}
              showUpload={showUpload}
              setPreviewImgUrl={setPreviewImgUrl}
              onDelete={() => {
                setFilename(file);
                setDeletedIndex(i);
                setOpen(true);
              }}
              e={file}
            />
          );
        })}

        {files.map((e, index) => (
          <FileThumbnail
            e={e}
            setPreviewImgUrl={setPreviewImgUrl}
            onDelete={() => {
              setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
              setAvailableCount(availableCount + 1);
            }}
          />
        ))}
      </div>
      {error && <p className='text-danger'>{error}</p>}
      <FullScreenImagePreviewComponent
        open={Boolean(previewImgUrl)}
        item={previewImgUrl}
        onClose={() => setPreviewImgUrl(null)}
      />
      <ConfirmDialog
        modalOpen={open}
        title='Delete Order File'
        body={'Are you sure to delete this file'}
        onToggle={() => setOpen(false)}
        onConfirm={() => {
          setOpen(false);
          dispatch(OrderAction.deleteImage(filename, waybillnumber, type));
          setNetworkImg([...networkImg.slice(0, deletedIndex), ...networkImg.slice(deletedIndex + 1)]);

          setTimeout(() => dispatch(OrderAction.getAll()), 1000);
        }}
      />
    </div>
  );
}

export default ImageUploadContainer;
