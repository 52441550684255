import { sendData, getData } from './apiService';
import { NotificationManager } from 'react-notifications';
import { storeCache, removeCache } from 'utli/cache';

export async function login(values) {
  try {
    let response = await sendData('users/login', values);
    if (response.user) storeCache('user', JSON.stringify(response.user));
    storeCache('access_token', response.access_token);
    return response;
  } catch (e) {
    NotificationManager.error(e.response.data.errors ? e.response.data.errors[0].msg : e.response.data?.message);
    return false;
  }
}

export async function changePassword(values) {
  try {
    let response = await sendData('users/reset-password', values);
    return response;
  } catch (e) {
    NotificationManager.error('Login Failed');
    return false;
  }
}

export function logout() {
  removeCache('user');
  removeCache('access_token');
}

export async function getPermssion() {
  try {
    let response = await getData('user-roles/get-role');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getUserProfile(userId) {
  try {
    let response = await getData(`users/${userId}/profile-image`);
    return response;
  } catch (error) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function updateUserProfile(img, userId) {
  const formData = new FormData();
  formData.append('profileImage', img);

  try {
    let response = await sendData(`users/${userId}/profile-image`, formData, 'POST', true, true);
    return response;
  } catch (error) {
    NotificationManager.error('Invalid Image');
    return false;
  }
}

export async function updatePassword(password, userId) {
  const data = { password: password };
  try {
    let response = await sendData(`users/${userId}/change-password`, JSON.stringify(data), 'PUT');
    return response;
  } catch (e) {
    NotificationManager.error('Password Updating Failed');
    return false;
  }
}

// let response = await sendData(`users/${userId}/profile-image`, formData, 'POST', true, true);
