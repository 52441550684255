import React from 'react';
import { Modal, ModalBody, Row, Col, Button, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import TextBox from 'components/Inputs/TextBox';
import SelectBox from 'components/SelectBox';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

export default function AddDialog({ modalOpen, onToggle, title, onSubmit, editData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();

  const submitData = async (values) => {
    reset();
    onSubmit(values);
  };

  const branches = useSelector((state) => state.information.branches);
  if (!branches.map) {
    window.location.reload();
  }
  const branchOptions = branches?.map((e) => {
    return {
      label: e.label,
      value: e._id,
    };
  });

  const positions = useSelector((state) => state.information.positions);
  const positionOptions = positions?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  React.useEffect(() => {
    if (editData) {
      setValue('name', editData?.name);
      setValue('phonenumber', editData?.phonenumber);
      setValue('branch', editData?.branch?._id);
      setValue('position', editData?.position?._id);
      setValue('address', editData?.address);
      setValue('id_number', editData?.id_number);
    } else {
      reset();
    }
  }, [editData]);

  return (
    <Modal isOpen={modalOpen} style={{ top: '25%' }} backdrop={true}>
      <div className=' modal-header'>
        <h5 className=' modal-title'>
          {title} {editData?.name}
        </h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitData)}>
          <Row>
            <Col md='6' className='mt-2'>
              <label>{t('branch')}</label>

              <SelectBox
                control={control}
                name='branch'
                options={branchOptions}
                value={editData?.branch}
                rules={{ required: true }}
                label={t('branch')}
              />
            </Col>
            <Col md='6' className='mt-2'>
              <label>{t('position')}</label>
              <SelectBox
                control={control}
                name='position'
                options={positionOptions}
                value={editData?.position}
                rules={{ required: true }}
                label={t('position')}
              />
            </Col>
            <Col md='6' className='mt-2'>
              <label>{t('name')}</label>
              <TextBox
                className='form-control'
                placeholder={t('name')}
                type='text'
                registerProps={register('name', {
                  required: `${t('name')} ${t('is_required')}`,
                })}
                errors={errors.name}
                defaultValue={editData?.name}
              />
            </Col>
            <Col md='6' className='mt-2'>
              <label>{t('information_employee_maintenance.phone_number')}</label>
              <TextBox
                className='form-control'
                placeholder={t('information_employee_maintenance.phone_number')}
                type='text'
                registerProps={register('phonenumber', {
                  required: `${t('information_employee_maintenance.phone_number')} ${t('is_required')}`,
                })}
                errors={errors.phonenumber}
                defaultValue={editData?.phonenumber}
              />
            </Col>

            <Col md='6' className='mt-2'>
              <label>{t('information_employee_maintenance.employee_id')}</label>
              <TextBox
                className='form-control'
                placeholder={t('information_employee_maintenance.employee_id')}
                type='text'
                registerProps={register('id_number')}
                errors={errors.id_number}
                defaultValue={editData?.id_number}
              />
            </Col>
            <Col md='6' className='mt-2'>
              <label>{t('address')}</label>
              <TextBox
                className='form-control'
                placeholder={t('address')}
                type='text'
                registerProps={register('address', {
                  required: `${t('address')} is required`,
                })}
                errors={errors.address}
                defaultValue={editData?.address}
              />
            </Col>
          </Row>

          <div className='text-center'>
            <Button className='my-4' color='success' type='button' onClick={() => onToggle(!modalOpen)}>
              {t('cancel')}
            </Button>
            <Button className='my-4' color='primary' type='submit'>
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
