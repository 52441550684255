import React from 'react';
import { Modal } from 'reactstrap';
import './index.scss';
const FullScreenImagePreviewComponent = ({ open = false, item, onClose }) => {
  const renderItem = (item) => {
    const ext = item.name.substr(item.name.lastIndexOf('.') + 1);
    if (ext === 'pdf') {
      return <iframe src={item.url} title={item.name} />;
    } else if (ext === 'png' || ext === 'jpg' || ext === 'jpeg' || ext === 'gif' || ext === 'jfif') {
      return <img src={item.url} alt={item.name} />;
    } else {
      return <iframe src={item.url} title={item.name} />;
    }
  };
  return (
    <Modal
      isOpen={open}
      size='lg'
      style={{
        maxWidth: '1400px',
        width: '100%',
        minWidth: 400,
        height: 200,
      }}
      backdrop={true}
    >
      <div className='preview_container'>
        <span className='close-btn' onClick={() => onClose()} aria-hidden={true}>
          ×
        </span>
        {item && renderItem(item)}
      </div>
    </Modal>
  );
};

export default FullScreenImagePreviewComponent;
