import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, Button } from 'reactstrap';
import List from './list';
import { t } from 'i18next';
import * as QuoteAction from 'store/actions/quote';

function Quotes() {
  const dispatch = useDispatch();
  const [quote, setQuote] = React.useState(null);

  const quotes = useSelector((state) => state.other_maintenance.quotes);

  const headers = [
    'no',
    'name',
    'email',
    'phone',
    'prefer_contact',
    'company',
    'business_description',
    'created_at',
    '',
  ];
  React.useEffect(() => {
    dispatch(QuoteAction.getAll({}));
  }, [QuoteAction.getAll, dispatch]);

  return (
    <>
      <List
        headers={headers}
        data={quotes}
        viewSuggestion={(data) => {
          setQuote(data);
        }}
      />
      <Modal isOpen={quote} style={{ top: '5%', maxWidth: '40%', minWidth: 450 }} backdrop={true}>
        <ModalBody>
          <div className='py-3'>
            <p>{quote?.feedback}</p>
          </div>
          <div className='text-right'>
            <Button className='my-4' color='success' type='button' onClick={() => setQuote(null)}>
              {t('ok')}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
export default Quotes;
