import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import TextBox from 'components/Inputs/TextBox';
import DateRangePicker from 'components/Inputs/DateRangePicker';
import { t } from 'i18next';

function FilterBox({ onSearch }) {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };

  const branches = useSelector((state) => state.information.branches);
  let branchOptions = [];
  if (branches.map) {
    branchOptions = branches?.map((e) => {
      return {
        label: e.label,
        value: e._id,
      };
    });
  }

  const [searchType, setSearchType] = React.useState('waybill_number');
  const [search, setSearch] = React.useState();
  const [dateSearch, setDateSearch] = React.useState();
  return (
    <>
      <Row>
        <Col className='mb-1' md='5' sm='6'>
          {searchType === 'destination' && (
            <Select
              styles={customStyles}
              options={branchOptions}
              onChange={(value) => setSearch(value.value)}
              value={branchOptions.find((b) => b._id === search)}
            />
          )}

          {['waybill_number', 'customer_code'].includes(searchType) && (
            <TextBox
              onChange={(e) => setSearch(e.target.value)}
              style={{ height: 34 }}
              value={typeof search === 'object' ? '' : search}
              placeholder={`${searchType}`}
            />
          )}
        </Col>
        <Col className='mb-1' md='2' sm='6'>
          <Select
            styles={customStyles}
            defaultValue={{
              value: 'waybill_number',
              label: t('customer_service.waybill_number'),
            }}
            onChange={(val) => {
              setSearch('');
              setSearchType(val.value);
            }}
            options={[
              {
                value: 'waybill_number',
                label: t('customer_service.waybill_number'),
              },

              { value: 'customer_code', label: t('waybill_entry.code') },
            ]}
          />
        </Col>

        <Col className='mb-1' md='4' sm='6' xs='10'>
          <DateRangePicker
            onDateChange={(dates) => {
              setDateSearch({
                startDate: dates[0],
                endDate: dates[1],
              });
            }}
          />
        </Col>

        {searchType && (
          <Button
            size='sm'
            color='success'
            style={{ height: 35 }}
            onClick={() => {
              if (search || dateSearch) {
                // setSearch('')
                const searchData = {};
                if (search) searchData[searchType] = search;
                if (dateSearch?.startDate) {
                  searchData.startDate = dateSearch.startDate;
                  searchData.endDate = dateSearch.endDate || new Date();
                }
                onSearch(searchData);
              }
            }}
          >
            <i className='fa fa-search' />
          </Button>
        )}
      </Row>
    </>
  );
}

export default FilterBox;
