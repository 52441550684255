import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import AddNewButton from 'components/ActionButtons/addNewButton';
import List from './list';
import AddDialog from './addDialog';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import * as PositionAction from 'store/actions/position';
import { t } from 'i18next';

function Position() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);

  const dispatch = useDispatch();

  const positions = useSelector((state) => state.information.positions);

  const headers = ['no', 'name', 'created_at', ''];
  React.useEffect(() => {
    dispatch(PositionAction.getAll({}));
  }, [PositionAction.getAll, dispatch]);

  return (
    <>
      <List
        headers={headers}
        data={positions}
        onEdit={(data) => {
          setModalOpen(true);
          setEditData(data);
        }}
        onDelete={(data) => {
          setShowDelete(true);
          setEditData(data);
        }}
        buttons={
          <>
            <AddNewButton
              type='country'
              onClick={() => {
                setModalOpen(true);
                setEditData(null);
              }}
            />
            <Button style={{ width: 100 }} size='sm' onClick={() => dispatch(PositionAction.getAll({}, true))}>
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
          </>
        }
      />
      <AddDialog
        title={editData ? `${t('edit')} ${t('position')}` : `${t('add')} ${t('position')}`}
        editData={editData}
        modalOpen={modalOpen}
        onToggle={(open) => setModalOpen(open)}
        onSubmit={(values) => {
          setModalOpen(false);
          if (editData) dispatch(PositionAction.update({ ...values, _id: editData?._id }));
          else dispatch(PositionAction.save(values));
          setTimeout(() => dispatch(PositionAction.getAll({}, true)), 1000);
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`${t('delete')} ${t('position')}`}
        body={`${t('confirm_delete')} ${editData?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(PositionAction.deleteData(editData?._id));
          setTimeout(() => dispatch(PositionAction.getAll()), 1000);
        }}
      />
    </>
  );
}

export default Position;
