import React from 'react';
import { Modal, ModalBody, Button, Form, Row, Col, FormGroup, ModalHeader } from 'reactstrap';
import * as EmployeeAction from 'store/actions/employee';
import { getCountryOption } from 'store/actions/location';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { fetchWaybillNumber } from 'services/order';
import BasicInfoForm from '../basicInfoForm';
import ItemInfoForm from '../itemInfoForm';
import CostInfoForm from '../costInfoForm';
import { getCargoType } from 'utli';
// import FinancialForm from '../financialForm';
import ImageUploadContainer from '../ImageUploadContainer';
import TextBox from 'components/Inputs/TextBox';
import AdressInputForm from 'pages/information/addressbook/inputForm';
import './add-dialog.scss';
import { t } from 'i18next';

export default function AddDialog({ modalOpen, onToggle, onSubmit, editData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    getValues,
    watch,
  } = useForm();

  const submitData = async (values) => {
    reset();
    const uploadFiles = {
      orderImageFiles: orderImageFiles,
      deliveryImageFiles: deliveryImageFiles,
    };

    setDeliveryImageFiles([]);
    setOrderImageFiles([]);

    onSubmit(values, uploadFiles);
  };
  const [orderImageFiles, setOrderImageFiles] = React.useState([]);
  const [deliveryImageFiles, setDeliveryImageFiles] = React.useState([]);

  const dispatch = useDispatch();
  const countries = useSelector((state) => state.location.countries);

  React.useEffect(() => {
    if (editData) {
      setValue('basicinfo.waybill_no', editData?.waybillnumber);
      setValue('basicinfo.shipment_type', editData?.shipment_type._id);
      setValue('basicinfo.shipment_type_name', editData?.shipment_type.name);
      setValue('basicinfo.shipping_mode', editData?.shipping_mode._id);
      setValue('basicinfo.delivery_mode', editData?.delivery_mode);
      setValue('basicinfo.delivery_place', editData?.delivery_place);
      setValue('basicinfo.delivery_no', editData?.delivery_no);
      setValue('basicinfo.delivery_company', editData?.delivery_company);
      setValue('basicinfo.transfer_no', editData?.transfer_no);
      setValue('basicinfo.custom_clearence', editData?.custom_clearence?._id);

      setValue('basicinfo.sales_person', editData?.sales_person?._id);
      setValue('basicinfo.transfer_company', editData?.transfer_company);
      setValue('basicinfo.destination', editData?.destination._id);
      setValue('basicinfo.createdBranch', editData?.createdBranch._id);
      setValue('basicinfo.shipping_date', editData?.shipping_date);
      setValue('iteminfo', editData?.item);
      setValue('iteminfo.itemtype', editData?.item?.itemtype?._id);
      setValue('costinfo', editData?.cost);
      setValue('costinfo.paymentType', editData?.cost.paymentType._id);
      setValue('senderinfo', editData.sender);
      setValue('senderinfo.code', editData.address_code);
      setValue('senderinfo.marketing_person', editData.sale_person?._id);

      setValue('receiverinfo', editData.receiver);

      let result = getCargoType(getValues('iteminfo.weight') / getValues('iteminfo.volume'));

      if (result === 'Light Cargo') {
        setValue('costinfo.qty_basic_freight_label', 'Cbm');
      } else {
        setValue('costinfo.qty_basic_freight_label', 'Kg');
      }
    } else {
      reset();
    }
  }, [editData]);

  React.useEffect(() => {
    dispatch(EmployeeAction.getMarketingPerson());
    dispatch(getCountryOption());
  }, [dispatch, EmployeeAction.getMarketingPerson, getCountryOption]);

  const onFetchWaybillNumber = async () => {
    const response = await fetchWaybillNumber();
    if (response) {
      setValue('generated_waybill_number', response);
      setValue('basicinfo.waybill_no', response.waybillnumber);
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      size='lg'
      style={{
        maxWidth: '1400px',
        width: '80%',
        minWidth: 400,
      }}
      backdrop={true}
    >
      {editData && (
        <ModalHeader style={{ background: '#e9e9ef' }}>
          <div>
            <h3>Waybill Number : {editData.waybillnumber}</h3>
          </div>
        </ModalHeader>
      )}
      <ModalBody style={{ maxHeight: '85vh', overflowY: 'auto' }}>
        <Form onSubmit={handleSubmit(submitData)} className='order-modal'>
          <BasicInfoForm
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            editData={editData}
            onFetchWaybillNumber={onFetchWaybillNumber}
            watch={watch}
          />
          <div className='divider'></div>
          <AdressInputForm
            register={register}
            errors={errors}
            control={control}
            options={{ countries: countries }}
            editData={editData}
            addNew={false}
            setValue={setValue}
            types={['senderinfo', 'receiverinfo']}
            getValues={getValues}
          />
          <div className='divider'></div>
          <ItemInfoForm
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            editData={editData}
          />
          <div className='divider'></div>
          <Row style={{ padding: 0, margin: 0 }}>
            <Col md='12'>
              <CostInfoForm
                register={register}
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                editData={editData}
              />
            </Col>
          </Row>
          <div className='divider'></div>
          <Row style={{ padding: 0, margin: 0 }}>
            <Col md='4'>
              <ImageUploadContainer
                files={orderImageFiles}
                setFiles={setOrderImageFiles}
                showUpload={true}
                networkFiles={editData?.order_file_names}
                waybillnumber={editData?._id}
                title={t('waybill_entry.packaging_list')}
                type='order'
              />
            </Col>
            <Col md='4'>
              <ImageUploadContainer
                files={deliveryImageFiles}
                setFiles={setDeliveryImageFiles}
                showUpload={true}
                networkFiles={editData?.delivery_file_names}
                waybillnumber={editData?._id}
                title={t('waybill_entry.delivery_file_upload')}
                type='order'
                accept='.png, .jpg, .jpeg'
              />
            </Col>
            <Col md='2' className='mt-1'>
              <FormGroup>
                <label>{t('waybill_entry.delivery_company')}</label>
                <TextBox
                  className='form-control mr-2'
                  type='text'
                  label={t('waybill_entry.delivery_company')}
                  registerProps={register('basicinfo.delivery_company')}
                  errors={errors?.basicinfo?.delivery_company}
                />
              </FormGroup>
            </Col>
            <Col md='2' className='mt-1'>
              <FormGroup>
                <label>{t('waybill_entry.delivery_no')}</label>
                <TextBox
                  className='form-control mr-2'
                  type='text'
                  label={t('waybill_entry.delivery_no')}
                  registerProps={register('basicinfo.delivery_no')}
                  errors={errors?.basicinfo?.delivery_no}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className='custom-modal-footer'>
            <Button color='success' type='button' onClick={() => onToggle(!modalOpen)}>
              {t('cancel')}
            </Button>
            <Button color='primary' type='submit'>
              {t('confirm')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
