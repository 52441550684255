import { login, logout, getPermssion, changePassword, getUserProfile, updateUserProfile } from 'services/authService';
import { SET_USER_INFO, SET_USER_ROLE, SET_USER_PROFILE, GET_USER_PROFILE } from 'store/types/auth';

export const updateProfileAction = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await updateUserProfile(data, id);
    if (response) {
      dispatch({
        type: SET_USER_PROFILE,
        payload: response,
      });
    }
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const getProfileAction = (userId) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await getUserProfile(userId);
    dispatch({
      type: GET_USER_PROFILE,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const loginAction = (data) => {
  return async (dispatch) => {
    let response = await login(data);
    if (response) {
      dispatch({
        type: SET_USER_INFO,
        payload: response,
      });

      if (!response.user) {
        window.location = '/auth/reset-password';
      } else {
        window.location = '/';
      }
    }
  };
};
export const changePasswordAction = (data) => {
  return async () => {
    let response = await changePassword(data);
    if (response) {
      window.location = '/auth/login';
    }
  };
};

export const getPermission = () => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_PERMISSION_LOADING',
      payload: true,
    });
    let response = await getPermssion();
    dispatch({
      type: SET_USER_ROLE,
      payload: response,
    });
    dispatch({
      type: 'SET_PERMISSION_LOADING',
      payload: false,
    });
  };
};

export const logOutAction = () => {
  logout();
  window.location = '/';
};
