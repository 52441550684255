import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import * as WaybillAction from 'store/actions/waybill';
import List from './list';
import AddDialog from './addDialog';
import FilterBox from './filter';
import { t } from 'i18next';

function AdditionalWaybill() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [selectedData, setSelectedData] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);
  const dispatch = useDispatch();
  const router = useHistory();
  const waybill_additional_status = useSelector((state) => state.waybill.waybill_additional_status);

  const headers = [
    {
      text: '',
      width: 40,
      className: 'fixed-cell left',
    },
    {
      text: 'customer_service.waybill_number',
    },
    {
      text: 'customer_service.content',
    },
    {
      text: 'customer_service.action_person',
    },
    {
      text: 'branch',
    },
    {
      text: 'created_at',
    },
  ];

  React.useEffect(() => {
    loadData();
  }, [WaybillAction.getAdditionalStatus, router.location.search]);

  const loadData = (value = null) => {
    setSelectedData([]);
    setSearchValue(value);
    let query = { limit: 10, page: 1 };
    if (router.location.search) query = queryString.parse(router.location.search);
    dispatch(WaybillAction.getAdditionalStatus(query, value ? value : searchValue ? searchValue : {}));
  };

  return (
    <>
      <List
        headers={headers}
        data={waybill_additional_status}
        onEdit={(data) => {
          setModalOpen(true);
          setEditData(data);
        }}
        filter={<FilterBox onSearch={(value) => loadData(value)} />}
        selectedData={selectedData}
        onSelect={(e) => {
          if (selectedData.find((s) => s._id === e._id)) setSelectedData(selectedData.filter((s) => s._id !== e._id));
          else setSelectedData([...selectedData, e]);
        }}
        buttons={
          <>
            <Button
              size='sm'
              onClick={() => {
                setModalOpen(true);
                setEditData(null);
              }}
              style={{ width: 100 }}
            >
              <i className='fa fa-plus mr-2' /> {t('add')}
            </Button>
            <Button style={{ width: 100 }} size='sm' onClick={() => loadData({}, true)}>
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
            <Button
              disabled={selectedData.length !== 1}
              size='sm'
              onClick={() => {
                setShowDelete(true);
              }}
              style={{ width: 100 }}
            >
              <i className='fa fa-trash mr-2' /> {t('delete')}
            </Button>
          </>
        }
      />
      <AddDialog
        title={editData ? `${t('edit')} ${t('country')}` : `${t('add')} ${t('country')}`}
        editData={editData}
        modalOpen={modalOpen}
        onToggle={(open) => setModalOpen(open)}
        onSubmit={(values) => {
          setModalOpen(false);

          dispatch(WaybillAction.saveAdditionalStatus(values));
          setTimeout(() => loadData({}, true), 1000);
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title='Delete Additional Waybill Status'
        body={`${t('confirm_delete')} ${selectedData[0]?.waybill_number}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(WaybillAction.deleteAdditionalStatus(selectedData[0]?._id));
          setTimeout(() => loadData({}), 1000);
        }}
      />
    </>
  );
}

export default AdditionalWaybill;
