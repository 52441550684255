import { getData, sendData, sendForBlobData } from './apiService';
import { NotificationManager } from 'react-notifications';
export async function getTranscationCount(waybillnumber) {
  try {
    let response = await getData(`transaction/get-transcation-count?waybillnumber=${waybillnumber}`);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getAll(query, value) {
  try {
    let response = await sendData(
      `waybill/get-waybill-for-purchase?&${new URLSearchParams(query).toString()}`,
      value,
      'POST',
      false
    );
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function pay(values) {
  try {
    let response = await sendData('transaction/make-purchase', values, 'POST');
    return response;
  } catch (e) {
    NotificationManager.error(e.response.data.message);
    return false;
  }
}

export async function exportData(data) {
  return await sendForBlobData('waybill/get-waybill-for-finance-export', data, {
    responseType: 'blob',
  });
}
