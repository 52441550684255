export function getHeaders() {
  const headers = [
    {
      text: '',
      width: 50,
      className: 'fixed-cell left',
    },
    {
      text: 'date',
    },
    {
      text: 'waybill_entry.waybill',
    },
    {
      text: 'waybill_entry.code',
    },
    {
      text: 'sidebar.custom_clearence',
    },

    {
      text: 'waybill_entry.qty',
    },
    {
      text: 'waybill_entry.weight',
    },
    {
      text: 'waybill_entry.volume',
    },
    {
      text: 'sign.receiver',
    },
    {
      text: 'cargo_loading.container',
    },
    {
      text: 'remark',
    },
  ];

  return headers;
}
