import { getData, sendData } from './apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';
export async function getRole() {
  try {
    let response = await getData('user-roles/get-all-refresh');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getUsers(values) {
  try {
    let response = await getData(`users/get-all-user-refresh?${new URLSearchParams(values)}`);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function updatePermission(data) {
  try {
    let response = await sendData('user-roles/update-permission', data);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function updateActionPermission(data) {
  try {
    let response = await sendData('user-roles/update-action-permission', data);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function updateViewPermissionAction(data) {
  try {
    let response = await sendData('user-roles/update-view-permission', data);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function createUser(data) {
  try {
    let response = await sendData('users/create', data);
    return response;
  } catch (e) {
    NotificationManager.error(e.response.data.errors[0]?.msg);
    return false;
  }
}

export async function deleteUser(_id) {
  try {
    let response = await sendData('users/delete-user', { id: _id });
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function updateUserRole(values) {
  try {
    let response = await sendData('users/updateRole', values);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function updateUserActiveStatus(values) {
  try {
    let response = await sendData('users/updateActiveStatus', values);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function createRole(values) {
  try {
    let response = await sendData('user-roles/create', values);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function updateRoleName(values) {
  try {
    let response = await sendData('user-roles/update', values);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function resetUserPassword(values) {
  try {
    let response = await sendData('users/reset-password-admin', values);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function updateUserWaybillPermission(permisions, id) {
  try {
    let response = await sendData('users/updateUserWaybillPermission', {
      permisions,
      id,
    });
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}
