import React from 'react';
import { Modal, ModalBody, Row, Col, Button, FormGroup, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import TextBox from 'components/Inputs/TextBox';
import SelectBox from 'components/SelectBox';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

export default function AddDialog({ modalOpen, onToggle, title, onSubmit, editData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();

  const submitData = async (values) => {
    reset();
    onSubmit(values);
  };

  const employee = useSelector((state) => state.information.employees);
  const employeeOptions = employee.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  const branches = useSelector((state) => state.information.branches);
  if (!branches.map) {
    window.location.reload();
  }
  const branchOptions = branches.map((e) => {
    return {
      label: e.label,
      value: e._id,
    };
  });

  const roles = useSelector((state) => state.role.roles);
  const roleOptions = roles.map((e) => {
    return {
      label: e.rolename,
      value: e._id,
    };
  });

  // eslint-disable-next-line no-unused-vars
  const [permission, setPermission] = React.useState([]);

  const onEmployeeSelect = (e) => {
    const selected = employee.find((emp) => emp._id === e);
    setValue('employee', e);
    setValue('name', selected?.name);
    setValue('phonenumber', selected?.phonenumber);
    setValue('branch', selected?.branch_id);
    setValue('address', selected?.address);
    setValue('role', '');
  };

  React.useEffect(() => {
    if (editData) {
      setValue('employee', editData?.employee);
      setValue('role', editData?.role._id);
      setValue('name', editData?.name);
      setValue('address', editData?.address);
      setValue('phonenumber', editData?.phonenumber);
      setValue('branch', editData?.branch?._id);
      setPermission(editData?.waybill_permission);
    } else {
      reset();
    }
  }, [editData]);

  return (
    <Modal isOpen={modalOpen} style={{ top: '25%' }} backdrop={true}>
      <div className=' modal-header'>
        <h5 className=' modal-title'>
          {title} {editData?.name}
        </h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitData)}>
          <Row>
            {!editData && (
              <Col xs={6}>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('system_user_management.employee')}</label>
                  <SelectBox
                    control={control}
                    name='employee'
                    options={employeeOptions}
                    value={editData?.employee._id}
                    rules={{ required: true }}
                    onValueChange={onEmployeeSelect}
                  />
                </FormGroup>
              </Col>
            )}
            <Col xs={6}>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('system_user_management.name')}</label>
                <TextBox
                  className='form-control'
                  placeholder={t('system_user_management.name')}
                  type='text'
                  registerProps={register('name')}
                  errors={errors.name}
                  disabled={editData ? false : true}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('system_user_management.phonenumber')}</label>
                <TextBox
                  className='form-control'
                  placeholder={t('system_user_management.phonenumber')}
                  type='text'
                  registerProps={register('phonenumber')}
                  errors={errors.phonenumber}
                  disabled={editData ? false : true}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('system_user_management.branch')}</label>
                <SelectBox control={control} name='branch' options={branchOptions} value={editData?.branch} />
              </FormGroup>
            </Col>

            <Col xs={6}>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('system_user_management.address')}</label>
                <TextBox
                  className='form-control'
                  placeholder={t('system_user_management.address')}
                  type='text'
                  registerProps={register('address')}
                  errors={errors.address}
                  disabled={editData ? false : true}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('system_user_management.permission')}</label>
                <SelectBox
                  control={control}
                  name='role'
                  options={roleOptions}
                  value={editData?.role}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
          </Row>

          <div className='text-center'>
            <Button className='my-4' color='success' type='button' onClick={() => onToggle(!modalOpen)}>
              {t('cancel')}
            </Button>
            <Button className='my-4' color='primary' type='submit'>
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
