import React from 'react';
import { Modal, ModalBody, Button, FormGroup, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import TextBox from 'components/Inputs/TextBox';
import { t } from 'i18next';

export default function AddDialog({ modalOpen, onToggle, title, onSubmit, editData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const submitData = async (values) => {
    reset();
    onSubmit(values);
  };

  React.useEffect(() => {
    if (editData) {
      setValue('name', editData?.name);
      setValue('code', editData?.code);
    } else {
      reset();
    }
  }, [editData]);

  return (
    <Modal isOpen={modalOpen} style={{ top: '25%' }} backdrop={true}>
      <div className=' modal-header'>
        <h5 className=' modal-title'>
          {title} {editData?.name}
        </h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitData)}>
          <FormGroup className={classnames('mb-3')}>
            <label>{t('name')}</label>
            <TextBox
              className='form-control'
              placeholder={t('name')}
              type='text'
              registerProps={register('name', {
                required: `${t('name')} ${t('is_required')}`,
              })}
              errors={errors.name}
              // defaultValue={editData?.name}
            />
          </FormGroup>
          <FormGroup className={classnames('mb-3')}>
            <label>{t('code')}</label>
            <TextBox
              className='form-control'
              placeholder={t('code')}
              type='text'
              registerProps={register('code', {
                required: `${t('code')} ${t('is_required')}`,
              })}
              errors={errors.code}
              //  defaultValue={editData?.code}
            />
          </FormGroup>

          <div className='text-center'>
            <Button
              className='my-4'
              color='success'
              type='button'
              onClick={() => onToggle(!modalOpen)}
              //   disabled={loading}
            >
              {/* {loading && (
                <Spinner size="sm" role="status" className="mr-2"></Spinner>
              )} */}
              {t('cancel')}
            </Button>
            <Button
              className='my-4'
              color='primary'
              type='submit'
              // disabled={loading}
            >
              {/* {loading && (
                <Spinner size="sm" role="status" className="mr-2"></Spinner>
              )} */}
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
