import * as EmployeeService from 'services/employee';
import { SET_EMPLOYEES, SET_MARKETING_PERSONS } from 'store/types/information';
export const getAll = (query) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await EmployeeService.getAll(query);
    dispatch({
      type: SET_EMPLOYEES,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const getMarketingPerson = () => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await EmployeeService.getMarketingPerson();
    dispatch({
      type: SET_MARKETING_PERSONS,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const save = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await EmployeeService.save(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const update = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await EmployeeService.update(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteData = (id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await EmployeeService.deleteData(id);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};
