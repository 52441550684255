export const SET_INVENTORY = 'SET_INVENTORY';
export const SET_WAYS = 'SET_WAYS';
export const SET_WAY_OPTIONS = 'SET_WAY_OPTIONS';
export const SET_WAYBILL_OPTIONS = 'SET_WAYBILL_OPTIONS';
export const SET_WAY_CARGOS = 'SET_WAY_CARGOS';
export const SET_LOADED_WAYBILLS = 'SET_LOADED_WAYBILLS';
export const SET_CONFIRMED_WAYBILLS = 'SET_CONFIRMED_WAYBILLS';
export const SET_UNLOADED_WAYBILLS = 'SET_UNLOADED_WAYBILLS';
export const SET_DELIVERED_WAYBILLS = 'SET_DELIVERED_WAYBILLS';
export const SET_END_WAYS = 'SET_END_WAYS';
