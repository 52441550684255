import { getData, sendData, sendForBlobData } from './apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';
export async function getAll(query, value) {
  try {
    let response = await sendData(
      `order/search?total=true&${new URLSearchParams(query).toString()}`,
      value,
      'POST',
      false
    );
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function activate(id) {
  try {
    return await sendData(`order/activate/${id}`, {}, 'PUT');
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}

export async function save(values) {
  try {
    return await sendData('order/create', values);
  } catch (e) {
    NotificationManager.error(e.response.data.message);
    return false;
  }
}

export async function uploadFile(files, waybillnumber) {
  try {
    return await sendData(`order-files/all-images-order/${waybillnumber}`, files, 'POST', false);
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}

export async function deleteData(_id) {
  try {
    let response = await sendData(`order/${_id}`, null, 'DELETE');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function deleteAllData(data) {
  try {
    let response = await sendData('order', data, 'DELETE');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function update(values) {
  try {
    return await sendData('address-book/update/' + values._id, values, 'PUT');
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}

export async function fetchWaybillNumber() {
  try {
    let response = await getData('order/request-waybill-number');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function deleteImage(file, waybillnumber, type) {
  try {
    return await sendData(
      `order-files/order-image-delete-custom?file_name=${file}&waybill_id=${waybillnumber}&type=${type}`,
      {},
      'DELETE'
    );
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}

export async function exportData(data) {
  return await sendForBlobData('order/export-order', data, {
    responseType: 'blob',
  });
}
