export const headers = [
  {
    text: 'no',
    width: 50,
    className: 'fixed-cell left',
  },
  {
    text: 'code',
    className: 'fixed-cell left',
  },
  {
    text: 'address_book.customer_type',
  },
  {
    text: 'address_book.sender',
  },
  {
    text: 'phonenumber',
  },
  {
    text: 'address_book.company',
  },
  // {
  //   text: 'region',
  // },
  // {
  //   text: 'township',
  // },
  {
    text: 'address_book.receiver',
  },
  {
    text: 'phonenumber',
  },
  {
    text: 'address_book.company',
  },
  // {
  //   text: 'region',
  // },
  // {
  //   text: 'township',
  // },
  {
    text: 'address_book.sale_person',
  },
  {
    text: 'waybill_entry.destination',
  },
  {
    text: 'actions',
    width: 80,
    className: 'fixed-cell',
  },
];
