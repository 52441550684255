import React from 'react';
import DatePicker from './DatePicker';
import dayjs from 'dayjs';
import { t } from 'i18next';

function DateRangePicker({ onDateChange }) {
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();

  const onStartDateChange = (date) => {
    setStartDate(date);
    onDateChange([date, endDate]);
  };

  const onEndDateChange = (date) => {
    setEndDate(date);
    onDateChange([startDate, date]);
  };

  return (
    <div className='d-flex' style={{ height: 35 }}>
      <DatePicker
        placeholder={t('start_date')}
        onChange={(date) => onStartDateChange(new Date(date))}
        value={startDate ? dayjs(startDate).format('DD/MM/YYYY') : ''}
      />
      <DatePicker
        placeholder={t('end_date')}
        onChange={(date) => onEndDateChange(new Date(date))}
        value={endDate ? dayjs(endDate).format('DD/MM/YYYY') : ''}
      />
    </div>
  );
}

export default DateRangePicker;
