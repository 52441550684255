import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Select from 'react-select';
import clsx from 'clsx';
import './table.scss';
import {
  CardBody,
  Card,
  Table as ReactStrapTable,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import { t } from 'i18next';
export default function Table({
  headers,
  rows,
  // title,
  buttons,
  filter,
  data,
  onPageChange,
  grid = false,
  showTotal = true,
  showTitle = true,
  footer,
  extraContent,
  tableHeight,
  // subtitle = 'List',
}) {
  const loading = useSelector((state) => state.loading.loading);
  const [activePage, setActivePage] = React.useState(1);
  const [rowPerPage, setRowPerPage] = React.useState(10);
  const router = useHistory();

  React.useEffect(() => {
    if (data?.page)
      if (!onPageChange) {
        router.push(`${router.location.pathname}?page=${activePage}&limit=${rowPerPage}`);
      } else {
        onPageChange(activePage, rowPerPage);
      }
  }, [activePage, rowPerPage]);

  const isEmptyData = (data) => {
    if (data?.docs) return data.docs.length === 0;
    return data?.length === 0;
  };

  return (
    <>
      {showTitle && (
        <div className={clsx('pb-2', grid && 'grid-background pt-2 pl-2')}>
          <div className='mt-2 mb-2 button-container'>{buttons}</div>
        </div>
      )}
      {extraContent}
      <Card>
        <CardBody>
          <div className='pb-4'>{filter}</div>

          <div className='table-container' style={{ maxHeight: tableHeight || '65vh' }}>
            <ReactStrapTable className='app-table'>
              <thead>
                <tr
                  style={{
                    border: '1px solid #e9e9ef',
                    position: 'sticky',
                    top: -5,
                    zIndex: 1,
                  }}
                >
                  {headers}
                </tr>
              </thead>

              {!loading && !isEmptyData(data) && (
                <tbody>
                  {rows}
                  <tr
                    style={{
                      border: '1px solid #e9e9ef',
                      position: 'sticky',
                      bottom: 5,
                      zIndex: 1,
                      background: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    {footer}
                  </tr>
                </tbody>
              )}
            </ReactStrapTable>
          </div>
          {loading && (
            <div style={{ width: '100%' }}>
              <SkeletonTheme height={40}>
                <Skeleton count={5} className='d-flex' />
              </SkeletonTheme>
            </div>
          )}
          {!loading && isEmptyData(data) && (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                height: 300,
                padding: '50px 0px',
              }}
            >
              <h3>No Data</h3>
            </div>
          )}
        </CardBody>
        <CardFooter>
          {data?.length && !loading && showTotal && (
            <div className='d-flex justify-content-end'>
              <p>
                {t('totalCount')} : {data.length ? data.length : 0}
              </p>
            </div>
          )}
          {data?.totalPages > 0 && (
            <div className='d-flex justify-content-end'>
              <div className='d-flex align-items-center mr-5'>
                <span className='mr-3'>{t('row_per_view')} : </span>
                <Select
                  defaultValue={{ value: '10', label: '10' }}
                  onChange={(val) => {
                    setActivePage(1);
                    setRowPerPage(val.value);
                  }}
                  options={[
                    { value: '5', label: '5' },
                    { value: '10', label: '10' },
                    { value: '25', label: '25' },
                    { value: '50', label: '50' },
                    { value: '100', label: '100' },
                    { value: '200', label: '200' },
                  ]}
                />
                <span className='ml-2'>
                  {data?.pagingCounter}-{rowPerPage * data?.page} of {data?.totalDocs}
                </span>
              </div>

              {data?.totalPages > 1 && (
                <div className='pagination-container'>
                  <Pagination>
                    {data?.hasPrevPage && (
                      <PaginationItem>
                        <PaginationLink previous onClick={() => setActivePage(data?.page - 1)} />
                      </PaginationItem>
                    )}
                    {[...Array(data?.totalPages)].map((x, i) => (
                      <PaginationItem active={i + 1 === data?.page} key={`pagination-${i}`}>
                        <PaginationLink onClick={() => setActivePage(i + 1)} key={`paginiation-item-${x}-${i}`}>
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    {data?.hasNextPage && (
                      <PaginationItem>
                        <PaginationLink next onClick={() => setActivePage(data?.page + 1)} />
                      </PaginationItem>
                    )}
                  </Pagination>
                </div>
              )}
            </div>
          )}
        </CardFooter>
      </Card>
    </>
  );
}
