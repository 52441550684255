import React from 'react';
import { Row, Col, Button } from 'reactstrap';

import Select from 'react-select';
import TextBox from 'components/Inputs/TextBox';
import { t } from 'i18next';

function FilterBox({ onSearch }) {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };

  const [searchType, setSearchType] = React.useState('waybill_number');
  const [search, setSearch] = React.useState();

  return (
    <>
      <Row>
        <Col md='5' xs='5'>
          {['waybill_number'].includes(searchType) && (
            <TextBox
              onChange={(e) => setSearch(e.target.value)}
              style={{ height: 34 }}
              value={typeof search === 'object' ? '' : search}
              placeholder={`${searchType}`}
            />
          )}
        </Col>
        <Col md='5' xs='5'>
          <Select
            styles={customStyles}
            defaultValue={{
              value: 'waybill_number',
              label: t('customer_service.waybill_number'),
            }}
            onChange={(val) => {
              setSearch('');
              setSearchType(val.value);
            }}
            options={[
              {
                value: 'waybill_number',
                label: t('customer_service.waybill_number'),
              },
            ]}
          />
        </Col>

        {searchType && (
          <Button
            size='sm'
            color='success'
            style={{ height: 35 }}
            onClick={() => {
              if (search) {
                //setSearch('')
                const searchData = {};
                if (search) searchData[searchType] = search;
                onSearch(searchData);
              }
            }}
          >
            <i className='fa fa-search' />
          </Button>
        )}
      </Row>
    </>
  );
}

export default FilterBox;
