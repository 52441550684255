import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import { Row, Col } from 'reactstrap';
import * as BranchAction from 'store/actions/branch';
import { getCountryOption } from 'store/actions/location';
import EntryForm from './entryForm';
import List from './list';
import { t } from 'i18next';

function Branch() {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.location.countries);
  const [disabled, setDisbled] = React.useState(true);
  const [addOpen, setAddOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);
  const [placeholderBranch, setPlaceholderBranch] = React.useState('');

  React.useEffect(() => {
    dispatch(BranchAction.getAll());
    dispatch(getCountryOption());
  }, [dispatch, BranchAction.getAll, getCountryOption]);

  return (
    <Row>
      <Col sm='5' md='4' lg='3'>
        <List
          onRefresh={() => dispatch(BranchAction.getAll())}
          onAddOpen={() => {
            setEditData(null);
            setAddOpen(true);
            setDisbled(false);
          }}
          onBranchClick={(branch) => {
            setAddOpen(false);
            setEditData(branch);
            setDisbled(true);
            setPlaceholderBranch(branch);
          }}
          onEditOpen={() => {
            if (editData) setDisbled(false);
          }}
          onAddSubBranch={(branch) => {
            setDisbled(false);
            setEditData(null);
            setPlaceholderBranch(branch);
          }}
        />
      </Col>
      <Col md='6' xs='9'>
        <EntryForm
          disabled={disabled}
          addOpen={addOpen}
          placeholderBranch={placeholderBranch}
          editData={editData}
          options={{ countries: countries }}
          onCancel={() => setDisbled(true)}
          onSubmit={(values) => {
            if (editData) dispatch(BranchAction.update({ ...values, _id: editData?._id }));
            else {
              if (placeholderBranch) {
                values.parentBranch = placeholderBranch._id;
              }

              dispatch(BranchAction.save(values));
            }
            setTimeout(() => dispatch(BranchAction.getAll({}, true)), 1000);
          }}
          onDelete={() => {
            setShowDelete(true);
          }}
        />
      </Col>
      <ConfirmDialog
        modalOpen={showDelete}
        title={`${t('delete')} ${t('branch')}`}
        body={`${t('confirm_delete')} ${editData?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(BranchAction.deleteData(editData?._id));
          setTimeout(() => dispatch(BranchAction.getAll()), 1000);
          setAddOpen(true);
        }}
      />
    </Row>
  );
}

export default Branch;
