import React from 'react';
import { Button } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import PrintWayBillLabelComponent from './print_waybill_label_component';
import { t } from 'i18next';
function WaybillLabelExport({ selectedData }) {
  const labelRef = React.useRef();
  // @page {
  //   size: 100mm 75mm!important;
  //   margin: 0px!important;
  //   padding: 0px!important;

  // }

  return (
    <>
      <ReactToPrint
        trigger={() => {
          return (
            <Button style={{ width: 100 }} size='sm' disabled={selectedData.length !== 1}>
              <i className='fa fa-file-excel mr-2' />
              {t('waybill_entry.label')}
            </Button>
          );
        }}
        content={() => {
          return labelRef.current;
        }}
      />

      <div style={{ display: 'none' }}>
        <PrintWayBillLabelComponent ref={labelRef} data={selectedData[0]} />
      </div>
    </>
  );
}

export default WaybillLabelExport;
