import { combineReducers } from 'redux';
import user from './user';
import loading from './loading';
import location from './location';
import util from './util';
import role from './role';
import information from './information';
import other_maintenance from './other_maintenance';
import waybill from './waybill';
import cargo from './cargo';
const reducers = combineReducers({
  user,
  loading,
  location,
  util,
  role,
  information,
  other_maintenance,
  waybill,
  cargo,
});
export default reducers;
