/* eslint-disable no-undef */
import React from 'react';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Button, Form, FormGroup, InputGroup, Spinner } from 'reactstrap';
import './login.scss';
import { connect } from 'react-redux';
import { loginAction } from 'store/actions';
import TextBox from 'components/Inputs/TextBox';
import CheckBox from 'components/Inputs/CheckBox';

const Login = ({ loginAction }) => {
  const {
    register,
    // handleSubmit,
    // getValues,
    formState: { errors },
  } = useForm();

  const [phonenumber, setPhoneNumber] = React.useState(false);
  const [password, setPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [showPassword, setShowPassowrd] = React.useState(false);

  const submitData = async () => {
    if (!phonenumber || !password) {
      setError('Some fields are required');
      return;
    }
    setLoading(true);
    await loginAction({
      phonenumber: phonenumber,
      password: password,
    });
    setLoading(false);
    setError(null);
  };

  return (
    <Container
      fluid
      className='main-bg'
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Form autocomplete='off'>
        <Container
          className='content-card'
          fluid
          style={{
            background: '#fff',
            borderRadius: 20,
            paddingLeft: 0,
          }}
        >
          <div className='d-flex'>
            <div style={{ flex: 1 }}>
              <img
                alt='...'
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '20px 0px 0px 20px',
                }}
                className='left-img'
                src={require('assets/img/brand/easyf-login-pic.jpg').default}
              />
            </div>

            <div style={{ flex: 1 }}>
              <div style={{ padding: '20px 120px' }}>
                <div className='d-flex justify-content-center'>
                  <img
                    alt='...'
                    width='220'
                    height='80'
                    src={require('assets/img/brand/company_logo-removebg.png').default}
                    style={{
                      marginTop: 20,
                      marginBottom: 60,
                      borderRadius: '20px 0px 0px 20px',
                    }}
                  />
                </div>
                {error && <p className='text-danger'>{error}</p>}
                <FormGroup>
                  <label htmlFor='phone'>Phone Number</label>
                  <TextBox
                    className='form-control'
                    placeholder='Phone'
                    type='text'
                    id='phone'
                    prepend
                    autoComplete='off'
                    icon={<i className='fa fa-phone' />}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <InputGroup className='input-group-merge input-group-alternative'>
                    <label htmlFor='password'>Password</label>
                    <TextBox
                      id='password'
                      placeholder='Password'
                      type={showPassword ? 'text' : 'password'}
                      registerProps={register('password', {
                        required: 'Password is required',
                      })}
                      autoComplete='new-password'
                      prepend
                      icon={<i className='ni ni-lock-circle-open' />}
                      errors={errors.password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <Row className='mt-2'>
                  <Col>
                    <FormGroup>
                      <CheckBox
                        label='Show Password'
                        onChange={() => {
                          setShowPassowrd(!showPassword);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className='d-flex justify-content-center'>
                  <Button
                    className='mt-1 mb-3 btn-primary'
                    type='button'
                    style={{
                      width: '150px',
                    }}
                    onClick={submitData}
                    disabled={loading}
                  >
                    {loading && <Spinner size='sm' role='status' className='mr-2'></Spinner>}
                    Sign in
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Form>
    </Container>
  );
};

export default connect(null, { loginAction })(Login);
