import React from 'react';
import { Modal, ModalBody, Row, Col, Button, FormGroup, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import TextBox from 'components/Inputs/TextBox';
import SelectBox from 'components/SelectBox';
import { t } from 'i18next';

export default function AddDialog({ modalOpen, onToggle, title, onSubmit, editData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,

    control,
  } = useForm();

  const sizeOptions = [
    { label: '20', value: 20 },
    { label: '40', value: 40 },
  ];

  const submitData = async (values) => {
    reset();
    onSubmit(values);
  };

  return (
    <Modal isOpen={modalOpen} style={{ top: '25%' }} backdrop={true}>
      <div className=' modal-header'>
        <h5 className=' modal-title'>
          {title} {editData?.name}
        </h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitData)}>
          <Row>
            <Col md='6'>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('information_container.container_number')}</label>
                <TextBox
                  className='form-control'
                  placeholder={t('information_container.container_number')}
                  type='text'
                  registerProps={register('number', {
                    required: `${t('information_container.container_number')} ${t('is_required')}`,
                  })}
                  errors={errors.number}
                  defaultValue={editData?.number}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('information_container.container_size')}</label>
                <SelectBox
                  control={control}
                  name={t('information_container.container_size')}
                  options={sizeOptions}
                  value={editData?.size}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('information_container.driver_name')}</label>
                <TextBox
                  className='form-control'
                  placeholder={t('information_container.driver_name')}
                  type='text'
                  registerProps={register('drivername', {
                    required: `${t('information_container.driver_name')} ${t('is_required')}`,
                  })}
                  errors={errors.drivername}
                  defaultValue={editData?.drivername}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('information_container.phone_number')}</label>
                <TextBox
                  className='form-control'
                  placeholder={t('information_container.phone_number')}
                  type='text'
                  registerProps={register('phonenumber', {
                    required: `${t('information_container.phone_number')} ${t('is_required')}`,
                  })}
                  errors={errors.phonenumber}
                  defaultValue={editData?.phonenumber}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('information_container.remark')}</label>
                <TextBox
                  className='form-control'
                  placeholder={t('information_container.phone_number')}
                  type='text'
                  registerProps={register('remark')}
                  errors={errors.remark}
                  defaultValue={editData?.remark}
                />
              </FormGroup>
            </Col>
          </Row>

          <div className='text-center'>
            <Button className='my-4' color='success' type='button' onClick={() => onToggle(!modalOpen)}>
              {t('cancel')}
            </Button>
            <Button className='my-4' color='primary' type='submit'>
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
