import React from 'react';
import { Modal, ModalBody, Button, Form, FormGroup } from 'reactstrap';
import { useForm } from 'react-hook-form';
import TextBox from 'components/Inputs/TextBox';
import DatePicker from 'components/Inputs/DatePicker';
import dayjs from 'dayjs';
import './sign-modal.scss';
import { t } from 'i18next';

export default function SubLoadDialog({ modalOpen, onToggle, onSubmit, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [file, setFile] = React.useState(null);

  const submitData = async (values) => {
    reset();
    let formData = new FormData();
    formData.append('cargo_id', data.waybill_number);
    formData.append('name', values.name);
    formData.append('phonenumber', values.phonenumber);
    formData.append('date', values.date || dayjs(new Date()).format('YYYY-MM-DD'));
    formData.append('remark', values.remark);
    if (file) {
      formData.append('fileupload', file);
    }
    onSubmit(formData);
  };

  return (
    <Modal isOpen={modalOpen} style={{ top: '5%', maxWidth: '40%', minWidth: 450 }} backdrop={true}>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitData)}>
          <div className='pt-1 pb-1 mb-5'>
            <h2>
              {t('sign.sign_for_waybill')} : [ {data?.waybill_number} ]
            </h2>
          </div>
          <FormGroup>
            <label>{t('date')}</label>
            <DatePicker
              onChange={(date) => {
                setValue('date', date);
              }}
              initialValue={new Date()}
            />
          </FormGroup>
          <FormGroup>
            <label>{t('address_book.receiver')}</label>
            <TextBox
              className='form-control'
              placeholder={t('address_book.receiver')}
              type='text'
              registerProps={register('name', {
                required: `${t('address_book.receiver')} ${t('is_required')}`,
              })}
              errors={errors.name}
            />
          </FormGroup>
          <FormGroup>
            <label>{t('phonenumber')}</label>
            <TextBox
              className='form-control'
              placeholder={t('phonenumber')}
              type='text'
              registerProps={register('phonenumber', {
                required: `${t('phonenumber')} ${t('is_required')}`,
              })}
              errors={errors.phonenumber}
            />
          </FormGroup>
          <FormGroup>
            <label>{t('remark')}</label>
            <TextBox
              className='form-control'
              placeholder={t('remark')}
              type='text'
              registerProps={register('remark')}
              errors={errors.remark}
            />
          </FormGroup>
          <FormGroup>
            <label>{t('sign.attachment')}</label>
            <br />
            <input
              type='file'
              onChange={(e) => {
                if (e.target.files[0].type) setFile(e.target.files[0]);
              }}
              accept='.png, .jpg, .jpeg'
              multiple
              name='attachment'
            />
          </FormGroup>
          <div>
            {file && (
              <div className='preview-image'>
                <img src={URL.createObjectURL(file)} />
                <span onClick={() => setFile(null)}>X</span>
              </div>
            )}
          </div>
          <div className='text-center'>
            <Button className='my-4' color='success' type='button' onClick={() => onToggle(!modalOpen)}>
              {t('cancel')}
            </Button>
            <Button className='my-4' color='primary' type='submit'>
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
