import { SET_ROLE, SET_USERS } from 'store/types/role';

const initialState = {
  roles: [],
  users: [],
};

const member = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROLE:
      return {
        ...state,
        roles: action.payload,
      };
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };

    default:
      return state;
  }
};

export default member;
