import React from 'react';
import { Modal, ModalBody, Button, FormGroup, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import TextBox from 'components/Inputs/TextBox';
import { t } from 'i18next';

export default function AddDialog({ modalOpen, onToggle, title, onSubmit, editData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const submitData = async (values) => {
    reset();
    onSubmit(values);
  };

  React.useEffect(() => {
    if (editData) {
      setValue('name', editData?.name);
      setValue('description', editData?.description);
    } else {
      reset();
    }
  }, [editData]);

  return (
    <Modal isOpen={modalOpen} style={{ top: '25%' }} backdrop={true}>
      <div className=' modal-header'>
        <h5 className=' modal-title'>
          {title} {editData?.name}
        </h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitData)}>
          <FormGroup className={classnames('mb-3')}>
            <label>{t('name')}</label>
            <TextBox
              className='form-control'
              placeholder={t('name')}
              type='text'
              registerProps={register('name', {
                required: `${t('name')} ${t('is_required')}`,
              })}
              errors={errors.name}
              defaultValue={editData?.name}
            />
          </FormGroup>
          <FormGroup className={classnames('mb-3')}>
            <label>{t('description')}</label>
            <TextBox
              className='form-control'
              placeholder={t('description')}
              type='text'
              registerProps={register('description', {
                required: `${t('description')} ${t('is_required')}`,
              })}
              errors={errors.remark}
              defaultValue={editData?.description}
            />
          </FormGroup>
          <div className='text-center'>
            <Button className='my-4' color='success' type='button' onClick={() => onToggle(!modalOpen)}>
              {t('cancel')}
            </Button>
            <Button className='my-4' color='primary' type='submit'>
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
