import React from 'react';
import { FormGroup, Row, Col } from 'reactstrap';
import TextBox from 'components/Inputs/TextBox';
import { t } from 'i18next';
function CustomerInfoForm({
  errors,
  type,
  register,

  addNew,
  editData,
  selectedAddress,
  resetButton,
}) {
  return (
    <Row>
      <Col md={6} xs={12}>
        <FormGroup>
          <label>{t('name')}</label>
          <TextBox
            className='form-control'
            placeholder={t('name')}
            type='text'
            registerProps={register(`${type}.name`, {
              required: `${t('name')} ${t('is_required')}`,
            })}
            errors={errors[type]?.name}
            disabled={!addNew && !selectedAddress && !editData}
          />
        </FormGroup>
      </Col>
      <Col md={6} xs={12}>
        <label>{t('company')}</label>
        <TextBox
          className='form-control'
          placeholder={t('company')}
          type='text'
          registerProps={register(`${type}.company`, {
            required: `${t('company')} ${t('is_required')}`,
          })}
          errors={errors[type]?.company}
          disabled={!addNew && !selectedAddress && !editData}
        />
      </Col>
      <Col md={6} xs={12}>
        <FormGroup>
          <label>{t('phonenumber')}</label>
          <TextBox
            className='form-control'
            placeholder={t('phonenumber')}
            type='text'
            registerProps={register(`${type}.phone`, {
              required: `${t('phonenumber')} ${t('is_required')}`,
            })}
            errors={errors[type]?.phone}
            disabled={!addNew && !selectedAddress && !editData}
          />
        </FormGroup>
      </Col>
      {/* <Col md={6} xs={12}>
        <FormGroup>
          <label>{t('country')}</label>
          <SelectBox
            control={control}
            name={`${type}.country`}
            value={getValues(`${type}.country`)}
            label={t('country')}
            options={countryOptions}
            rules={{ required: true }}
            disabled={!addNew && !selectedAddress && !editData}
            onValueChange={(value) => {
              if (!value) setRegionOption([])
              else {
                const result = options.countries?.find((c) => c._id === value)
                setRegionOption(result?.region || [])
              }
            }}
          />
        </FormGroup>
      </Col>
      <Col md={6} xs={12}>
        <FormGroup>
          <label>{t('region')}</label>
          <SelectBox
            control={control}
            name={`${type}.region`}
            value={getValues(`${type}.region`)}
            label={t('region')}
            options={regionOptions}
            rules={{ required: true }}
            disabled={!addNew && !selectedAddress && !editData}
            onValueChange={async (value) => {
              if (!value) setDistrictOption([])
              else {
                const response = await getDistriceOption(value)

                setDistrictOption(response)
              }
            }}
          />
        </FormGroup>
      </Col>
      <Col md={6} xs={12}>
        <FormGroup>
          <label>{t('district')}</label>
          <SelectBox
            control={control}
            name={`${type}.district`}
            value={getValues(`${type}.district`)}
            label={t('district')}
            options={districtOptions}
            rules={{ required: true }}
            disabled={!addNew && !selectedAddress && !editData}
            onValueChange={(value) => {
              if (!value) setTownshipOption([])
              else {
                const result = districts?.find((c) => c._id === value)
                setTownshipOption(result?.township || [])
              }
            }}
          />
        </FormGroup>
      </Col>
      <Col md={6} xs={12}>
        <FormGroup>
          <label>{t('township')}</label>
          <SelectBox
            control={control}
            name={`${type}.township`}
            value={getValues(`${type}.township`)}
            label={t('township')}
            disabled={!addNew && !selectedAddress && !editData}
            options={townshipOptions}
            rules={{ required: true }}
          />
        </FormGroup>
      </Col> */}
      <Col md={6} xs={12}>
        <FormGroup>
          <label>{t('address')}</label>
          <TextBox
            className='form-control'
            placeholder={t('address')}
            disabled={!addNew && !selectedAddress && !editData}
            type='textarea'
            registerProps={register(`${type}.address`, {
              required: `${t('address')} ${t('is_required')}`,
              minLength: 5,
            })}
            errors={errors[type]?.address}
            customErrorMessage={`${t('address')} ${t('is_invalid')}`}
          />
        </FormGroup>
      </Col>
      <Col md={12} className='d-flex justify-content-end'>
        {selectedAddress && resetButton}
      </Col>
    </Row>
  );
}
export default CustomerInfoForm;
