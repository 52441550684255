import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { headers } from './headers';
import FilterBox from './filter';
import List from './list';
import { useDispatch, useSelector } from 'react-redux';
import * as AddressBookAction from 'store/actions/addressBook';
import * as EmployeeAction from 'store/actions/employee';
import { t } from 'i18next';
import useViewPermission from 'hooks/useViewPermission';

function SelectModal({ modalOpen, onSelect, onToggle }) {
  const dispatch = useDispatch();
  const addressBooks = useSelector((state) => state.information.addressBooks);
  const [searchValue, setSearchValue] = React.useState(null);
  const [query, setQuery] = React.useState({});
  const { isOwn, isBranch } = useViewPermission('address_book');

  React.useEffect(() => {
    dispatch(EmployeeAction.getMarketingPerson());
  }, [EmployeeAction.getMarketingPerson, dispatch]);

  React.useEffect(() => {
    loadData();
  }, [AddressBookAction.getAll, query]);

  const loadData = (value = null) => {
    setSearchValue(value);
    let queryParam = { limit: 10, page: 1 };

    if (isOwn()) query.isOwn = !!isOwn();
    if (isBranch()) query.isBranch = !!isBranch();

    if (query) {
      queryParam = query;
    }

    dispatch(AddressBookAction.getAll(queryParam, value ? value : searchValue ? searchValue : {}));
  };

  return (
    <Modal isOpen={modalOpen} size='lg' style={{ maxWidth: '1300px', width: '60%' }} backdrop={true}>
      <div className=' modal-header' style={{ padding: 0, paddingTop: 2, paddingLeft: 10 }}>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody style={{ height: '80vh' }}>
        <List
          headers={headers}
          data={addressBooks}
          title={`${t('choose')} ${t('customer')}`}
          filter={<FilterBox onSearch={(value) => loadData(value)} />}
          onPageChange={(activePage, rowPerPage) => {
            setQuery({ limit: rowPerPage, page: activePage });
          }}
          tableHeight='35vh'
          buttons={
            <>
              <Button size='sm' onClick={() => loadData({})}>
                {t('refresh')}
              </Button>
            </>
          }
          onSelect={onSelect}
        />
      </ModalBody>
    </Modal>
  );
}
export default SelectModal;
