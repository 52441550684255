import React from 'react';
import WordImg from 'assets/img/images/word_img.png';
import PdfImg from 'assets/img/images/pdf_img.png';
import XclImg from 'assets/img/images/excel_img.png';
import './index.scss';
function FileThumbnail({ e, setPreviewImgUrl, onDelete }) {
  const renderItem = (item) => {
    const ext = item.name.split('.')[1];
    if (ext === 'pdf') {
      return <img src={PdfImg} alt={item.name} />;
    } else if (ext === 'docx' || ext === 'doc') {
      return <img src={WordImg} alt={item.name} />;
    } else if (ext === 'xlsx' || ext === 'csv' || ext === 'xlsm' || ext === 'xls') {
      return <img src={XclImg} alt={item.name} />;
    } else {
      return <img src={URL.createObjectURL(item)} alt={item.name} />;
    }
  };

  const previewFile = (file) => {
    if (
      file.name.split('.')[1] === 'docx' ||
      file.name.split('.')[1] === 'doc' ||
      file.name.split('.')[1] === 'csv' ||
      file.name.split('.')[1] === 'xlsm' ||
      file.name.split('.')[1] === 'xls' ||
      file.name.split('.')[1] === 'xlsx'
    ) {
      window.location.href = URL.createObjectURL(file);
    } else {
      setPreviewImgUrl({
        name: file.name,
        url: URL.createObjectURL(file),
      });
    }
  };

  return (
    <div className='file-thumbnail'>
      <div onClick={() => previewFile(e)}>{renderItem(e)}</div>
      <span onClick={onDelete}>x</span>
    </div>
  );
}

export default FileThumbnail;
