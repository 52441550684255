import { SET_EMPLOYEES, SET_BRANCHS } from 'store/types/information';
import * as InformationService from 'services/information';
export const getEmployee = () => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await InformationService.getEmployee();
    dispatch({
      type: SET_EMPLOYEES,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const getBranch = () => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await InformationService.getBranch();
    dispatch({
      type: SET_BRANCHS,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};
