import React from 'react';
import Table from 'components/Table';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { isExpired } from 'utli';
import CheckBox from 'components/Inputs/CheckBox';
import { t } from 'i18next';
function List({
  headers,
  selectedData = [],
  data,
  onSelect,

  buttons,
  filter,
  showDetail,
  onPageChange,
}) {
  return (
    <div className='address-table'>
      <Table
        data={data}
        title={t('sidebar.sign')}
        subtitle={t('sidebar.sign_query')}
        filter={filter}
        onPageChange={onPageChange}
        headers={headers.map((e) => (
          <th
            className={clsx('headers', e.className)}
            key={`thead-${Math.random()}`}
            style={{ width: e.width, left: e.left }}
          >
            {t(e.text)}
          </th>
        ))}
        buttons={buttons}
        rows={data?.docs?.map((e, index) => (
          <tr key={`table-row-${index}-${Math.random()}`} className={clsx(isExpired(e.expire_date) && 'deactive')}>
            <td style={{ width: 40 }} className='fixed-cell left'>
              <CheckBox
                padding='mr-0'
                onChange={() => onSelect(e)}
                checked={selectedData.find((s) => s._id === e._id)}
              />
            </td>
            <td className='fixed-cell left'>{dayjs(e.date).format('DD/MM/YYYY')}</td>
            <td style={{ left: 55 }} className='fixed-cell left' onClick={() => showDetail(e)}>
              <span style={{ cursor: 'pointer' }}>{e.waybill_number}</span>
            </td>
            <td>{e.waybill?.address_code}</td>
            <td>{e.waybill?.custom_clearence?.name}</td>
            <td>{e.qty}</td>
            <td>{e.weight}</td>
            <td>{e.volume}</td>
            <td>{e.receiver_name}</td>
            <td>{e.container}</td>
            <td>{e.remark}</td>
          </tr>
        ))}
      />
    </div>
  );
}

export default List;
