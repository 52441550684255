/* eslint-disable no-unused-vars */
import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import TextBox from 'components/Inputs/TextBox';
import DateRangePicker from 'components/Inputs/DateRangePicker';
import { t } from 'i18next';
import dayjs from 'dayjs';
function FilterBox({ onSearch }) {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };

  const [searchType, setSearchType] = React.useState('waybillnumber');
  const [search, setSearch] = React.useState();
  const [purchase_date, setPurchaseDate] = React.useState();
  const [signDate, setSignDate] = React.useState();
  const [dateSearch, setDateSearch] = React.useState();

  const collections = useSelector((state) => state.other_maintenance.collections);
  const collectionTypesOptions = collections?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  const payment_types = useSelector((state) => state.other_maintenance.payment_types);
  const paymentOptions = payment_types?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  const marketing_persons = useSelector((state) => state.information.marketing_persons);
  const marketingPersonOptions = marketing_persons?.map((e) => {
    return {
      label: e.label,
      value: e._id,
    };
  });

  return (
    <>
      <Row>
        <Col className='mb-1' md='5' sm='6'>
          {searchType === 'collection_type' && (
            <Select
              options={collectionTypesOptions}
              label={t('finance.collection_type')}
              onChange={(value) => setSearch(value.value)}
              value={collectionTypesOptions.find((b) => b._id === search)}
            />
          )}
          {searchType === 'payment_type' && (
            <Select
              options={paymentOptions}
              label={t('finance.collection')}
              onChange={(value) => setSearch(value.value)}
              value={paymentOptions.find((b) => b._id === search)}
            />
          )}

          {['customer_code', 'waybillnumber'].includes(searchType) && (
            <TextBox
              onChange={(e) => setSearch(e.target.value)}
              style={{ height: 34 }}
              value={typeof search === 'object' ? '' : search}
              placeholder={`${searchType}`}
            />
          )}
          {searchType === 'marketing_person' && (
            <Select
              styles={customStyles}
              options={marketingPersonOptions}
              onChange={(value) => setSearch(value.value)}
              value={marketingPersonOptions.find((b) => b._id === search)}
            />
          )}
        </Col>
        <Col className='mb-1' md='2' sm='6'>
          <Select
            styles={customStyles}
            defaultValue={{
              value: 'waybillnumber',
              label: t('finance.waybill'),
            }}
            onChange={(val) => {
              setSearch('');
              setSearchType(val.value);
            }}
            options={[
              { value: 'waybillnumber', label: t('finance.waybill') },
              { value: 'customer_code', label: t('finance.customer_code') },
              { value: 'collection_type', label: t('finance.collection') },
              { value: 'payment_type', label: t('finance.payment') },
              {
                value: 'marketing_person',
                label: t('address_book.sale_person'),
              },
            ]}
          />
        </Col>

        <Col className='mb-1' md='4' sm='6' xs='10'>
          <DateRangePicker
            onDateChange={(dates) => {
              setDateSearch({
                startDate: dates[0],
                endDate: dates[1],
              });
            }}
          />
        </Col>

        {searchType && (
          <Button
            size='sm'
            color='success'
            style={{ height: 35 }}
            onClick={() => {
              if (search || dateSearch) {
                //  setSearch('')
                setPurchaseDate('');
                setSignDate('');
                const searchData = {};
                if (search) searchData[searchType] = search.trim();
                if (dateSearch?.startDate) {
                  searchData.startDate = dayjs(dateSearch.startDate).format('MM/DD/YYYY');

                  searchData.endDate =
                    dayjs(dateSearch.endDate).format('MM/DD/YYYY') || dayjs(new Date()).format('MM/DD/YYYY');
                }

                onSearch(searchData);
              }
            }}
          >
            <i className='fa fa-search' />
          </Button>
        )}
      </Row>
    </>
  );
}

export default FilterBox;
