import EntryGrid from 'components/Inputs/EntryGrid';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, FormGroup } from 'reactstrap';
import * as ItemTypeAction from 'store/actions/item_type';
import SelectBox from 'components/SelectBox';
import TextBox from 'components/Inputs/TextBox';
import { t } from 'i18next';
import { getCargoType } from 'utli';
import NumberInput from 'components/Inputs/NumberInput';

function ItemInfoForm({ register, errors, control, editData, getValues, watch, setValue }) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(ItemTypeAction.getAll({}));
  }, [ItemTypeAction.getAll, dispatch]);

  const item_types = useSelector((state) => state.other_maintenance.item_types);

  const itemTypeOptions = item_types?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  const [cargoType, setCargoType] = React.useState('');

  React.useEffect(() => {
    if (editData) {
      setCargoType(getCargoType(getValues('iteminfo.weight') / getValues('iteminfo.volume')));
    }
  }, []);

  return (
    <EntryGrid title={t('waybill_entry.item_information')}>
      <Row className='pt-1 pb-1'>
        <Col md={2} sm={6}>
          <FormGroup>
            <label>{t('waybill_entry.item_name')}</label>
            <TextBox
              className='form-control mr-2'
              type='text'
              registerProps={register('iteminfo.itemname')}
              errors={errors?.iteminfo?.itemname}
            />
          </FormGroup>
        </Col>
        <Col md={5} sm={12} className='d-flex justify-content-around' style={{ flexWrap: 'wrap' }}>
          <FormGroup style={{ width: 90 }}>
            <label>{t('waybill_entry.qty')}</label>
            <NumberInput
              className='form-control mr-2'
              errors={errors?.iteminfo?.qty}
              defaultValue={1}
              value={watch('iteminfo.qty')}
              onChange={(value) => {
                setValue('iteminfo.qty', value);
              }}
            />
          </FormGroup>
          <FormGroup style={{ width: 100 }}>
            <label>{t('waybill_entry.weight')}</label>
            <TextBox
              step='.001'
              className='form-control mr-2'
              errors={errors?.iteminfo?.weight}
              defaultValue={0}
              registerProps={register('iteminfo.weight')}
              onChange={(e) => {
                const value = e.target.value;
                setValue('iteminfo.weight', value);

                setCargoType(getCargoType(Number(value) / getValues('iteminfo.volume')));

                let result = getCargoType(value / getValues('iteminfo.volume'));
                if (result === 'Light Cargo') {
                  setValue('costinfo.qty_basic_freight', getValues('iteminfo.volume'));
                  setValue(
                    'costinfo.basic_freight',
                    Number(getValues('costinfo.org_basic_freight')) * Number(getValues('iteminfo.volume'))
                  );
                  setValue('costinfo.qty_basic_freight_label', 'Cbm');
                } else {
                  setValue('costinfo.qty_basic_freight', value);
                  setValue('costinfo.basic_freight', Number(getValues('costinfo.org_basic_freight')) * Number(value));
                  setValue('costinfo.qty_basic_freight_label', 'Kg');
                }
              }}
            />
          </FormGroup>
          <FormGroup style={{ width: 100 }}>
            <label>{t('waybill_entry.volume')}</label>
            <TextBox
              step='.001'
              className='form-control mr-2'
              errors={errors?.iteminfo?.volume}
              defaultValue={0}
              registerProps={register('iteminfo.volume')}
              onChange={(event) => {
                const value = event.target.value;
                setValue('iteminfo.volume', value);
                setCargoType(getCargoType(getValues('iteminfo.weight') / Number(event.target.value)));
                let result = getCargoType(getValues('iteminfo.weight') / Number(event.target.value));

                if (result === 'Light Cargo') {
                  setValue('costinfo.qty_basic_freight', event.target.value);
                  setValue(
                    'costinfo.basic_freight',
                    Number(getValues('costinfo.org_basic_freight')) * Number(event.target.value)
                  );
                  setValue('costinfo.qty_basic_freight_label', 'Cbm');
                } else {
                  setValue('costinfo.qty_basic_freight', getValues('iteminfo.weight'));
                  setValue(
                    'costinfo.basic_freight',
                    Number(getValues('costinfo.org_basic_freight')) * Number(getValues('iteminfo.weight'))
                  );
                  setValue('costinfo.qty_basic_freight_label', 'Kg');
                }
              }}
            />
          </FormGroup>

          <FormGroup style={{ width: 90 }}>
            <label>{t('waybill_entry.value')}</label>
            <TextBox
              className='form-control mr-2'
              type='number'
              step='.001'
              errors={errors?.iteminfo?.item_value}
              defaultValue={0}
              registerProps={register('iteminfo.item_value')}
            />
          </FormGroup>
        </Col>
        <Col md={2} sm={6}>
          <FormGroup>
            <label>{t('waybill_entry.item_type')}</label>
            <SelectBox
              label={t('waybill_entry.item_type')}
              control={control}
              name='iteminfo.itemtype'
              options={itemTypeOptions}
              rules={{ required: true }}
              value={editData?.item?.itemtype}
            />
          </FormGroup>
        </Col>

        <Col md={2} sm={6}>
          <FormGroup>
            <label>{t('waybill_entry.cargo_type')}</label>
            <TextBox
              className='form-control'
              type='text'
              disabled={true}
              errors={errors?.iteminfo?.cargo_type}
              value={cargoType}
            />
          </FormGroup>
        </Col>

        <Col md={2} sm={6}>
          <FormGroup>
            <label>{t('remark')}</label>
            <TextBox
              className='form-control mr-2'
              type='text'
              registerProps={register('iteminfo.remark')}
              errors={errors?.iteminfo?.remark}
            />
          </FormGroup>
        </Col>
      </Row>
    </EntryGrid>
  );
}
export default ItemInfoForm;
