import {
  SET_INVENTORY,
  SET_WAYS,
  SET_END_WAYS,
  SET_WAY_OPTIONS,
  SET_WAY_CARGOS,
  SET_LOADED_WAYBILLS,
  SET_CONFIRMED_WAYBILLS,
  SET_DELIVERED_WAYBILLS,
  SET_UNLOADED_WAYBILLS,
  SET_WAYBILL_OPTIONS,
} from 'store/types/cargo';

const initialState = {
  inventories: [],
  ways: [],
  end_ways: [],
  way_options: [],
  waybill_options: [],
  cargos: [],
  loaded_waybills: [],
  confirmed_waybills: [],
  unloaded_waybills: [],
  delivered_waybills: [],
};

const waybill = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVENTORY:
      return {
        ...state,
        inventories: action.payload,
      };

    case SET_WAYS:
      return {
        ...state,
        ways: action.payload,
      };
    case SET_END_WAYS:
      return {
        ...state,
        end_ways: action.payload,
      };

    case SET_WAY_OPTIONS:
      return {
        ...state,
        way_options: action.payload,
      };
    case SET_WAYBILL_OPTIONS:
      return {
        ...state,
        waybill_options: action.payload,
      };
    case SET_WAY_CARGOS:
      return {
        ...state,
        cargos: action.payload,
      };
    case SET_LOADED_WAYBILLS:
      return {
        ...state,
        loaded_waybills: action.payload,
      };
    case SET_CONFIRMED_WAYBILLS:
      return {
        ...state,
        confirmed_waybills: action.payload,
      };
    case SET_DELIVERED_WAYBILLS:
      return {
        ...state,
        delivered_waybills: action.payload,
      };
    case SET_UNLOADED_WAYBILLS:
      return {
        ...state,
        unloaded_waybills: action.payload,
      };

    default:
      return state;
  }
};

export default waybill;
