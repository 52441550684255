export const moneyFormatter = (value) => {
  return new Intl.NumberFormat().format(plainNumber(value));
};

export const moneyDecimalFormatter = (value) => {
  if (value === 0) {
    return 0;
  }
  if (value < 0) return value;

  if (value) {
    if (typeof value !== 'string') {
      value = value.toString();
    }
    const clean = value.replace(/,/g, '');
    const regex = /^[0-9]*\.?[0-9]*$/;

    if (value && clean.match(regex)) {
      if (!value.includes('.')) {
        const formatted = new Intl.NumberFormat().format(parseFloat(clean));
        return formatted;
      } else {
        return value;
      }
    } else {
      return value;
    }
  }
};

export const customRound = (value) => {
  if (value) {
    var customValue = value.toString();
    if (customValue.indexOf('.') !== -1) {
      var decimal = customValue.substr(customValue.indexOf('.') + 1, 1);
      var whole = customValue.substr(0, customValue.indexOf('.'));
      if (parseInt(decimal) > 4) {
        return parseInt(whole) + 1;
      }
      return parseInt(whole);
    }
    if (value > 0) return value;
    else return 0;
  }
  return 0;
};

export const plainNumber = (value) => {
  if (!value || isNaN(Number(value))) return 0;

  if (typeof value === 'string') return +value.replace(/[^0-9]/g, '');

  if (typeof value === 'number') return +value.toString().replace(/[^0-9]/g, '');
};

const getCurrentTime = new Date().getTime();
export const isExpired = (expire_date = null) =>
  expire_date ? getCurrentTime > new Date(expire_date).getTime() : false;

export const getCargoType = (value) => {
  if (value !== 'infinity') {
    if (value <= 300) return 'Light Cargo';
    else if (value <= 600) return 'Light Heavy Cargo';
    else if (value > 600) return 'Heavy Cargo';
    return '';
  } else return '';
};
