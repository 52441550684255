import {
  SET_EMPLOYEES,
  SET_BRANCHS,
  SET_POSITION,
  SET_ADDRESSBOOKS,
  SET_MARKETING_PERSONS,
  SET_CLEARENCES,
  SET_ESTIMATED_TIMES,
  SET_BASIC_FREIGHT_VALUES,
} from 'store/types/information';

const initialState = {
  employees: [],
  branches: [],
  positions: [],
  addressBooks: [],
  marketing_persons: [],
  estimatedTimes: [],
  clearences: [],
  basicFreightValues: [],
};

const information = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
      };
    case SET_ESTIMATED_TIMES:
      return {
        ...state,
        estimatedTimes: action.payload,
      };
    case SET_BASIC_FREIGHT_VALUES:
      return {
        ...state,
        basicFreightValues: action.payload,
      };

    case SET_CLEARENCES:
      return {
        ...state,
        clearences: action.payload,
      };

    case SET_BRANCHS:
      return {
        ...state,
        branches: action.payload,
      };
    case SET_POSITION:
      return {
        ...state,
        positions: action.payload,
      };
    case SET_ADDRESSBOOKS:
      return {
        ...state,
        addressBooks: action.payload,
      };
    case SET_MARKETING_PERSONS:
      return {
        ...state,
        marketing_persons: action.payload,
      };

    default:
      return state;
  }
};

export default information;
