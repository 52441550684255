import React from 'react';

function StatuBox({ bgColor, label }) {
  return (
    <div className='ml-4 d-flex align-items-center'>
      <div
        style={{
          width: 30,
          height: 15,
          marginRight: 10,
          border: '1px solid white',
          background: bgColor,
        }}
      ></div>
      <h5 className='text-white'>{label}</h5>
    </div>
  );
}

export default StatuBox;
