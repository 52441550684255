import * as TranscationService from 'services/transcation';
import { SET_TRANSCATIONS } from 'store/types/waybill';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

export const getAll = (query) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await TranscationService.getAll(query);
    dispatch({
      type: SET_TRANSCATIONS,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const exportData = (query) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });

    const response = await TranscationService.exportData(query);
    const blob = await response;

    const temp_date = dayjs(new Date()).format('DD/MM/YYYY');
    saveAs(blob, `transcation-${temp_date}.csv`);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};
