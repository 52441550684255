import React from 'react';
import { Modal, ModalBody, Row, Col, Button, FormGroup, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import classnames from 'classnames';
import TextBox from 'components/Inputs/TextBox';
import { getTracking } from 'services/waybill';
import { load, edit_cargo_loading } from 'services/cargo';
import { t } from 'i18next';

export default function LoadingModal({ modalOpen, onToggle, started_way, title, way, editData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [actionLoading, setActionLoading] = React.useState(false);

  const submitData = async (values) => {
    setActionLoading(true);
    const data = await getTracking(values.waybill_number.trim());
    if (!data) {
      NotificationManager.error('Waybill not found');
      setActionLoading(false);
      return;
    }

    if (data.currentStatus === 'ORDERCREATED') {
      NotificationManager.error('Waybill not found');
      setActionLoading(false);
      return;
    }

    const { waybill_info } = data;

    if (waybill_info.item.qty < Number(values.qty)) {
      // eslint-disable-next-line quotes
      NotificationManager.error("Qty is larger than waybill's qty");
      setActionLoading(false);
      return;
    }

    values.waybill_number = values.waybill_number.trim();

    const percentage = (Number(values.qty) / waybill_info.item.qty) * 100;
    const weight = Math.ceil((waybill_info.item.weight / 100) * percentage);
    const volume = ((waybill_info.item.volume / 100) * percentage).toFixed(2);

    if (editData) {
      await edit_cargo_loading({
        ...values,
        ...{
          volume,
          weight,
          way_id: way._id,
          status: started_way ? 'LOADED' : 'UNLOADED',
        },
      });
    } else {
      await load({
        ...values,
        ...{
          volume,
          weight,
          way_id: way._id,
          status: started_way ? 'LOADED' : 'UNLOADED',
        },
      });
    }
    setActionLoading(false);
    reset();
    onToggle();
  };

  React.useEffect(() => {
    if (editData) {
      setValue('waybill_number', editData?.waybillnumber);
      setValue('qty', editData?.qty);
    } else {
      reset();
    }
  }, [editData, reset, setValue]);

  return (
    <Modal isOpen={modalOpen} style={{ top: '15%', maxWidth: '80%' }} backdrop={true}>
      <div className=' modal-header pb-0'>
        <h5 className=' modal-title'>{title}</h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitData)}>
          <Row>
            <Col md='6'>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('cargo_loading.waybill_no')}</label>
                <TextBox
                  className='form-control'
                  placeholder={t('cargo_loading.waybill_no')}
                  type='text'
                  disabled={editData}
                  registerProps={register('waybill_number', {
                    required: 'Waybill No is required',
                  })}
                  errors={errors?.waybill_number}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('cargo_loading.qty')}</label>
                <TextBox
                  className='form-control'
                  placeholder={t('cargo_loading.qty')}
                  type='text'
                  registerProps={register('qty', {
                    required: 'Qty is required',
                  })}
                  errors={errors?.qty}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className='text-center'>
            <Button
              className='my-4'
              color='success'
              type='button'
              disabled={actionLoading}
              onClick={() => onToggle(!modalOpen)}
            >
              {t('cancel')}
            </Button>
            <Button className='my-4' color='primary' type='submit' disabled={actionLoading}>
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
