import { getData, sendData } from './apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';
export async function getCountry(query, refresh = false) {
  try {
    let response = await getData(
      'country/get-all' + (refresh ? '-refresh' : '') + `?${new URLSearchParams(query).toString()}`
    );
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getCountryOption() {
  try {
    let response = await getData('country/get-option');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function saveCountry(values) {
  try {
    return await sendData('country/create', values);
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}

export async function updateCountry(data) {
  try {
    return await sendData('country/update', data);
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}

export async function getRegion(query, refresh = false) {
  try {
    let response = await getData(
      'region/get-all' + (refresh ? '-refresh' : '') + `?${new URLSearchParams(query).toString()}`
    );
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getDistriceOption(region_id) {
  try {
    let response = await getData(`district/get-option/${region_id}`);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function saveRegion(values) {
  try {
    return await sendData('region/create', values);
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}

export async function updateRegion(data) {
  try {
    return await sendData('region/update', data);
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}

export async function getDistrict(query, refresh = false) {
  try {
    let response = await getData(
      'district/get-all' + (refresh ? '-refresh' : '') + `?${new URLSearchParams(query).toString()}`
    );
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function saveDistrict(values) {
  try {
    let response = await sendData('district/create', values);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function updateDistrict(values) {
  try {
    let response = await sendData('district/update', values);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getTownship(query, refresh = false) {
  try {
    let response = await getData(
      'township/get-all' + (refresh ? '-refresh' : '') + `?${new URLSearchParams(query).toString()}`
    );
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function saveTownship(values) {
  try {
    let response = await sendData('township/create', values);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function updateTownship(values) {
  try {
    let response = await sendData('township/update', values);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function deleteTownship(values) {
  try {
    let response = await sendData('township/delete', values);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function deleteDistrict(values) {
  try {
    let response = await sendData('district/delete', values);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function deleteRegion(values) {
  try {
    let response = await sendData('region/delete', values);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function deleteCountry(values) {
  try {
    let response = await sendData('country/delete', values);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}
