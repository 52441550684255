export function getHeaders() {
  const headers = [
    {
      text: '',
      width: 50,
      className: 'fixed-cell left',
    },

    {
      text: 'waybill_entry.waybill',
      className: 'fixed-cell left',
      left: 55,
    },
    {
      text: 'waybill_entry.depature_branch',
    },
    {
      text: 'waybill_entry.destination',
    },

    {
      text: 'address_book.code',
    },

    {
      text: 'address_book.sender',
    },

    {
      text: 'address_book.receiver',
    },

    {
      text: 'waybill_entry.item_name',
    },
    {
      text: 'waybill_entry.qty',
    },
    {
      text: 'waybill_entry.weight',
    },
    {
      text: 'waybill_entry.volume',
    },
    {
      text: 'waybill_entry.remark',
    },
  ];

  return headers;
}
