import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import Table from 'components/Table';
import CheckBox from 'components/Inputs/CheckBox';
import { t } from 'i18next';

function List({ headers, data, filter, buttons, selectedData, onSelect }) {
  return (
    <Table
      title={t('sidebar.customer_service')}
      subtitle={t('sidebar.waybill_comment')}
      filter={filter}
      headers={headers.map((e) => (
        <th
          className={clsx('headers', e.className)}
          key={`thead-${Math.random()}`}
          style={{ width: e.width, left: e.left }}
        >
          {t(e.text)}
        </th>
      ))}
      buttons={buttons}
      data={data}
      rows={data.docs?.map((e) => (
        <tr key={`table-row-${Math.random()}`}>
          <td className='fixed-cell left'>
            <CheckBox padding='mr-0' onChange={() => onSelect(e)} checked={selectedData.find((s) => s._id === e._id)} />
          </td>
          <td>{e.waybill_number}</td>
          <td>{e.content}</td>
          <td>{e.createdBy?.name}</td>
          <td>{e.createdBranch?.name}</td>
          <td>{dayjs(e.createdAt).format('DD/MM/YYYY HH:mm')}</td>
        </tr>
      ))}
    />
  );
}

export default List;
