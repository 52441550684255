export function getHeaders() {
  const headers = [
    {
      text: '',
      width: 50,
      className: 'fixed-cell left',
    },

    {
      text: 'finance.waybill',
      className: 'fixed-cell left',
      left: 55,
    },
    {
      text: 'finance.customer_code',
    },
    {
      text: 'finance.purchase_date',
    },
    {
      text: 'waybill_entry.shipping_date',
    },
    {
      text: 'finance.payment',
    },
    {
      text: 'finance.collection_type',
    },
    {
      text: 'finance.currency',
    },
    {
      text: 'finance.paid',
    },
    {
      text: 'finance.exchange_rate',
    },
    {
      text: 'finance.paid_mmk',
    },
    {
      text: 'finance.billing_amount',
    },
    {
      text: 'finance.discount',
    },
    {
      text: 'finance.remaining_balance',
    },
    {
      text: 'finance.reference_number',
    },
    {
      text: 'waybill_entry.departure',
    },
    {
      text: 'waybill_entry.destination',
    },
    {
      text: 'waybill_entry.marketing_person',
    },
    {
      text: 'waybill_entry.operator',
    },
    {
      text: 'waybill_entry.remark',
    },
  ];

  return headers;
}
