import React from 'react';
import { InputGroupAddon, InputGroupText, InputGroup } from 'reactstrap';

import './text-box.scss';
function TextBox({
  icon,
  prepend = false,
  outputPrepend = null,
  errors,
  customErrorMessage = '',
  registerProps,
  onChange,
  ...props
}) {
  const getInput = (type) => {
    switch (type) {
      case 'textarea':
        return (
          <textarea
            className='form-control '
            {...props}
            {...registerProps}
            autoComplete={'off'}
            style={{ minHeight: 100 }}
          ></textarea>
        );

      case 'number':
        return (
          <input
            step='.01'
            className='form-control'
            {...props}
            {...registerProps}
            autoComplete={'off'}
            onChange={onChange}
          />
        );

      default:
        return (
          <input className='form-control' {...props} {...registerProps} autoComplete={'off'} onChange={onChange} />
        );
    }
  };

  return (
    <>
      <InputGroup className='custom-tb input-group-merge input-group-alternative'>
        {prepend && (
          <InputGroupAddon addonType='prepend'>
            <InputGroupText>{icon}</InputGroupText>
          </InputGroupAddon>
        )}

        {getInput(props.type)}
        {outputPrepend && <div className='output-prepend'>{outputPrepend}</div>}
      </InputGroup>
      {errors && (
        <div className='error-containe' style={{ marginTop: props.type === 'textarea' ? 80 : 5 }}>
          <span className='text-danger'>{errors?.message}</span>
        </div>
      )}

      {errors && errors.type === 'validate' && (
        <div className='error-container'>
          <span className='text-danger'>{customErrorMessage}</span>
        </div>
      )}
      {errors && errors.type === 'minLength' && (
        <div className='error-container'>
          <span className='text-danger'>{customErrorMessage}</span>
        </div>
      )}
    </>
  );
}
export default TextBox;
