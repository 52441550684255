import {
  getCountry as getCountryService,
  getCountryOption as getCountryOptionService,
  saveCountry as saveCountryService,
  updateCountry as updateCountryService,
  getRegion as getRegionService,
  saveRegion as saveRegionService,
  updateRegion as updateRegionService,
  getDistrict as getDistrictService,
  saveDistrict as saveDistrictService,
  updateDistrict as updateDistrictService,
  getTownship as getTownshipService,
  saveTownship as saveTownshipService,
  updateTownship as updateTownshipService,
  deleteTownship as deleteTownshipService,
  deleteDistrict as deleteDistrictService,
  deleteRegion as deleteRegionService,
  deleteCountry as deleteCountryService,
} from 'services/locationService';
import { SET_COUNTRY, SET_REGION, SET_DISTRICT, SET_TOWNSHIP } from 'store/types/location';

export const getCountry = (query, refresh = false) => {
  return async (dispatch, getState) => {
    if (getState().location.countries.length > 0 && !refresh) {
      return;
    }
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await getCountryService(query, refresh);
    dispatch({
      type: SET_COUNTRY,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const getCountryOption = () => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await getCountryOptionService();
    dispatch({
      type: SET_COUNTRY,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const saveCountry = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await saveCountryService(values);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const updateCountry = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await updateCountryService(data);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const getRegion = (query, refresh = false) => {
  return async (dispatch, getState) => {
    if (getState().location.regions.length > 0 && !refresh) {
      return;
    }
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await getRegionService(query, refresh);
    dispatch({
      type: SET_REGION,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const saveRegion = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await saveRegionService(values);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const updateRegion = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await updateRegionService(data);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const getDistrict = (query, refresh = false) => {
  return async (dispatch, getState) => {
    if (getState().location.districts.length > 0 && !refresh) {
      return;
    }
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await getDistrictService(query, refresh);
    dispatch({
      type: SET_DISTRICT,
      payload: response.docs,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const saveDistrict = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await saveDistrictService(values);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const updateDistrict = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await updateDistrictService(data);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const getTownship = (query, refresh = false) => {
  return async (dispatch, getState) => {
    if (getState().location.townships.length > 0 && !refresh) {
      return;
    }
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await getTownshipService(query, refresh);
    dispatch({
      type: SET_TOWNSHIP,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const saveTownship = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await saveTownshipService(values);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const updateTownship = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await updateTownshipService(data);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteTownship = (_id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await deleteTownshipService({ _id: _id });
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteDistrict = (_id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await deleteDistrictService({ _id: _id });
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteRegion = (_id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await deleteRegionService({ _id: _id });
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteCountry = (_id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await deleteCountryService({ _id: _id });
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};
