import * as FinanceService from 'services/finance';
import { SET_FINANCE_WAYBILLS } from 'store/types/waybill';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
export const getAll = (query, body) => {
  return async (dispatch) => {
    dispatch({
      type: SET_FINANCE_WAYBILLS,
      payload: [],
    });
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await FinanceService.getAll(query, body);
    dispatch({
      type: SET_FINANCE_WAYBILLS,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const pay = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    const result = await FinanceService.pay(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });

    if (result) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
};

export const exportData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });

    const response = await FinanceService.exportData(data);
    const blob = await response;

    const temp_date = dayjs(new Date()).format('DD/MM/YYYY');
    saveAs(blob, `Sales-${temp_date}.csv`);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};
