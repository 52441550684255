import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import DetailDialog from 'pages/waybill/query/detailDialog';
import FilterBox from './filter';
import * as InformationAction from 'store/actions/information';
import * as SignAction from 'store/actions/sign';
import SignModal from './signModal';
import List from './list';
import { getHeaders } from './header';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import { t } from 'i18next';
import useActionPermission from 'hooks/useActionPermission';

function SignQuery() {
  const dispatch = useDispatch();
  const router = useHistory();
  const signs = useSelector((state) => state.waybill.signs);
  const [searchValue, setSearchValue] = React.useState(null);
  const [selectedData, setSelectedData] = React.useState([]);
  const [showAddNew, setShowAddNew] = React.useState(false);
  const [showDetail, setShowDetail] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);
  const { canAdd, canEdit } = useActionPermission('sign');

  React.useEffect(() => {
    dispatch(InformationAction.getBranch());
  }, [dispatch, InformationAction.getBranch]);

  React.useEffect(() => {
    loadData();
  }, [SignAction.getAll, router.location.search]);

  const loadData = (value = null) => {
    setSelectedData([]);
    setSearchValue(value);
    let query = { limit: 10, page: 1 };
    if (router.location.search) query = queryString.parse(router.location.search);
    dispatch(SignAction.getAllSigned(query, value ? value : searchValue ? searchValue : {}));
  };

  return (
    <>
      <List
        headers={getHeaders()}
        data={signs}
        selectedData={selectedData}
        showDetail={(e) => {
          setEditData(e);
          setShowDetail(true);
        }}
        onSelect={(e) => {
          if (selectedData.find((s) => s._id === e._id)) setSelectedData(selectedData.filter((s) => s._id !== e._id));
          else setSelectedData([...selectedData, e]);
        }}
        filter={
          <FilterBox
            onSearch={(value) => {
              loadData(value);
              setSelectedData([]);
            }}
          />
        }
        buttons={
          <>
            {canAdd() && (
              <Button
                style={{ width: 100 }}
                size='sm'
                onClick={() => {
                  setEditData(null);
                  setShowAddNew(true);
                }}
              >
                <i className='fa fa-plus mr-2' /> {t('add')}
              </Button>
            )}
            <Button
              style={{ width: 100 }}
              size='sm'
              onClick={() => {
                window.location.reload();
              }}
            >
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
            <Button
              style={{ width: 100 }}
              size='sm'
              onClick={() => {
                dispatch(SignAction.exportSignQuery());
              }}
            >
              <i className='fa fa-file-excel mr-2' /> {t('export')}
            </Button>
            {canEdit() && (
              <Button
                style={{ width: 100 }}
                size='sm'
                disabled={selectedData.length !== 1}
                onClick={() => {
                  setEditData(selectedData[0]);
                  setShowAddNew(true);
                }}
              >
                <i className='fa fa-edit mr-2' /> {t('edit')}
              </Button>
            )}
            {canEdit() && (
              <Button
                style={{ width: 100 }}
                size='sm'
                disabled={selectedData.length !== 1}
                onClick={() => {
                  setEditData(selectedData[0]);
                  setShowDelete(true);
                }}
              >
                <i className='fa fa-trash mr-2' /> {t('delete')}
              </Button>
            )}
          </>
        }
      />
      <DetailDialog
        data={editData?.waybill}
        modalOpen={showDetail}
        onToggle={() => {
          setShowDetail(false);
          setEditData(null);
        }}
        showSign={true}
        signInfo={editData}
        customItemName={editData?.waybill?.item?.itemtype?.name}
        customPaymentMethod={editData?.waybill?.cost.paymentType.name}
      />
      <SignModal
        data={editData}
        modalOpen={showAddNew}
        onToggle={() => {
          setShowAddNew(!showAddNew);
          setSelectedData([]);
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`${t('delete')} ${t('sidebar.sign_query')}`}
        body={`${t('confirm_delete')} ${editData?.waybill_number}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(SignAction.deleteData(editData?._id));
          setTimeout(() => loadData(), 1000);
        }}
      />
    </>
  );
}

export default SignQuery;
