import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, Button } from 'reactstrap';
import List from './list';
import { t } from 'i18next';
import * as SuggestionAction from 'store/actions/suggestion';

function Suggestion() {
  const dispatch = useDispatch();
  const [suggestion, setSuggestion] = React.useState(null);

  const suggestions = useSelector((state) => state.other_maintenance.suggestions);

  const headers = ['no', 'name', 'email', 'sidebar.suggestion', 'created_at', ''];
  React.useEffect(() => {
    dispatch(SuggestionAction.getAll({}));
  }, [SuggestionAction.getAll, dispatch]);

  return (
    <>
      <List
        headers={headers}
        data={suggestions}
        viewSuggestion={(data) => {
          setSuggestion(data);
        }}
      />
      <Modal isOpen={suggestion} style={{ top: '5%', maxWidth: '40%', minWidth: 450 }} backdrop={true}>
        <ModalBody>
          <div className='py-3'>
            <p>{suggestion?.suggestion}</p>
          </div>
          <div className='text-right'>
            <Button className='my-4' color='success' type='button' onClick={() => setSuggestion(null)}>
              {t('ok')}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
export default Suggestion;
