import React from 'react';
import { Modal, ModalBody, Button, FormGroup, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import TextBox from 'components/Inputs/TextBox';
import SelectBox from 'components/SelectBox';
import { getDistriceOption } from 'services/locationService';
import { t } from 'i18next';

export default function AddDialog({ modalOpen, onToggle, title, onSubmit, editData, options }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm();

  const [regions, setRegionOption] = React.useState([]);
  const [districts, setDistrictOption] = React.useState([]);

  const submitData = async (values) => {
    reset();
    onSubmit(values);
  };

  const countryOptions = options.countries?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  const regionOptions = regions?.map((e) => {
    return {
      label: e.name,
      value: e.value,
    };
  });

  const districtOptions = districts?.map((e) => {
    return {
      label: e.label,
      value: e.value,
    };
  });

  React.useEffect(() => {
    if (editData) {
      setValue('name', editData?.name);
      setValue('country', editData?.country._id);
    } else {
      reset();
    }
  }, [editData]);

  return (
    <Modal isOpen={modalOpen} style={{ top: '25%' }} backdrop={true}>
      <div className=' modal-header'>
        <h5 className=' modal-title'>{title}</h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitData)}>
          <FormGroup className={classnames('mb-3')}>
            <label>{t('name')}</label>
            <TextBox
              className='form-control'
              placeholder={t('name')}
              type='text'
              registerProps={register('name', {
                required: `${t('name')} ${t('is_required')}`,
              })}
              errors={errors.name}
              defaultValue={editData?.name}
            />
          </FormGroup>
          <FormGroup className={classnames('mb-3')}>
            <label>{t('country')}</label>
            <SelectBox
              control={control}
              name='country'
              options={countryOptions}
              value={editData?.country._id}
              rules={{ required: true }}
              onValueChange={(value) => {
                if (!value) setRegionOption([]);
                else {
                  const result = options.countries.find((c) => c._id === value);
                  console.log(result?.region);
                  setRegionOption(result?.region || []);
                }
              }}
              label={t('country')}
            />
          </FormGroup>
          <FormGroup className={classnames('mb-3')}>
            <label>{t('region')}</label>
            <SelectBox
              control={control}
              name='region'
              options={regionOptions}
              value={editData?.region}
              rules={{ required: true }}
              onValueChange={async (value) => {
                if (!value) setDistrictOption([]);
                else {
                  const response = await getDistriceOption(value);
                  setDistrictOption(response);
                }
              }}
              label={t('region')}
            />
          </FormGroup>
          <FormGroup className={classnames('mb-3')}>
            <label>{t('District')}</label>
            <SelectBox
              control={control}
              name='district'
              options={districtOptions}
              value={editData?.region}
              rules={{ required: true }}
              label={t('eistrict')}
            />
          </FormGroup>

          <div className='text-center'>
            <Button
              className='my-4'
              color='success'
              type='button'
              onClick={() => onToggle(!modalOpen)}
              //   disabled={loading}
            >
              {/* {loading && (
                <Spinner size="sm" role="status" className="mr-2"></Spinner>
              )} */}
              {t('cancel')}
            </Button>
            <Button
              className='my-4'
              color='primary'
              type='submit'
              // disabled={loading}
            >
              {/* {loading && (
                <Spinner size="sm" role="status" className="mr-2"></Spinner>
              )} */}
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
