import { getData, sendData } from './apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';

export async function getAll(query) {
  try {
    let response = await getData(`cargo-action/get-cargo-to-load?${new URLSearchParams(query).toString()}`);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getAllConfirmed(query) {
  try {
    let response = await getData(`cargo-action/get-cargo-to-confirm?${new URLSearchParams(query).toString()}`);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function load(values) {
  try {
    return await sendData('cargo-action/manual-cargo-loading', values);
  } catch (e) {
    NotificationManager.error('Opps Something Wrong');
    return false;
  }
}

export async function edit_cargo_loading(values) {
  try {
    return await sendData('cargo-action/manual-edit-cargo-loading', values);
  } catch (e) {
    NotificationManager.error('Opps Something Wrong');
    return false;
  }
}

export async function undoload(values) {
  try {
    return await sendData('cargo-action/cargo-undo-loading', values);
  } catch (e) {
    NotificationManager.error('Opps Something Wrong');
    return false;
  }
}

export async function getAllToDeliver(query) {
  try {
    let response = await getData(`cargo-action/get-cargo-to-deliver?${new URLSearchParams(query).toString()}`);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getAllToUnload(query) {
  try {
    let response = await getData(`cargo-action/get-cargo-to-unload?${new URLSearchParams(query).toString()}`);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function unload(values) {
  try {
    return await sendData('cargo-action/cargo-unloading', values);
  } catch (e) {
    NotificationManager.error('Opps Something Wrong');
    return false;
  }
}

export async function undounload(values) {
  try {
    return await sendData('cargo-action/cargo-undo-unloading', values);
  } catch (e) {
    NotificationManager.error('Opps Something Wrong');
    return false;
  }
}

export async function subLoad(values) {
  try {
    return await sendData('cargo-action/load-sub-item', values);
  } catch (e) {
    NotificationManager.error('Opps Something Wrong');
    return false;
  }
}

export async function unloadSub(values) {
  try {
    return await sendData('cargo-action/unload-sub-item', values);
  } catch (e) {
    NotificationManager.error('Opps Something Wrong');
    return false;
  }
}
