import { getData, sendData } from './apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';
export async function getAll() {
  try {
    let response = await getData('collection-type/get-all-refresh');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function save(values) {
  try {
    return await sendData('collection-type/create', values);
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}

export async function deleteData(_id) {
  try {
    let response = await sendData('collection-type/delete', { id: _id });
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function update(values) {
  try {
    return await sendData('collection-type/update', values);
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}
