import { SET_DISTRICT, SET_COUNTRY, APPEND_COUNTRY, SET_REGION, SET_TOWNSHIP } from 'store/types/location';
const initialState = {
  countries: [],
  regions: [],
  districts: [],
  townships: [],
};
const location = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRY:
      return {
        ...state,
        countries: action.payload,
      };
    case SET_REGION:
      return {
        ...state,
        regions: action.payload,
      };
    case SET_DISTRICT:
      return {
        ...state,
        districts: action.payload,
      };
    case SET_TOWNSHIP:
      return {
        ...state,
        townships: action.payload,
      };
    case APPEND_COUNTRY:
      return {
        ...state,
        countries: [...state.countries, action.payload],
      };

    default:
      return state;
  }
};
export default location;
