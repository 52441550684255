import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import TextBox from 'components/Inputs/TextBox';
import { t } from 'i18next';
function FilterBox({ onSearch }) {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };

  const marketing_persons = useSelector((state) => state.information.marketing_persons);
  const marketingPersonOptions = marketing_persons?.map((e) => {
    return {
      label: e.label,
      value: e._id,
    };
  });

  const [searchType, setSearchType] = React.useState('code');
  const [search, setSearch] = React.useState();
  return (
    <Row>
      <Col md='5'>
        <Select
          styles={customStyles}
          defaultValue={{ value: 'code', label: t('code') }}
          onChange={(val) => {
            setSearch('');
            setSearchType(val.value);
          }}
          options={[
            { value: 'code', label: t('code') },
            { value: 'marketing_person', label: t('address_book.sale_person') },
            { value: 'sender_name', label: t('address_book.sender') },
            { value: 'receiver_name', label: t('address_book.receiver') },
          ]}
        />
      </Col>
      <Col md='5'>
        {searchType === 'marketing_person' && (
          <Select
            styles={customStyles}
            options={marketingPersonOptions}
            onChange={(value) => setSearch(value.value)}
            value={marketingPersonOptions.find((b) => b._id === search)}
          />
        )}
        {(searchType === 'code' || searchType === 'sender_name' || searchType === 'receiver_name') && (
          <TextBox onChange={(e) => setSearch(e.target.value)} style={{ height: 34 }} value={search} />
        )}
      </Col>

      {searchType && (
        <Button
          size='sm'
          color='success'
          style={{ height: 35 }}
          onClick={() => {
            if (search) {
              // setSearch('')
              const searchData = {};
              searchData[searchType] = search;
              onSearch(searchData);
            }
          }}
        >
          <i className='fa fa-search' />
        </Button>
      )}
    </Row>
  );
}

export default FilterBox;
