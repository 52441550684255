import React from 'react';
// react library for routing
import { useLocation, Switch } from 'react-router-dom';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import ProtectedRoute from 'protectedRoutes/protectedRoute';
import { globalStyle } from 'styles/globalStyle';
import NotAuthorized from 'components/NotAuthorized';
import { routes } from 'routes.js';
import { useDispatch, useSelector } from 'react-redux';

import { getPermission } from 'store/actions';
import FullScreenLoading from 'components/FullScreenLoading';
import clsx from 'clsx';
import useTags from 'hooks/useTags';
import Tag from 'components/Tag';

function Admin() {
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const mainContentRef = React.useRef(null);
  const { tags, removeTag } = useTags();
  const role = useSelector((state) => state.user.role);
  const accessToken = useSelector((state) => state.user.accessToken);
  const user = useSelector((state) => state.user.userInfo);
  const permissionLoading = useSelector((state) => state.loading.permissionLoading);
  const invisibleRoutes = [
    '/customer-service',
    '/waybill_tracking_management',
    '/additional_waybill_status',
    '/suggestion',
    '/quotes',
    '/cargo-unload-action-and-list',
  ];
  const [isAuthorized, setIsAuthorized] = React.useState(false);

  React.useEffect(() => {
    const permission_url = role?.permission;
    if (permission_url) {
      if (permission_url.includes(location.pathname)) {
        setIsAuthorized(true);
      }
      // nyi checks for permission
      else if (invisibleRoutes.includes(location.pathname)) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    }
  }, [role, location.pathname]);

  React.useEffect(() => {
    if (accessToken && user) dispatch(getPermission());
    else window.location = '/auth/login';
  }, [accessToken]);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      if (prop.layout === '/') {
        if (role?.permission.includes('/' + prop.path) || invisibleRoutes.includes('/' + prop.path))
          return (
            <ProtectedRoute
              path={prop.layout + prop.path}
              exact
              component={prop.component}
              key={key}
              name={prop.name}
            />
          );
      } else {
        return null;
      }
    });
  };
  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = () => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      //   document.body.classList.add('g-sidenav-hidden')
    } else {
      document.body.classList.add('g-sidenav-pinned');
      //  document.body.classList.remove('g-sidenav-hidden')
    }
    setSidenavOpen(!sidenavOpen);
  };

  return (
    <>
      <Sidebar routes={routes} toggleSidenav={toggleSidenav} sidenavOpen={sidenavOpen} />
      {globalStyle}
      <div className='main-content' ref={mainContentRef}>
        <AdminNavbar
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        {location.pathname !== '/' && (
          <Tag
            tags={tags}
            onRemove={(tag) => {
              removeTag(tag);
            }}
          />
        )}
        {!permissionLoading ? (
          <>
            {isAuthorized ? (
              <div className={clsx(location.pathname !== '/' && 'px-4 pt-3')}>
                <Switch>
                  {getRoutes(routes)}
                  {/* <Redirect from="*" to="/admin/dashboard" /> */}
                </Switch>
              </div>
            ) : (
              <NotAuthorized />
            )}
          </>
        ) : (
          <FullScreenLoading />
        )}
      </div>
      {sidenavOpen ? <div className='backdrop d-xl-none' onClick={toggleSidenav} /> : null}
    </>
  );
}

export default Admin;
