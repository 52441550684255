import { getData, sendData, sendForBlobData } from './apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';
export async function getAll(query, value) {
  try {
    let response = await sendData(
      `address-book/get-all-refresh?${new URLSearchParams(query).toString()}`,
      value,
      'POST',
      false
    );
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function save(values) {
  try {
    return await sendData('address-book/create', values);
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}

export async function deleteData(_id) {
  try {
    let response = await sendData('address-book/delete', { _id: _id });
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function update(values) {
  try {
    return await sendData('address-book/update/' + values._id, values, 'PUT');
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}

export async function fetchCustomerCode() {
  try {
    return await getData('address-book/fetch-customer-code');
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}

export async function exportData(data) {
  return await sendForBlobData('address-book/export-data', data, {
    responseType: 'blob',
  });
}
