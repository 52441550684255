import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import List from './list';
import AddDialog from './addDialog';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import * as ContainerAction from 'store/actions/container';
import { t } from 'i18next';
import AddNewButton from 'components/ActionButtons/addNewButton';
function Container() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);

  const dispatch = useDispatch();

  const containers = useSelector((state) => state.other_maintenance.containers);

  const headers = ['no', 'information_container.container_number', '', ''];
  React.useEffect(() => {
    dispatch(ContainerAction.getAll({}));
  }, [ContainerAction.getAll, dispatch]);

  return (
    <>
      <List
        headers={headers}
        data={containers}
        onEdit={(data) => {
          setModalOpen(true);
          setEditData(data);
        }}
        onDelete={(data) => {
          setShowDelete(true);
          setEditData(data);
        }}
        buttons={
          <>
            <AddNewButton
              onClick={() => {
                setModalOpen(true);
                setEditData(null);
              }}
              type='container'
            />
            <Button style={{ width: 95 }} size='sm' onClick={() => dispatch(ContainerAction.getAll({}, true))}>
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
          </>
        }
      />
      <AddDialog
        title={editData ? t('information_container.edit_container') : t('information_container.create_new_container')}
        editData={editData}
        modalOpen={modalOpen}
        onToggle={(open) => setModalOpen(open)}
        onSubmit={(values) => {
          setModalOpen(false);
          if (editData) dispatch(ContainerAction.update({ ...values, itemId: editData?._id }));
          else dispatch(ContainerAction.save(values));
          setTimeout(() => dispatch(ContainerAction.getAll({}, true)), 1000);
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={t('information_container.delete_container')}
        body={`${t('confirm_delete')} ${editData?.number}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(ContainerAction.deleteData(editData?._id));
          setTimeout(() => dispatch(ContainerAction.getAll({}, true)), 1000);
        }}
      />
    </>
  );
}

export default Container;
