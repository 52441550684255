export function getHeaders(selectDialog = false) {
  const headers = [
    {
      text: '',
      width: 50,
      className: 'fixed-cell left',
    },

    {
      text: 'finance.waybill',
      className: 'fixed-cell left',
      left: 55,
    },
    {
      text: 'waybill_entry.depature_branch',
    },
    {
      text: 'finance.customer_code',
    },
    {
      text: 'waybill_entry.sender_company',
    },
    {
      text: 'address_book.sender',
    },
    {
      text: 'address_book.receiver',
    },
    {
      text: 'waybill_entry.shipping_date',
    },
    {
      text: 'waybill_entry.sign_status',
    },
    {
      text: 'waybill_entry.sign_date',
    },
    {
      text: 'waybill_entry.total_amount',
    },
    {
      text: 'waybill_entry.paymentType',
    },
    {
      text: 'finance.paid_amount',
    },
    {
      text: 'finance.remaining_balance',
    },
  ];

  if (selectDialog) {
    headers.push({
      text: 'waybill_entry.marketing_person',
      width: 80,
    });
    headers.push({
      text: 'actions',
      className: 'fixed-cell',
      width: 80,
    });
  }

  return headers;
}
