import { getData } from './apiService';
import { NotificationManager } from 'react-notifications';
export async function getCountData() {
  try {
    let response = await getData('graph/get-count-data');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getAllTotal() {
  try {
    let response = await getData('graph/get-all-branch-total');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getMonthlyCost(month, year) {
  try {
    let response = await getData(`graph/get-monthly-data?month=${month}&year=${year}`);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getMonthlyWaybillCount(month, year) {
  try {
    let response = await getData(`graph/get-monthly-waybill-count?month=${month}&year=${year}`);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}
