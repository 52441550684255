export const SET_ITEMTYPE = 'SET_ITEMTYPE';
export const SET_SHIPPINGTYPE = 'SET_SHIPPINGTYPE';
export const SET_SHIPPINGMODE = 'SET_SHIPPINGMODE';
export const SET_PAYMENTTYPE = 'SET_PAYMENTTYPE';
export const SET_PAYMENTGROUP = 'SET_PAYMENTGROUP';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_COLLECTION = 'SET_COLLECTION';
export const SET_CONTAINER = 'SET_CONTAINER';
export const SET_SUGGESTION = 'SET_SUGGESTION';
export const SET_QUOTE = 'SET_QUOTE';
