import React, { useEffect } from 'react';
import { Modal, ModalBody, Button, FormGroup, Form, Row, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import TextBox from 'components/Inputs/TextBox';
import SelectBox from 'components/SelectBox';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import DatePicker from 'components/Inputs/DatePicker';
import dayjs from 'dayjs';
export default function AddDialog({ modalOpen, onToggle, title, onSubmit, editData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      update_value_date: new Date(),
    },
  });

  const submitData = async (values) => {
    reset();
    onSubmit(values);
  };

  useEffect(() => {
    if (editData) {
      setValue('update_value_date', editData?.update_value_date);
      setValue('cargo_type', editData?.cargo_type);
      setValue('departure', editData?.departure?._id);
      setValue('destination', editData?.destination?._id);
      setValue('itemtype', editData?.itemtype?._id);
      setValue('shipment_type', editData?.shipment_type?._id);
      setValue('shipping_mode', editData?.shipping_mode?._id);
      setValue('unit', editData?.unit);
      setValue('limit', editData?.limit);
      setValue('basic_freight_value', editData?.basic_freight_value);
      setValue('value', editData?.value);
    } else {
      reset();
    }
  }, [editData, reset]);

  const branches = useSelector((state) => state.information.branches);
  let branchOptions = [];
  if (branches.map) {
    branchOptions = branches?.map((e) => {
      return {
        label: e.label,
        value: e._id,
      };
    });
  }

  const shipping_modes = useSelector((state) => state.other_maintenance.shipping_modes);
  const shippingModeOptions = shipping_modes?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  const item_types = useSelector((state) => state.other_maintenance.item_types);

  const itemTypeOptions = item_types?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  const units = [
    { value: 'Weight', label: 'Weight' },
    { value: 'Measurement', label: 'Measurement' },
  ];

  const limits = [
    { value: '<=', label: '<=' },
    { value: '>', label: '>' },
  ];

  const shipping_types = useSelector((state) => state.other_maintenance.shipping_types);

  const shippingTypeOptions = shipping_types?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  return (
    <Modal isOpen={modalOpen} style={{ top: '25%', maxWidth: '60%' }} backdrop={true}>
      <div className=' modal-header'>
        <h5 className=' modal-title'>
          {title} {editData?.name}
        </h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitData)}>
          <Row>
            <Col md='4'>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('waybill_entry.departure')}</label>
                <SelectBox
                  label={t('waybill_entry.departure')}
                  control={control}
                  name='departure'
                  options={branchOptions}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col md='4'>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('waybill_entry.destination')}</label>
                <SelectBox
                  label={t('waybill_entry.destination')}
                  control={control}
                  name='destination'
                  options={branchOptions}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col md='4'>
              <FormGroup>
                <label>{t('waybill_entry.item_type')}</label>
                <SelectBox
                  label={t('waybill_entry.item_type')}
                  control={control}
                  name='itemtype'
                  options={itemTypeOptions}
                  rules={{ required: true }}
                  value={editData?.item?.itemtype}
                />
              </FormGroup>
            </Col>
            <Col md='4'>
              <FormGroup>
                <label>{t('waybill_entry.cargo_type')}</label>
                <SelectBox
                  control={control}
                  name='cargo_type'
                  options={[
                    { value: 'Light Cargo', label: 'Light Cargo' },
                    { value: 'Light Heavy Cargo', label: 'Light Heavy Cargo' },
                    { value: 'Heavy Cargo', label: 'Heavy Cargo' },
                  ]}
                  label={t('waybill_entry.cargo_type')}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col md='4'>
              <FormGroup>
                <label>{t('waybill_entry.shipment_type')}</label>
                <SelectBox
                  label={t('waybill_entry.shipment_type')}
                  control={control}
                  name='shipment_type'
                  options={shippingTypeOptions}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col md='4'>
              <FormGroup>
                <label>{t('waybill_entry.shipping_mode')}</label>
                <SelectBox
                  label={t('waybill_entry.shipping_mode')}
                  control={control}
                  name='shipping_mode'
                  options={shippingModeOptions}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col md='4'>
              <FormGroup>
                <label>Unit</label>
                <SelectBox
                  label='Unit'
                  control={control}
                  name='unit'
                  options={units}
                  rules={{ required: true }}
                  value={editData?.item?.unit}
                />
              </FormGroup>
            </Col>
            <Col md='4'>
              <FormGroup>
                <label>Limit</label>
                <SelectBox
                  label='Limit'
                  control={control}
                  name='limit'
                  options={limits}
                  rules={{ required: true }}
                  value={editData?.item?.limit}
                />
              </FormGroup>
            </Col>
            <Col md='4'>
              <FormGroup>
                <label>Value</label>
                <TextBox
                  type='number'
                  errors={errors?.value}
                  registerProps={register('value', { required: 'Value is required' })}
                />
              </FormGroup>
            </Col>

            <Col md='4'>
              <FormGroup>
                <label>Update Value Date</label>
                <DatePicker
                  onChange={(date) => {
                    setValue('update_value_date', date);
                  }}
                  value={dayjs(watch('update_value_date')).format('DD/MM/YYYY')}
                />
              </FormGroup>
            </Col>
            <Col md='4'>
              <FormGroup>
                <label>Basic Freight Value</label>
                <TextBox
                  type='number'
                  errors={errors?.basic_freight_value}
                  registerProps={register('basic_freight_value', { required: 'Basic Freight Value is required' })}
                />
              </FormGroup>
            </Col>
          </Row>

          <div className='text-center'>
            <Button className='my-4' color='success' type='button' onClick={() => onToggle(!modalOpen)}>
              {t('cancel')}
            </Button>
            <Button className='my-4' color='primary' type='submit'>
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
