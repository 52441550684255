import React from 'react';
import * as styles from './print_official_receipt.module.css';
import logo from 'assets/img/brand/company_logo.png';
import dayjs from 'dayjs';
import { getCache } from 'utli/cache';
import { moneyDecimalFormatter, customRound } from 'utli';

const user = getCache('user') !== 'undefined' ? JSON.parse(getCache('user')) : {} || null;

class PrintOfficialReceipt extends React.Component {
  render() {
    const data = this.props.data;
    const { branchInfo = {}, branchname = '-' } = user;
    const { address = '', township = {}, region = {} } = branchInfo;

    if (data)
      return (
        <div className={styles.PrintPage}>
          <div className={styles.LogoDiv}>
            <div className={styles.Logo}>
              <img src={logo} alt='Company Logo' width={200} />
            </div>
          </div>
          <h2 className={styles.PrintHead}>OFFICIAL RECEIPT</h2>
          <p>Ref no.: {data.reference_number}</p>
          {/* <p>Date: {new Date(data.createdAt).toLocaleDateString('en-GB')}</p> */}
          <p>Date: {dayjs(data.purchase_date).format('DD/MM/YYYY')}</p>

          <div className={styles.PrintDataWrapper}>
            <div className={styles.PrintData}>
              <span>Received From:</span>
              <span>{data.name}</span>
            </div>
            {data.currency === 'CNY' ? (
              <>
                <div className={styles.PrintData}>
                  <span>Billing Amount (CNY):</span>
                  <span>{moneyDecimalFormatter(customRound(data.paid_amount))}</span>
                </div>
                <div className={styles.PrintData}>
                  <span>Exchange Rate:</span>
                  <span>-</span>
                </div>

                <div className={styles.PrintData}>
                  <span>Received Amount (CNY):</span>
                  <span>{moneyDecimalFormatter(customRound(data.paid_in_currency))}</span>
                </div>
              </>
            ) : (
              <>
                <div className={styles.PrintData}>
                  <span>Billing Amount (CNY):</span>
                  <span>{moneyDecimalFormatter(customRound(data.paid_amount))}</span>
                </div>
                <div className={styles.PrintData}>
                  <span>Exchange Rate:</span>
                  <span>{moneyDecimalFormatter(customRound(data.exchangeRate))}</span>
                </div>

                <div className={styles.PrintData}>
                  <span>Received Amount (MMK):</span>
                  <span>{moneyDecimalFormatter(customRound(data.paid_in_currency))}</span>
                </div>
              </>
            )}

            {/* <div className={styles.PrintData}>
                        <span>Received Date:</span>
                        <span>{date_formatter(data.purchase_date)}</span>
                    </div> */}
            <div className={styles.PrintData}>
              <span>Payment For:</span>
              <span style={{ fontWeight: 'bold' }}>{data.waybill_number}</span>
            </div>
            <div className={styles.PrintData}>
              <span>Received In:</span>
              <span>{data.collectionType?.name}</span>
            </div>
          </div>
          <div className={styles.ReceivedPersonWrapper}>Remark: {data?.remark}</div>
          <div className={styles.ReceivedPersonWrapper}>
            Received: {data?.createdBy?.name} - {branchname}
          </div>
          <p>www.eflogisticsgroup.com</p>
          <div className={styles.BranchWrapper}>
            Rec branch add: {address}, {township?.name}, {region?.name},
          </div>
          <div className={styles.BranchWrapper}></div>
        </div>
      );

    return <></>;
  }
}

export default PrintOfficialReceipt;
