import React from 'react';
import { Bar } from 'react-chartjs-2';
import * as GraphService from 'services/graph';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import { t } from 'i18next';

function BarChart() {
  const [selectedDate, setSelectedDate] = React.useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  const [monthly_cost_data, setMonthlyCostData] = React.useState({});

  React.useEffect(() => {
    async function getData() {
      const monthlyCost = await GraphService.getMonthlyWaybillCount(selectedDate.month, selectedDate.year);
      setMonthlyCostData({
        labels: generatePreviousSixMonth(new Date(selectedDate.year, selectedDate.month, '01'), arr_month).reverse(),
        datasets: monthlyCost?.map((data) => ({
          label: data.label,
          data: data.data,
          fill: true,
          backgroundColor: [`${random_color(true)}`],
          borderColor: ['white'],
          borderWidth: 1,
        })),
      });
    }
    getData();
  }, [selectedDate]);

  function generatePreviousSixMonth(selectedDate, arr_month) {
    let i = selectedDate.getMonth();

    let resultArr = [];
    for (let k = 0; k < 6; k++) {
      i = i - 1;
      if (i >= 0) {
        resultArr.push(`${arr_month[i]} / ${selectedDate.getFullYear()}`);
      } else {
        resultArr.push(`${arr_month[12 + i]} / ${selectedDate.getFullYear() - 1}`);
      }
    }

    return resultArr;
  }

  const arr_month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  function random_color(isBg = false) {
    const x = Math.floor(Math.random() * 256);
    const y = 0 + Math.floor(Math.random() * 50);
    const z = 50 + Math.floor(Math.random() * 100);
    let color;
    if (isBg) {
      color = 'rgba(' + x + ',' + y + ',' + z + ', 0.5)';
    } else {
      color = 'rgba(' + x + ',' + y + ',' + z + ')';
    }

    return color;
  }

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Total cost',
      },
    },
  };

  return (
    <Card>
      <CardHeader className='bg-transparent'>
        <Row className='align-items-center'>
          <div className='col'>
            <h6 className='text-uppercase text-muted ls-1 mb-1'>{t('performance')}</h6>
            <h5 className='h3 mb-0'>{t('total_orders')}</h5>
          </div>
          <div className='col d-flex justify-content-end'>
            <div className='d-flex align-items-center justify-content-center pr-4'>
              <i
                className='fas fa-angle-left '
                style={{ cursor: 'pointer', fontSize: 22 }}
                onClick={() => {
                  let nextMonth = selectedDate.month - 6;
                  let year = selectedDate.year;
                  if (nextMonth < 0) {
                    nextMonth = 12 + nextMonth;
                    year -= 1;
                  }
                  setSelectedDate({
                    month: nextMonth,
                    year: year,
                  });
                }}
              ></i>
              <span className=' ml-3' style={{ fontSize: 14 }}>
                {monthly_cost_data.labels?.length >= 1 ? monthly_cost_data.labels[0].replace('/', '').trim() : ''}
              </span>
            </div>
            <div className='d-flex  align-items-center justify-content-center'>
              <span className=' mr-2' style={{ fontSize: 14 }}>
                {monthly_cost_data.labels?.length >= 2 ? monthly_cost_data.labels[5].replace('/', '').trim() : ''}
              </span>
              <i
                className='fas fa-angle-right '
                style={{ cursor: 'pointer', fontSize: 22 }}
                onClick={() => {
                  let nextMonth = selectedDate.month + 6;
                  let year = selectedDate.year;
                  if (nextMonth > 12) {
                    nextMonth = nextMonth - 12;
                    year += 1;
                  }
                  setSelectedDate({
                    month: nextMonth,
                    year: year,
                  });
                }}
              ></i>
            </div>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <div className='chart' style={{ height: '50vh' }}>
          <Bar data={monthly_cost_data} options={options} className='chart-canvas' id='chart-bars' />
        </div>
      </CardBody>
    </Card>
  );
}

export default BarChart;
