import { sendData, getData, sendForBlobData } from './apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';
export async function getAll(value, started_way) {
  try {
    let url = `way/get-all-refresh?started_way=${started_way}`;
    let response = await sendData(url, value, 'POST', false);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getWayCargo(id, query) {
  try {
    let response = await getData(`way/waybills/${id}?${new URLSearchParams(query).toString()}`);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function exportWayCargo(id) {
  return await sendForBlobData(
    'way/export-waybill',
    { way_id: id },
    {
      responseType: 'blob',
    }
  );
}

export async function remove_loaded_waybill(waybill, way, status) {
  return await sendData('way/remove_waybill', {
    waybill: waybill,
    way: way,
    status,
  });
}

export async function getOptions(started_way = false) {
  try {
    let response = await getData(`way/get-option?started_way=${started_way}`);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function save(values) {
  try {
    return await sendData('way/create', values);
  } catch (e) {
    NotificationManager.error(e.response.data.errors[0].msg);
    return false;
  }
}

export async function deleteData(id) {
  try {
    return await sendData('way/delete', { id: id });
  } catch (e) {
    NotificationManager.error(e.response.data.errors[0].msg);
    return false;
  }
}

export async function update(values) {
  try {
    return await sendData('way/update', values);
  } catch (e) {
    NotificationManager.error(e.response.data.errors[0].msg);
    return false;
  }
}

export async function confirm(id) {
  try {
    let response = await getData(`way/confirm/${id}`);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function end(id) {
  try {
    let response = await getData(`way/end-way/${id}`);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}
