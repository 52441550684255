import React from 'react';

import { Chart } from 'chart.js';

import { Container, Row, Col } from 'reactstrap';

import CardsHeader from 'components/Headers/CardsHeader.js';

import { chartOptions, parseOptions } from 'variables/charts.js';
import * as GraphService from 'services/graph';
import LineChart from 'components/LineChart';
import BarChart from 'components/BarChart';

function Hone() {
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const [count, setCount] = React.useState({});

  React.useEffect(() => {
    async function getData() {
      const countData = await GraphService.getCountData();
      const allTotalData = await GraphService.getAllTotal();
      countData.sale_total = 0;
      allTotalData.forEach((e) => (countData.sale_total += e.total));
      setCount(countData);
    }
    getData();
  }, [GraphService.getCountData]);

  return (
    <>
      <CardsHeader count={count} />
      <Container className='mt--6' fluid>
        <Row>
          <Col xl='8'>
            <LineChart />
          </Col>
          <Col xl='4'>
            <BarChart />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Hone;
