import React from 'react';
import { Button } from 'reactstrap';
import Table from 'components/Table';
// import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import CheckBox from 'components/Inputs/CheckBox';
function List({
  headers,
  data,
  onEdit,
  onActionEdit,
  onViewPermissionEdit,
  // title,
  buttons,
  onSelect,
  selectedData,
  // onInfoEdit,
}) {
  // const user = useSelector((state) => state.user.userInfo);

  // const isActive = user?.rolename === 'Super Administrator' || user?.rolename === 'System Admin';

  const { t } = useTranslation();

  return (
    <Table
      title={t('sidebar.system')}
      subtitle={t('system_role_management.user_role_management')}
      buttons={buttons}
      data={data}
      headers={headers.map((e) => (
        <th scope='col' key={`thead-${Math.random()}`}>
          {t(e)}
        </th>
      ))}
      rows={data.map((e, index) => (
        <tr key={`table-row-${Math.random()}`}>
          <td className='fixed-cell left'>
            <CheckBox padding='mr-0' onChange={() => onSelect(e)} checked={selectedData.find((s) => s._id === e._id)} />
          </td>
          <td>{index + 1}</td>
          <td>{e.rolename}</td>
          <td>{dayjs(e.createdAt).format('DD/MM/YYYY HH:mm')}</td>
          <td>{dayjs(e.updatedAt).format('DD/MM/YYYY HH:mm')}</td>

          <td>
            <Button size='sm' color='success' onClick={() => onEdit(e)}>
              <i className='fa fa-edit' />
            </Button>
          </td>
          <td>
            <Button size='sm' color='success' onClick={() => onActionEdit(e)}>
              <i className='fa fa-edit' />
            </Button>
          </td>
          <td>
            <Button size='sm' color='success' onClick={() => onViewPermissionEdit(e)}>
              <i className='fa fa-edit' />
            </Button>
          </td>
        </tr>
      ))}
    />
  );
}

export default List;
