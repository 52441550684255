import { getData, sendData } from './apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';
export async function getAll(value) {
  try {
    let response = await sendData('employee/get-all-refresh', value, 'POST', false);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getMarketingPerson() {
  try {
    let response = await getData('employee/get-marketing-person-option');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function save(values) {
  try {
    return await sendData('employee/create', values);
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}

export async function deleteData(_id) {
  try {
    let response = await sendData('employee/delete', { _id: _id });
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function update(values) {
  try {
    return await sendData('employee/update/' + values._id, values, 'PUT');
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}
