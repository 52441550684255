/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
import React from 'react';
import { useForm } from 'react-hook-form';
// import { Link } from 'react-router-dom';
import { Container, Button, Form, FormGroup, InputGroup, Spinner } from 'reactstrap';
import './login.scss';
import { connect, useSelector } from 'react-redux';
import { changePasswordAction } from 'store/actions';
import TextBox from 'components/Inputs/TextBox';
import CheckBox from 'components/Inputs/CheckBox';
import ValidatePassword from './validatePassword';
import { passwordValidateArr } from 'constant/password';

const Login = ({ changePasswordAction }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassowrd] = React.useState(false);
  const accessToken = useSelector((state) => state.user.accessToken);
  const password = register('password');
  const currentPassword = watch('password');
  const allowToChangePassword = passwordValidateArr(currentPassword).every((data) => data.isValid);

  const submitData = async (values) => {
    setLoading(true);
    await changePasswordAction(values);
    setLoading(false);
  };

  if (!accessToken) {
    window.location = '/';
  }

  return (
    <Container
      fluid
      className='main-bg'
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Container
        className='content-card'
        fluid
        style={{
          background: '#fff',
          borderRadius: 20,
          paddingLeft: 0,
        }}
      >
        <div className='d-flex'>
          <div style={{ flex: 1 }}>
            <img
              alt='...'
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '20px 0px 0px 20px',
              }}
              className='left-img'
              src={require('assets/img/brand/easyf-login-pic.jpg').default}
            />
          </div>

          <div style={{ flex: 1 }}>
            <div style={{ padding: '20px 120px' }}>
              <div className='d-flex justify-content-center'>
                <img
                  alt='...'
                  width='220'
                  height='80'
                  src={require('assets/img/brand/company_logo-removebg.png').default}
                  style={{
                    marginTop: 20,
                    marginBottom: 60,
                    borderRadius: '20px 0px 0px 20px',
                  }}
                />
              </div>
              <Form role='form' onSubmit={handleSubmit(submitData)}>
                <FormGroup>
                  <InputGroup className='input-group-merge input-group-alternative'>
                    <TextBox
                      placeholder='Password'
                      type={showPassword ? 'text' : 'password'}
                      registerProps={register('password', {
                        required: 'Password is required',
                      })}
                      onChange={(e) => {
                        password.onChange(e);
                      }}
                      prepend
                      icon={<i className='ni ni-lock-circle-open' />}
                      errors={errors.password}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className='input-group-merge input-group-alternative'>
                    <TextBox
                      placeholder='Confirm Password'
                      type={showPassword ? 'text' : 'password'}
                      registerProps={register('confirm_password', {
                        required: 'Confirm Password is required',
                        validate: (val) => {
                          if (watch('password') !== val) {
                            return 'Your passwords do no match';
                          }
                        },
                      })}
                      prepend
                      icon={<i className='ni ni-lock-circle-open' />}
                      errors={errors.confirm_password}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className='mt-2'>
                  <CheckBox
                    label='Show Password'
                    onChange={() => {
                      setShowPassowrd(!showPassword);
                    }}
                  />
                </FormGroup>

                <div style={{ marginBottom: '12px' }}>
                  {passwordValidateArr(currentPassword)?.map((data) => {
                    return <ValidatePassword content={data.content} isValid={data.isValid} />;
                  })}
                </div>

                <Button
                  className='mt-1 mb-3 btn-primary'
                  type='submit'
                  style={{
                    width: '100%',
                  }}
                  disabled={!allowToChangePassword}
                >
                  {loading && <Spinner size='sm' role='status' className='mr-2'></Spinner>}
                  Change Password
                </Button>
              </Form>
              <a href='/auth/login'>
                <div className='text-center text-muted mb-4'>
                  <small>Back to Sign In</small>
                </div>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default connect(null, { changePasswordAction })(Login);
