import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import OrderDetailContent from './content';
import './index.scss';
import { t } from 'i18next';
function DetailDialog({ data, modalOpen, onToggle, customItemName = '', customPaymentMethod = '' }) {
  return (
    <Modal isOpen={modalOpen} size='lg' backdrop={true} style={{ top: '1%' }}>
      <div className=' modal-header' style={{ background: '#e9e9ef' }}>
        <h5 className=' modal-title'>{t('waybill_entry.order_detail')}</h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <ModalBody className='order-detail-dialog'>
        <OrderDetailContent data={data} customItemName={customItemName} customPaymentMethod={customPaymentMethod} />
      </ModalBody>
    </Modal>
  );
}
export default DetailDialog;
