import {
  SET_ORDERS,
  SET_WAYBILLS,
  SET_SIGN_CONFIRMS,
  SET_SIGN_LIST,
  SET_FINANCE_WAYBILLS,
  SET_TRANSCATIONS,
  SET_ADDITIONAL_STATUSES,
  SET_WAYBILL_LOGS,
} from 'store/types/waybill';

const initialState = {
  orders: [],
  waybills: [],
  signConfirms: [],
  signs: [],
  transcations: [],
  waybill_additional_status: [],
  waybill_logs: [],
};

const waybill = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };

    case SET_WAYBILLS:
      return {
        ...state,
        waybills: action.payload,
      };

    case SET_SIGN_CONFIRMS:
      return {
        ...state,
        signConfirms: action.payload,
      };
    case SET_SIGN_LIST:
      return {
        ...state,
        signs: action.payload,
      };
    case SET_FINANCE_WAYBILLS:
      return {
        ...state,
        waybill_finances: action.payload,
      };
    case SET_TRANSCATIONS:
      return {
        ...state,
        transcations: action.payload,
      };
    case SET_ADDITIONAL_STATUSES:
      return {
        ...state,
        waybill_additional_status: action.payload,
      };
    case SET_WAYBILL_LOGS:
      return {
        ...state,
        waybill_logs: action.payload,
      };

    default:
      return state;
  }
};

export default waybill;
