import React from 'react';
import { InputGroup } from 'reactstrap';
import { moneyDecimalFormatter } from 'utli';
function NumberInput({
  // registerProps,
  onChange,
  value,
  error,
  // defaultValue = null,
  readOnly,
  validation = {},
  ...props
}) {
  //const [text, setText] = React.useState(value ? value : defaultValue)

  return (
    <>
      <InputGroup className='custom-tb input-group-merge input-group-alternative'>
        {!readOnly ? (
          <input
            className='form-control'
            autoComplete={'off'}
            onChange={(e) => {
              onChange(e.target.value.replace(',', ''));
            }}
            value={moneyDecimalFormatter(value)}
            {...props}
          />
        ) : (
          <input className='form-control' autoComplete={'off'} value={moneyDecimalFormatter(value)} {...props} />
        )}
      </InputGroup>
      {validation.required && value?.length < 0 && (
        <div className='error-containe' style={{ marginTop: 5 }}>
          <span className='text-danger'>{error}</span>
        </div>
      )}
    </>
  );
}

export default NumberInput;
