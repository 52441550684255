import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import Table from 'components/Table';
import { t } from 'i18next';
function List({ headers, data, title, load, onPageChange, buttons, extraContent, onDeleteCargo, onEditCargo }) {
  return (
    <div>
      <Table
        data={data}
        title={title}
        extraContent={extraContent}
        subtitle=''
        buttons={buttons}
        onPageChange={onPageChange}
        headers={headers.map((e) => (
          <th style={{ width: '30px' }} key={`thead-${Math.random()}`}>
            {t(e)}
          </th>
        ))}
        rows={data?.docs?.map((e, index) => (
          <tr key={`table-row-${index}-${Math.random()}`}>
            <td style={{ width: '30px' }}>{index + 1}</td>
            <td style={{ width: '30px' }}>{e.waybillnumber}</td>
            <td style={{ width: '30px' }}>{e.waybill?.address_code}</td>
            <td style={{ width: '30px' }}>{e.waybill?.receiver?.name}</td>
            <td style={{ width: '30px' }}>{e.waybill?.item?.itemname}</td>
            <td style={{ width: '30px' }}>{e.qty}</td>
            <td style={{ width: '30px' }}>{e.weight}</td>
            <td style={{ width: '30px' }}>{e.volume}</td>
            <td style={{ width: '30px' }}>{e.destination}</td>
            <td style={{ width: '30px' }}>{e.waybill?.remark}</td>

            <td>
              {load && (
                <>
                  <Button size='sm' onClick={() => onEditCargo(e)}>
                    Edit
                  </Button>
                  <Button size='sm' color='danger' onClick={() => onDeleteCargo(e)}>
                    Delete
                  </Button>
                </>
              )}
            </td>
          </tr>
        ))}
        footer={
          <>
            <td>-</td>
            <td>{t('waybill_entry.total')}</td>
            <td colSpan={3}></td>
            <td>{data?.totalSum?.qty?.toFixed(2)}</td>
            <td>{data?.totalSum?.weight?.toFixed(2)}</td>
            <td>{data?.totalSum?.volume?.toFixed(2)}</td>
          </>
        }
      />
    </div>
  );
}

export default List;
