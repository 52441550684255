import React from 'react';
import { Modal, ModalBody, Row, Col, Button, Form, FormGroup } from 'reactstrap';
import { useForm } from 'react-hook-form';
import TextBox from 'components/Inputs/TextBox';
import DatePicker from 'components/Inputs/DatePicker';
import { HOST } from 'services/apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import './sign-modal.scss';
import { t } from 'i18next';
import { getTracking } from 'services/waybill';
import * as SignAction from 'store/actions/sign';
import { add_sign_query, update_sign_query, remove_sign_query_image } from 'services/sign';
import { useDispatch } from 'react-redux';
export default function SignModal({ modalOpen, onToggle, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm();

  const [actionLoading, setActionLoading] = React.useState(false);
  const [networkImage, setNetworkImage] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (data) {
      setValue('waybill_number', data.waybill_number);
      setValue('qty', data.qty);
      setValue('receiver_name', data.receiver_name);
      setValue('container', data.container);
      setValue('receiver_phone', data.receiver_phone);
      setValue('remark', data.remark);
      setValue('date', data.date);
      setNetworkImage(data?.sign_info?.imageUpload);
    } else {
      reset();
      setNetworkImage(null);
      setFile(null);
      setValue('date', new Date());
    }
  }, [data, reset, setValue]);

  const clearNetworkImage = async () => {
    await remove_sign_query_image({ _id: data?._id });
    setTimeout(() => dispatch(SignAction.getAllSigned({}, {})), 1000);
    setNetworkImage(null);
  };

  const submitData = async (values) => {
    setActionLoading(true);
    const waybillData = await getTracking(values.waybill_number.trim());
    if (!waybillData) {
      NotificationManager.error('Waybill not found');
      setActionLoading(false);
      return;
    }

    if (waybillData.currentStatus === 'ORDERCREATED') {
      NotificationManager.error('Waybill not found');
      setActionLoading(false);
      return;
    }

    const { waybill_info } = waybillData;

    if (waybill_info.item.qty < Number(values.qty)) {
      // eslint-disable-next-line prettier/prettier, quotes
      NotificationManager.error("Qty is larger than waybill's qty");
      setActionLoading(false);
      return;
    }

    values.waybill_number = values.waybill_number.trim();

    const percentage = (Number(values.qty) / waybill_info.item.qty) * 100;
    const weight = Math.ceil((waybill_info.item.weight / 100) * percentage);
    const volume = ((waybill_info.item.volume / 100) * percentage).toFixed(2);

    const formData = new FormData();
    if (file) formData.append('fileupload', file);
    formData.append('waybill_number', values.waybill_number);
    formData.append('receiver_name', values.receiver_name);
    formData.append('receiver_phone', values.receiver_phone);
    formData.append('remark', values.remark);
    formData.append('container', values.container);
    formData.append('qty', values.qty);
    formData.append('weight', weight);
    formData.append('volume', volume);
    formData.append('date', values.date);
    formData.append('address_code', waybill_info.address_code);

    if (data) {
      formData.append('_id', data?._id);
      await update_sign_query(formData);
    } else {
      await add_sign_query(formData);
    }

    setActionLoading(false);
    setTimeout(() => dispatch(SignAction.getAllSigned({}, {})), 1000);

    reset();
    onToggle();
  };

  return (
    <Modal isOpen={modalOpen} style={{ top: '5%', maxWidth: '40%', minWidth: 450 }} backdrop={true}>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitData)}>
          <Row>
            <Col md='6'>
              <FormGroup>
                <label>{t('waybill_entry.waybill_no')}</label>
                <TextBox
                  className='form-control'
                  placeholder={t('waybill_entry.waybill_no')}
                  type='text'
                  registerProps={register('waybill_number', {
                    required: `${t('waybill_entry.waybill_no')} ${t('is_required')}`,
                  })}
                  disabled={data}
                  errors={errors.name}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup>
                <label>{t('waybill_entry.qty')}</label>
                <TextBox
                  type='number'
                  className='form-control'
                  placeholder={t('waybill_entry.qty')}
                  registerProps={register('qty', {
                    required: `${t('waybill_entry.qty')} ${t('is_required')}`,
                  })}
                  errors={errors.qty}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup>
                <label>{t('sign.receiver')}</label>
                <TextBox
                  className='form-control'
                  placeholder={t('sign.receiver')}
                  type='text'
                  registerProps={register('receiver_name', {
                    required: `${t('sign.receiver')} ${t('is_required')}`,
                  })}
                  errors={errors.receiver_name}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup>
                <label>{t('phonenumber')}</label>
                <TextBox
                  className='form-control'
                  placeholder={t('phonenumber')}
                  type='text'
                  registerProps={register('receiver_phone', {
                    required: `${t('phonenumber')} ${t('is_required')}`,
                  })}
                  errors={errors.receiver_phone}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup>
                <label>{t('cargo_loading.container')}</label>
                <TextBox
                  className='form-control'
                  placeholder={t('cargo_loading.container')}
                  type='text'
                  registerProps={register('container', {
                    required: `${t('cargo_loading.container')} ${t('is_required')}`,
                  })}
                  errors={errors.container}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup>
                <label>{t('date')}</label>
                <DatePicker
                  onChange={(date) => {
                    setValue('date', date);
                  }}
                  initialValue={watch('date')}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup>
                <label>{t('remark')}</label>
                <TextBox
                  className='form-control'
                  placeholder={t('remark')}
                  type='text'
                  registerProps={register('remark', {
                    required: `${t('remark')} ${t('is_required')}`,
                  })}
                  errors={errors.remark}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup>
                <label>{t('sign.attachment')}</label>
                <br />
                <input
                  type='file'
                  onChange={(e) => {
                    if (e.target.files[0].type) setFile(e.target.files[0]);
                  }}
                  accept='.png, .jpg, .jpeg'
                  multiple
                  name='attachment'
                />
              </FormGroup>
              <div>
                {networkImage && !file && (
                  <div className='preview-image network'>
                    <img src={`${HOST}/static/${networkImage}`} />
                    <span onClick={() => clearNetworkImage()}>X</span>
                  </div>
                )}
                {file && (
                  <div className='preview-image'>
                    <img src={URL.createObjectURL(file)} />
                    <span onClick={() => setFile(null)}>X</span>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <div className='text-center'>
            <Button className='my-4' color='success' type='button' onClick={() => onToggle(!modalOpen)}>
              {t('cancel')}
            </Button>
            <Button className='my-4' color='primary' type='submit' disabled={actionLoading}>
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
