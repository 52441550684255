import { t } from 'i18next';
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from 'reactstrap';
import PrintOfficialReceipt from './printOfficialReceipt';

const PrintTranscation = ({ selectedData }) => {
  const printRef = useRef();

  return (
    <>
      <ReactToPrint
        trigger={() => {
          return (
            <Button style={{ width: 100 }} size='sm' disabled={selectedData.length !== 1}>
              <i className='fa fa-file-archive mr-2' />
              {t('print')}
            </Button>
          );
        }}
        content={() => printRef.current}
      />
      <div id='print' style={{ display: 'none' }}>
        <PrintOfficialReceipt ref={printRef} data={selectedData[0]} />
      </div>
    </>
  );
};

export default PrintTranscation;
