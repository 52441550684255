import React from 'react';
import { Modal, ModalBody, Button, FormGroup, Form, Row, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import TextBox from 'components/Inputs/TextBox';
import SelectBox from 'components/SelectBox';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
export default function AddDialog({ modalOpen, onToggle, title, onSubmit, editData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm();

  const submitData = async (values) => {
    reset();
    onSubmit(values);
  };

  const branches = useSelector((state) => state.information.branches);
  let branchOptions = [];
  if (branches.map) {
    branchOptions = branches?.map((e) => {
      return {
        label: e.label,
        value: e._id,
      };
    });
  }

  const shipping_modes = useSelector((state) => state.other_maintenance.shipping_modes);
  const shippingModeOptions = shipping_modes?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  React.useEffect(() => {
    if (editData) {
      setValue('departure', editData?.departure?._id);
      setValue('destination', editData?.destination?._id);
      setValue('shipping_mode', editData?.shipping_mode?._id);
      setValue('remark', editData?.remark);
    } else {
      reset();
    }
  }, [editData]);

  return (
    <Modal isOpen={modalOpen} style={{ top: '25%' }} backdrop={true}>
      <div className=' modal-header'>
        <h5 className=' modal-title'>
          {title} {editData?.name}
        </h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitData)}>
          <Row>
            <Col md='6'>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('waybill_entry.departure')}</label>
                <SelectBox
                  label={t('waybill_entry.departure')}
                  control={control}
                  name='departure'
                  options={branchOptions}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup className={classnames('mb-3')}>
                <label>{t('waybill_entry.destination')}</label>
                <SelectBox
                  label={t('waybill_entry.destination')}
                  control={control}
                  name='destination'
                  options={branchOptions}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup>
                <label>{t('waybill_entry.shipping_mode')}</label>
                <SelectBox
                  label={t('waybill_entry.shipping_mode')}
                  control={control}
                  name='shipping_mode'
                  options={shippingModeOptions}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <label>{t('duration')}</label>
              <TextBox
                type='number'
                className='form-control mr-2'
                registerProps={register('remark', {
                  required: `${t('duration')} ${t('is_required')}`,
                })}
                errors={errors?.remark}
              />
            </Col>
          </Row>

          <div className='text-center'>
            <Button className='my-4' color='success' type='button' onClick={() => onToggle(!modalOpen)}>
              {t('cancel')}
            </Button>
            <Button className='my-4' color='primary' type='submit'>
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
