import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddNewButton from 'components/ActionButtons/addNewButton';
import { Button } from 'reactstrap';
import List from './list';
import AddDialog from './addDialog';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import FilterBox from './filter';
import * as AddressBookAction from 'store/actions/addressBook';
import * as EmployeeAction from 'store/actions/employee';
import { getCountryOption } from 'store/actions/location';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { headers } from './headers';
import { t } from 'i18next';
import useViewPermission from 'hooks/useViewPermission';

function AddressBook() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState(null);
  const actionLoading = useSelector((state) => state.loading.actionLoading);
  const { isOwn, isBranch } = useViewPermission('address_book');

  const dispatch = useDispatch();
  const router = useHistory();

  const addressBooks = useSelector((state) => state.information.addressBooks);
  const countries = useSelector((state) => state.location.countries);

  React.useEffect(() => {
    dispatch(EmployeeAction.getMarketingPerson());
    dispatch(getCountryOption());
  }, [EmployeeAction.getMarketingPerson, getCountryOption, dispatch]);

  React.useEffect(() => {
    loadData();
  }, [AddressBookAction.getAll, router.location.search]);

  const loadData = (value = null) => {
    setSearchValue(value);
    let query = { limit: 10, page: 1 };
    if (router.location.search) query = queryString.parse(router.location.search);

    if (isOwn()) query.isOwn = !!isOwn();
    if (isBranch()) query.isBranch = !!isBranch();

    dispatch(AddressBookAction.getAll(query, value ? value : searchValue ? searchValue : {}));
  };

  return (
    <>
      <List
        headers={headers}
        data={addressBooks}
        onEdit={(data) => {
          setModalOpen(true);
          setEditData(data);
        }}
        onDelete={(data) => {
          setShowDelete(true);
          setEditData(data);
        }}
        filter={<FilterBox onSearch={(value) => loadData(value)} />}
        buttons={
          <>
            <AddNewButton
              type='unknown'
              onClick={() => {
                setModalOpen(true);
                setEditData(null);
              }}
            />
            <Button
              style={{ width: 100 }}
              size='sm'
              disabled={actionLoading}
              onClick={() => {
                dispatch(AddressBookAction.exportData(searchValue ? searchValue : {}));
              }}
            >
              {actionLoading ? <i className='fa fa-spinner mr-2' /> : <i className='fa fa-file-excel mr-2' />}

              {t('export')}
            </Button>
            <Button size='sm' onClick={() => loadData({})}>
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
          </>
        }
      />
      <AddDialog
        options={{ countries: countries }}
        title={editData ? `${t('edit')} ${t('address_book.title')}` : `${t('add')} ${t('address_book.title')}`}
        editData={editData}
        modalOpen={modalOpen}
        onToggle={(open) => setModalOpen(open)}
        onSubmit={(values) => {
          setModalOpen(false);
          if (editData) {
            values.original_address_id = editData._id;
          }
          dispatch(AddressBookAction.save(values));
          setTimeout(() => loadData({}), 1000);
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`${t('delete')} ${t('address_book')}`}
        body={`${t('confirm_delete')} ${editData?.code}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(AddressBookAction.deleteData(editData?._id));
          setTimeout(() => loadData({}), 1000);
        }}
      />
    </>
  );
}

export default AddressBook;
