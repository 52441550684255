import React from 'react';
import { Modal, ModalBody, Button, FormGroup, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import TextBox from 'components/Inputs/TextBox';

export default function AddDialog({ modalOpen, onToggle, onSubmit }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const submitData = async (values) => {
    reset();

    onSubmit(values);
  };

  return (
    <Modal isOpen={modalOpen} style={{ top: '25%' }} backdrop={true}>
      <div className=' modal-header'>
        <h5 className=' modal-title'>New Waybill Status</h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitData)}>
          <FormGroup className={classnames('mb-3')}>
            <label>Waybill number</label>
            <TextBox
              className='form-control'
              placeholder='Waybill number'
              type='text'
              registerProps={register('waybill_number', {
                required: 'Waybill number is required',
              })}
              errors={errors.waybill_number}
            />
          </FormGroup>
          <FormGroup className={classnames('mb-3')}>
            <label>Content</label>

            <TextBox
              className='form-control'
              placeholder='Content'
              type='textarea'
              registerProps={register('content', {
                required: 'Content is required',
              })}
              errors={errors.content}
            />
          </FormGroup>

          <div className='text-center' style={{ marginTop: 100 }}>
            <Button className='my-4' color='success' type='button' onClick={() => onToggle(!modalOpen)}>
              Cancel
            </Button>
            <Button
              className='my-4'
              color='primary'
              type='submit'
              // disabled={loading}
            >
              {/* {loading && (
                <Spinner size="sm" role="status" className="mr-2"></Spinner>
              )} */}
              Save
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
