import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddNewButton from 'components/ActionButtons/addNewButton';
import { Button } from 'reactstrap';
import List from './list';
// import * as EstimatedTimeAction from 'store/actions/estimatedTime';
import * as ShippingModeAction from 'store/actions/shipping_mode';
import * as InformationAction from 'store/actions/information';
import * as ItemTypeAction from 'store/actions/item_type';
import * as ShippingTypeAction from 'store/actions/shipping_type';
import * as BasicFreightValueAction from 'store/actions/basicFreightValueAction';
import { t } from 'i18next';
import AddDialog from './addDialog';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';

function CustomClearence() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);

  const basicFreightValues = useSelector((state) => state.information.basicFreightValues);

  const dispatch = useDispatch();

  const headers = [
    'no',
    'waybill_entry.departure',
    'waybill_entry.destination',
    'waybill_entry.item_type',
    'waybill_entry.cargo_type',
    'unit',
    'limit_value',
    'waybill_entry.shipment_type',
    'waybill_entry.shipping_mode',
    'Update Value Date',
    'Basic Freight Value',
  ];
  React.useEffect(() => {
    dispatch(ShippingModeAction.getAll({}));
    dispatch(InformationAction.getBranch());
    dispatch(ItemTypeAction.getAll({}));
    dispatch(ShippingTypeAction.getAll({}));
    dispatch(BasicFreightValueAction.getAll({}));
  }, [dispatch]);

  return (
    <>
      <List
        headers={headers}
        data={basicFreightValues}
        onEdit={(data) => {
          setModalOpen(true);
          setEditData(data);
        }}
        onDelete={(data) => {
          setShowDelete(true);
          setEditData(data);
        }}
        buttons={
          <>
            <AddNewButton
              type='custom_clearnece'
              onClick={() => {
                setEditData(null);
                setModalOpen(true);
              }}
            />
            <Button style={{ width: 100 }} size='sm' onClick={() => dispatch(BasicFreightValueAction.getAll({}, true))}>
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
          </>
        }
      />
      <AddDialog
        title={editData ? `${t('edit')} Basic Freight Value` : `${t('add')} Basic Freight Value`}
        editData={editData}
        modalOpen={modalOpen}
        onToggle={(open) => setModalOpen(open)}
        onSubmit={(values) => {
          setModalOpen(false);
          if (editData) dispatch(BasicFreightValueAction.update({ ...values, _id: editData?._id }));
          else dispatch(BasicFreightValueAction.save(values));
          setTimeout(() => dispatch(BasicFreightValueAction.getAll({}, true)), 1000);
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`${t('delete')} ${t('sidebar.basic_reight_value')}`}
        body={`${t('confirm_delete')} ?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(BasicFreightValueAction.deleteData(editData?._id));
          setTimeout(() => dispatch(BasicFreightValueAction.getAll()), 1000);
        }}
      />
    </>
  );
}

export default CustomClearence;
