import React from 'react';
import { Button } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import ExportOrderForm from './exportOrderForm';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { t } from 'i18next';

function OrderFormExport({ selectedData }) {
  const exportRef = React.useRef();
  const [showPDF_Download, setShowPDF_Download] = React.useState(false);
  return (
    <>
      {!showPDF_Download ? (
        <Button
          style={{ width: 100 }}
          size='sm'
          disabled={selectedData.length === 0}
          onClick={() => {
            if (selectedData.length === 1) {
              setShowPDF_Download(true);
              setTimeout(() => {
                if (exportRef?.current) {
                  exportRef.current.click();
                }
              }, 1000);

              setTimeout(() => {
                setShowPDF_Download(false);
              }, 1000);
            } else {
              NotificationManager.error('Please choose only one order');
            }
          }}
        >
          <i className='fa fa-file-pdf mr-2' />
          {t('sidebar.order')}
        </Button>
      ) : (
        <PDFDownloadLink
          document={<ExportOrderForm data={selectedData[0]} />}
          fileName={`${selectedData[0].waybillnumber}.pdf`}
        >
          {({ loading, error }) => {
            if (error) return 'error';

            if (!error && loading) return '...';

            if (!error && !loading)
              return (
                <button type='button' style={{ width: 100 }} size='sm' ref={exportRef}>
                  <i className='fa fa-file-pdf mr-2' />
                  {t('sidebar.order')}
                </button>
              );
          }}
        </PDFDownloadLink>
      )}
    </>
  );
}
export default OrderFormExport;
