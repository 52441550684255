import React from 'react';
import { t } from 'i18next';
import dayjs from 'dayjs';
import Table from 'components/Table';
function List({ headers, data }) {
  const getWayBilLogStatus = (status) => {
    switch (status) {
      case 'ORDERCREATED':
        return 'Order created';
      case 'ORDEREDITED':
        return 'Order edited';
      case 'ORDERDELETED':
        return 'Order deleted';
      case 'WAYBILLCREATED':
        return 'Waybill created';
      case 'WAYBILLEDITED':
        return 'Waybill edited';
      case 'WAYBILLDELETED':
        return 'Waybill deleted';
      default:
        return '';
    }
  };

  return (
    <Table
      showTotal={false}
      showTitle={false}
      headers={headers.map((e) => (
        <th scope='col' key={`thead-${Math.random()}`}>
          {t(e)}
        </th>
      ))}
      data={data}
      rows={data.map((e) => (
        <tr key={`table-row-${Math.random()}`}>
          <td>{getWayBilLogStatus(e.status)}</td>
          <td>{e.userName}</td>
          <td>{dayjs(e.updatedAt).format('DD/MM/YYYY HH:mm')}</td>
        </tr>
      ))}
    />
  );
}

export default List;
