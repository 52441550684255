import EntryGrid from 'components/Inputs/EntryGrid';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Row, Col, FormGroup, Button } from 'reactstrap';
import TextBox from 'components/Inputs/TextBox';
import SelectBox from 'components/SelectBox';
import { getDistriceOption } from 'services/locationService';

import { t } from 'i18next';
import useActionPermission from 'hooks/useActionPermission';
function EntryForm({ disabled, onSubmit, options, editData, onCancel, addOpen, onDelete, placeholderBranch }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();

  const submitData = async (values) => {
    reset();
    onSubmit(values);
  };

  const { canDelete } = useActionPermission();
  const [regions, setRegionOption] = React.useState([]);
  const [districts, setDistrictOption] = React.useState([]);
  const [townships, setTownshipOption] = React.useState([]);
  const countryOptions = options.countries?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  const regionOptions = regions?.map((e) => {
    return {
      label: e.name,
      value: e.value,
    };
  });

  const districtOptions = districts?.map((e) => {
    return {
      label: e.label,
      value: e.value,
    };
  });

  const townshipOptions = townships?.map((e) => {
    return {
      label: e.name,
      value: e.value,
    };
  });

  React.useEffect(() => {
    async function initData() {
      if (editData && !addOpen) {
        setValue('name', editData?.name);
        setValue('code', editData?.branchCode);
        setValue('phonenumber', editData?.phonenumber);
        setValue('supervisor', editData?.supervisor);
        setValue('linenumber', editData?.linenumber);
        setValue('country', editData?.country?._id);

        const result = options.countries?.find((c) => c._id === editData?.country?._id);
        setRegionOption(result?.region || []);
        setValue('region', editData?.region?._id);

        const response = await getDistriceOption(editData?.region?._id);
        setDistrictOption(response);

        setValue('district', editData?.district._id);

        const result2 = response?.find((c) => c._id === editData?.distric?._id);
        setTownshipOption(result2?.township || []);

        setValue('township', editData?.township._id);
        setValue('address', editData?.address);
        setValue('remark', editData?.remark);
      } else if (addOpen || !editData) {
        reset();
      }
    }
    initData();
  }, [editData, addOpen]);

  return (
    <EntryGrid
      title={
        <div className='pt-2 pb-1'>
          <h3>
            {t('information_branch.branch_set_up_and_update')} : {placeholderBranch.name}
          </h3>
        </div>
      }
    >
      <Form onSubmit={handleSubmit(submitData)}>
        <Row>
          <Col md={6} xs={12} className='mb-1'>
            <FormGroup>
              <label>{t('name')}</label>
              <TextBox
                className='form-control'
                placeholder={t('name')}
                type='text'
                registerProps={register('name', {
                  required: `${t('name')} ${t('is_required')}`,
                })}
                errors={errors.name}
                disabled={disabled}
              />
            </FormGroup>
          </Col>
          <Col md={6} xs={12} className='mb-1'>
            <FormGroup>
              <label>{t('code')}</label>
              <TextBox
                className='form-control'
                placeholder={t('code')}
                type='text'
                registerProps={register('code', {
                  required: `${t('code')} ${t('is_required')}`,
                })}
                errors={errors.code}
                disabled={disabled}
              />
            </FormGroup>
          </Col>
          <Col md={6} xs={12} className='mb-1'>
            <FormGroup>
              <label>{t('information_branch.supervisor_name')}</label>
              <TextBox
                className='form-control'
                placeholder={t('information_branch.supervisor_name')}
                type='text'
                registerProps={register('supervisor', {
                  required: `${t('information_branch.supervisor_name')} ${t('is_required')}`,
                })}
                errors={errors.supervisor}
                disabled={disabled}
              />
            </FormGroup>
          </Col>
          <Col md={6} xs={12} className='mb-1'>
            <FormGroup>
              <label>{t('phonenumber')}</label>
              <TextBox
                className='form-control'
                placeholder={t('phonenumber')}
                type='text'
                registerProps={register('phonenumber', {
                  required: `${t('phonenumber')} ${t('is_required')}`,
                })}
                errors={errors.phonenumber}
                disabled={disabled}
              />
            </FormGroup>
          </Col>
          <Col md={6} xs={12} className='mb-1'>
            <FormGroup>
              <label>{t('information_branch.line_number')}</label>
              <TextBox
                className='form-control'
                placeholder={t('information_branch.line_number')}
                type='text'
                registerProps={register('linenumber')}
                errors={errors.linenumber}
                disabled={disabled}
              />
            </FormGroup>
          </Col>
          <Col md={6} xs={12}>
            <FormGroup>
              <label>{t('country')}</label>
              <SelectBox
                control={control}
                name='country'
                label={t('country')}
                options={countryOptions}
                value={editData?.country?._id}
                rules={{ required: true }}
                onValueChange={(value) => {
                  if (!value) setRegionOption([]);
                  else {
                    const result = options.countries?.find((c) => c._id === value);
                    setRegionOption(result?.region || []);
                  }
                  setValue('region', '');
                }}
                disabled={disabled}
              />
            </FormGroup>
          </Col>
          <Col md={6} xs={12}>
            <FormGroup>
              <label>{t('region')}</label>
              <SelectBox
                control={control}
                name='region'
                label={t('region')}
                options={regionOptions}
                value={editData?.region?._id}
                rules={{ required: true }}
                onValueChange={async (value) => {
                  if (!value) setDistrictOption([]);
                  else {
                    const response = await getDistriceOption(value);
                    setDistrictOption(response);
                  }
                  setValue('district', '');
                }}
                disabled={disabled}
              />
            </FormGroup>
          </Col>
          <Col md={6} xs={12}>
            <FormGroup>
              <label>{t('district')}</label>
              <SelectBox
                control={control}
                name='district'
                label={t('district')}
                options={districtOptions}
                value={editData?.district?._id}
                rules={{ required: true }}
                onValueChange={(value) => {
                  if (!value) setTownshipOption([]);
                  else {
                    const result = districts?.find((c) => c._id === value);
                    setTownshipOption(result?.township || []);
                  }
                  setValue('township', '');
                }}
                disabled={disabled}
              />
            </FormGroup>
          </Col>
          <Col md={6} xs={12}>
            <FormGroup>
              <label>{t('township')}</label>
              <SelectBox
                control={control}
                name='township'
                label={t('township')}
                options={townshipOptions}
                value={editData?.township?._id}
                rules={{ required: true }}
                disabled={disabled}
              />
            </FormGroup>
          </Col>
          <Col md={6} xs={12}>
            <FormGroup>
              <label>{t('remark')}</label>
              <TextBox
                className='form-control'
                ßß
                placeholder={t('remark')}
                type='text'
                registerProps={register('remark', {
                  required: `${t('remark')} ${t('is_required')}`,
                })}
                errors={errors.remark}
                disabled={disabled}
              />
            </FormGroup>
          </Col>
          <Col md={6} xs={12}>
            <FormGroup>
              <label>{t('address')}</label>
              <TextBox
                className='form-control'
                placeholder={t('address')}
                type='textarea'
                registerProps={register('address', {
                  required: `${t('address')} ${t('is_required')}`,
                })}
                errors={errors.address}
                disabled={disabled}
              />
            </FormGroup>
          </Col>
        </Row>
        <div className='text-center'>
          <Button className='my-4' color='success' onClick={onCancel} type='button'>
            {t('cancel')}
          </Button>
          {editData && canDelete() && (
            <Button disabled={disabled} className='my-4' color='danger' type='button' onClick={onDelete}>
              {t('delete')}
            </Button>
          )}
          <Button disabled={disabled} className='my-4' color='primary' type='submit'>
            {t('submit')}
          </Button>
        </div>
      </Form>
    </EntryGrid>
  );
}

export default EntryForm;
