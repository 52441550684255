// nodejs library that concatenates classes
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { FONT_CN, FONT_EN, LANGUAGES } from '../../constant/Language';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import CropperModal from 'components/cropperModal';
import { dataUrlToFile } from 'utli/fileConverter';
import './AdminNavbar.scss';
// reactstrap components
import {
  Collapse,
  UncontrolledDropdown,
  Navbar,
  Nav,
  Container,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  NavItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  InputGroup,
  Dropdown,
} from 'reactstrap';
import { logOutAction } from 'store/actions';
import * as UserAction from 'store/actions/auth';
import { getUserProfile, updatePassword } from 'services/authService';
import TextBox from 'components/Inputs/TextBox';
import { useForm } from 'react-hook-form';
import { passwordValidateArr } from 'constant/password';
import ValidatePassword from 'pages/validatePassword';

// eslint-disable-next-line no-undef
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + '/static/';

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  const user = useSelector((state) => state.user.userInfo);
  const { t, i18n } = useTranslation();
  const [modal, setModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const password = register('password');
  const currentPassword = watch('password');
  const allowToChangePassword = passwordValidateArr(currentPassword).every((data) => data.isValid);

  const [image, setImage] = useState(null);
  const [openCropper, setOpenCropper] = useState(false);
  const [cropImage, setCropImage] = useState(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const toggleModal = () => {
    setModal(!modal);
  };

  const togglePasswordModal = () => {
    setPasswordModal(!passwordModal);
  };

  const handleUpload = async () => {
    const imageFile = await dataUrlToFile(cropImage);
    dispatch(UserAction.updateProfileAction(imageFile, user?._id));
    // Reset the selected file and close the modal
    setPreviewUrl(null);
    setCropImage(null);
    toggleModal();
  };

  const fetchImage = useCallback(async (id) => {
    const imageResponse = await getUserProfile(id);
    const imgUrl = `${apiEndpoint}${imageResponse.data}`;
    setPreviewUrl(imgUrl);
  }, []);

  useEffect(() => {
    fetchImage(user?._id);

    return () => URL.revokeObjectURL(previewUrl);
  }, [fetchImage]);

  const changeLanguage = (lang) => {
    try {
      if ([FONT_EN, FONT_CN].includes(lang)) {
        i18n.changeLanguage(lang);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitData = async (data) => {
    try {
      await updatePassword(data?.password, user?._id);
    } catch (error) {
      console.log(error);
    } finally {
      setValue('password', '');
      setValue('confirm_password', '');

      setPasswordModal(false);
    }
  };

  return (
    <>
      <Navbar className='navbar-top navbar-dark bg-primary navbar-expand border-bottom'>
        <Container fluid>
          <div
            className={classnames(
              'pr-3 sidenav-toggler admin-navbar-toggle',
              { active: sidenavOpen },
              { 'sidenav-toggler-dark': theme === 'dark' }
            )}
            onClick={toggleSidenav}
          >
            <div className='sidenav-toggler-inner'>
              <i className='sidenav-toggler-line' />
              <i className='sidenav-toggler-line' />
              <i className='sidenav-toggler-line' />
            </div>
          </div>
          <Collapse navbar isOpen={true} className='d-flex justify-content-between '>
            <h2 className='text-white mt-2 brand-text'>{t('header.title_text')}</h2>
            <Nav className=' ml-auto ml-md-0 mr-2' navbar>
              {/* <NavItem>
                <NavLink className='nav-link-icon mt-3 mr-4' href='#pablo' onClick={(e) => e.preventDefault()}>
                  <i className='fa fa-bell' style={{ fontSize: 20 }} />
                </NavLink>
              </NavItem> */}

              {/* {LANGUAGES.map((lang, index) => {
                if (lang.value !== i18n.language)
                  return (
                    <span
                      onClick={() => changeLanguage(lang.value)}
                      key={'lange-' + index}
                      className='text-white mt-3 mr-3'
                    >
                      {lang?.name}
                    </span>
                  );
              })} */}
              <NavItem style={{ marginTop: '12px' }}>
                <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle nav>
                    <i className='fa fa-globe mr-1' style={{ fontSize: 16 }} />
                    {'  '}
                    {LANGUAGES.find((lang) => lang.value === i18n.language)?.name || 'Languages'}
                  </DropdownToggle>
                  <DropdownMenu style={{ cursor: 'pointer', width: '100px' }} className='p-0 m-0'>
                    {LANGUAGES.map((lang) => (
                      <DropdownItem
                        className='d-flex justify-content-around'
                        key={lang.value}
                        onClick={() => changeLanguage(lang.value)}
                      >
                        {lang.name} <img src={lang.icon} alt='flag' width={40} height={20} />
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </NavItem>

              <UncontrolledDropdown>
                <DropdownToggle style={{ background: 'none', border: 'none', zIndex: 0 }}>
                  <div className='navbar-dropdown'>
                    {previewUrl ? (
                      <img src={previewUrl} alt='Profile' />
                    ) : (
                      <img src='https://www.cargroup.org/wp-content/uploads/2017/02/Placeholder_Male-300x300.jpg' />
                    )}
                    <h6>{user?.name}</h6>
                  </div>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    {user?.rolename}
                    <br />({user?.branchname})
                  </DropdownItem>

                  <DropdownItem divider />

                  <DropdownItem onClick={toggleModal}>Update Profile</DropdownItem>
                  <DropdownItem onClick={togglePasswordModal}>Change Password</DropdownItem>

                  <DropdownItem divider />
                  <DropdownItem href='#pablo' onClick={() => logOutAction()}>
                    {t('logout')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Upload Profile Image</ModalHeader>
        <ModalBody className='d-flex align-items-center justify-content-center flex-column'>
          {/* ==== upload profile image ===== */}
          <div className='image-preview-container '>
            <label htmlFor='upload-input'>
              <div className='image-preview profile-img-container'>
                {cropImage ? (
                  <>
                    <img src={cropImage} alt='Profile' className='preview-image profile-img-preview' />
                  </>
                ) : (
                  <>
                    {previewUrl ? (
                      <img src={previewUrl} alt='Profile' className='preview-image profile-img-preview' />
                    ) : (
                      <div className='empty-preview profile-img-container'>
                        <span className='plus-sign'>+</span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </label>
            <input
              type='file'
              id='upload-input'
              onChange={(event) => {
                if (event.target.files[0]) {
                  setOpenCropper(true);
                  setImage(URL.createObjectURL(event.target.files[0]));
                }
              }}
              accept='image/*'
              style={{ display: 'none' }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={handleUpload}>
            Save
          </Button>{' '}
          <Button color='secondary' onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={passwordModal} toggle={togglePasswordModal}>
        <Form role='form' onSubmit={handleSubmit(submitData)}>
          <ModalHeader toggle={togglePasswordModal}>Change Password</ModalHeader>
          <ModalBody className='d-flex align-items-center justify-content-center flex-column'>
            {/* ======== change password ======== */}
            <div style={{ marginTop: '1rem', width: '60%' }}>
              <FormGroup>
                <InputGroup className='input-group-merge input-group-alternative'>
                  <TextBox
                    placeholder='Password'
                    type='password'
                    registerProps={register('password', {
                      required: 'Password is required',
                    })}
                    onChange={(e) => {
                      password.onChange(e);
                    }}
                    prepend
                    icon={<i className='ni ni-lock-circle-open' />}
                    errors={errors.password}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className='input-group-merge input-group-alternative'>
                  <TextBox
                    placeholder='Confirm Password'
                    type='password'
                    registerProps={register('confirm_password', {
                      required: 'Confirm Password is required',
                      validate: (val) => val === watch('password') || 'Your passwords do not match',
                    })}
                    prepend
                    icon={<i className='ni ni-lock-circle-open' />}
                    errors={errors.confirm_password}
                  />
                </InputGroup>
              </FormGroup>

              <div style={{ marginBottom: '12px' }}>
                {passwordValidateArr(currentPassword)?.map((data) => {
                  return <ValidatePassword key={data.content} content={data.content} isValid={data.isValid} />;
                })}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className='btn-primary' type='submit' disabled={!allowToChangePassword}>
              Change Password
            </Button>
            <Button color='secondary' onClick={togglePasswordModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      {openCropper && (
        <CropperModal
          isShow={openCropper}
          image={image}
          close={() => setOpenCropper(false)}
          setCroppedImage={async (image) => {
            setCropImage(image);
          }}
        />
      )}
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark',
};

export default AdminNavbar;
