import * as ShippingModeAction from 'services/shipping_mode';
import { SET_SHIPPINGMODE } from 'store/types/other_maintenance';
export const getAll = (query, refresh) => {
  return async (dispatch, getState) => {
    if (getState().other_maintenance.shipping_modes.length > 0 && !refresh) {
      return;
    }
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await ShippingModeAction.getAll(query);
    dispatch({
      type: SET_SHIPPINGMODE,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const save = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await ShippingModeAction.save(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const update = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await ShippingModeAction.update(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteData = (id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await ShippingModeAction.deleteData(id);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};
