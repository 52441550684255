import React from 'react';
import { Button } from 'reactstrap';
import Table from 'components/Table';
import clsx from 'clsx';
import { t } from 'i18next';
import EditButton from 'components/ActionButtons/editButton';
import DeleteButton from 'components/ActionButtons/deleteButton';
function List({ headers, data, onEdit, title, tableHeight, buttons, onDelete, filter, onPageChange, onSelect }) {
  const getIndex = (index) => {
    return index + data?.pagingCounter - 1;
  };

  return (
    <div className='address-table'>
      <h3>{title}</h3>
      <Table
        data={data}
        title={t('sidebar.information')}
        subtitle={t('address_book.title')}
        filter={filter}
        tableHeight={tableHeight}
        headers={headers.map((e) => (
          <th className={clsx('headers', e.className)} key={`thead-${Math.random()}`} style={{ width: e.width }}>
            {t(e.text)}
          </th>
        ))}
        onPageChange={onPageChange}
        buttons={buttons}
        rows={data?.docs?.map((e, index) => (
          <tr key={`table-row-${Math.random()}`}>
            <td className='fixed-cell left'>{getIndex(index + 1)}</td>
            <td className='fixed-cell left'>
              {' '}
              {e.customer_type === 'VIP' ? 'VIP-' : ''}
              {e.code}
            </td>
            <td>{e.customer_type} Customer</td>
            <td>{e.sender?.name}</td>
            <td>{e.sender?.phone}</td>
            <td style={{ width: 200 }}>{e.sender?.company}</td>
            {/* <td>{e.sender?.region.name}</td>
            <td>{e.sender?.township.name}</td> */}
            <td>{e.receiver?.name}</td>
            <td>{e.receiver?.phone}</td>
            <td style={{ width: 200 }}>{e.receiver?.company}</td>
            {/* <td>{e.receiver?.region.name}</td>
            <td>{e.receiver?.township.name}</td> */}
            <td style={{ width: 400 }}>{e.marketing_person?.name}</td>
            <td>{e.branch?.name}</td>
            <td className='fixed-cell'>
              {onSelect ? (
                <Button size='sm' color='success' onClick={() => onSelect(e)}>
                  <i className='fa fa-check-circle' />
                </Button>
              ) : (
                <>
                  <EditButton onClick={() => onEdit(e)} />
                  <DeleteButton onClick={() => onDelete(e)} />
                </>
              )}
            </td>
          </tr>
        ))}
      />
    </div>
  );
}

export default List;
