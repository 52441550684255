import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import List from './list';
import AddDialog from './addDialog';
import AddNewButton from 'components/ActionButtons/addNewButton';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import * as CollectionAction from 'store/actions/collection';
import { t } from 'i18next';

function Collection() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);

  const dispatch = useDispatch();

  const collections = useSelector((state) => state.other_maintenance.collections);

  const headers = ['no', 'name', 'created_at', ''];
  React.useEffect(() => {
    dispatch(CollectionAction.getAll({}));
  }, [CollectionAction.getAll, dispatch]);

  return (
    <>
      <List
        headers={headers}
        data={collections}
        onEdit={(data) => {
          setModalOpen(true);
          setEditData(data);
        }}
        onDelete={(data) => {
          setShowDelete(true);
          setEditData(data);
        }}
        buttons={
          <>
            <AddNewButton
              onClick={() => {
                setModalOpen(true);
                setEditData(null);
              }}
              type='collection'
            />

            <Button size='sm' style={{ width: 95 }} onClick={() => dispatch(CollectionAction.getAll({}, true))}>
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
          </>
        }
      />
      <AddDialog
        title={editData ? t('collection_management.edit_collection') : t('collection_management.add_collection')}
        editData={editData}
        modalOpen={modalOpen}
        onToggle={(open) => setModalOpen(open)}
        onSubmit={(values) => {
          setModalOpen(false);
          if (editData) dispatch(CollectionAction.update({ ...values, itemId: editData?._id }));
          else dispatch(CollectionAction.save(values));
          setTimeout(() => dispatch(CollectionAction.getAll({}, true)), 1000);
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={t('collection_management.delete_collection')}
        body={`${t('confirm_delete')} ${editData?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(CollectionAction.deleteData(editData?._id));
          setTimeout(() => dispatch(CollectionAction.getAll({}, true)), 1000);
        }}
      />
    </>
  );
}

export default Collection;
