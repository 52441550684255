import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import FilterBox from './filter';
import * as InformationAction from 'store/actions/information';
import * as SignAction from 'store/actions/sign';
import SignModal from './signModal';
import NotificationManager from 'react-notifications/lib/NotificationManager';

import List from './list';
import { getHeaders } from './header';
import { t } from 'i18next';

function SignConfirmation() {
  const dispatch = useDispatch();
  const router = useHistory();
  const signConfirms = useSelector((state) => state.waybill.signConfirms);
  const [searchValue, setSearchValue] = React.useState(null);
  const [selectedData, setSelectedData] = React.useState([]);
  const [signModal, setSignModal] = React.useState(false);

  React.useEffect(() => {
    dispatch(InformationAction.getBranch());
  }, [dispatch, InformationAction.getBranch]);

  React.useEffect(() => {
    loadData();
  }, [SignAction.getAll, router.location.search]);

  const loadData = (value = null) => {
    setSearchValue(value);
    let query = { limit: 10, page: 1 };
    if (router.location.search) query = queryString.parse(router.location.search);
    dispatch(SignAction.getAll(query, value ? value : searchValue ? searchValue : {}));
  };

  return (
    <>
      <List
        headers={getHeaders()}
        data={signConfirms}
        selectedData={selectedData}
        onSelect={(e) => {
          if (selectedData.find((s) => s._id === e._id)) setSelectedData(selectedData.filter((s) => s._id !== e._id));
          else setSelectedData([...selectedData, e]);
        }}
        filter={
          <FilterBox
            onSearch={(value) => {
              setSelectedData([]);
              loadData(value);
            }}
          />
        }
        buttons={
          <>
            <Button
              style={{ width: 100 }}
              size='sm'
              onClick={() => {
                loadData({});
                setSelectedData([]);
                router.push('/sign-confirmation');
              }}
            >
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
            <Button
              style={{ width: 100 }}
              size='sm'
              onClick={() => {
                if (selectedData.length === 1) {
                  setSignModal(true);
                } else {
                  NotificationManager.error('Please choose only one waybill to sign');
                }
              }}
            >
              <i className='fa fa-sign mr-2' /> {t('sidebar.sign')}
            </Button>
          </>
        }
      />
      <SignModal
        modalOpen={signModal}
        onToggle={() => setSignModal(!signModal)}
        onSubmit={(values) => {
          dispatch(SignAction.sign(values));
          setSelectedData([]);
          setSignModal(false);
          setTimeout(() => loadData(), 1000);
        }}
        data={selectedData[0]}
      />
    </>
  );
}

export default SignConfirmation;
