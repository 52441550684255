import React from 'react';
import Table from 'components/Table';
// import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import DeleteButton from 'components/ActionButtons/deleteButton';
import { useTranslation } from 'react-i18next';
import CheckBox from 'components/Inputs/CheckBox';

function List({
  headers,
  selectedData = [],
  data,
  onSelect,
  filter,
  onDelete,
  buttons,
  onActiveChange,
  // onWaybillPermssionChange,
}) {
  // const user = useSelector((state) => state.user.userInfo);
  const { t } = useTranslation();
  // const isActive = user?.rolename === 'Super Administrator' || user?.rolename === 'System Admin';

  return (
    <Table
      subtitle={t('system_user_management.user_management')}
      title={t('sidebar.system')}
      buttons={buttons}
      data={data}
      headers={headers.map((e) => (
        <th scope='col' key={`thead-${Math.random()}`}>
          {t(e)}
        </th>
      ))}
      filter={filter}
      rows={data?.map((e, index) => (
        <tr key={`table-row-${Math.random()}`}>
          <td>
            <CheckBox padding='mr-0' onChange={() => onSelect(e)} checked={selectedData.find((s) => s._id === e._id)} />
          </td>
          <td>{index + 1}</td>
          <td>{e.name}</td>
          <td>{e.phonenumber}</td>
          <td>{e.branch.name}</td>
          <td>{e.role.rolename}</td>
          <td>{!e.is_verified && <span>{e.temp_pwd}</span>}</td>
          <td>{dayjs(e.createdAt).format('DD/MM/YYYY HH:mm')}</td>
          <td>{e.is_verified ? <span className='text-success'>Yes</span> : <span className='text-danger'>No</span>}</td>
          <td>
            {e.active ? (
              <span
                className='text-success'
                style={{ cursor: 'pointer' }}
                onClick={() => onActiveChange({ status: false, _id: e._id })}
              >
                Yes
              </span>
            ) : (
              <span
                className='text-danger'
                style={{ cursor: 'pointer' }}
                onClick={() => onActiveChange({ status: true, _id: e._id })}
              >
                No
              </span>
            )}
          </td>
          <td className='d-flex justify-content-start'>
            {!e.is_verified && <DeleteButton onClick={() => onDelete(e)} type='user' />}
          </td>
        </tr>
      ))}
    />
  );
}

export default List;
