import * as SugestionService from 'services/suggestion';
import { SET_SUGGESTION } from 'store/types/other_maintenance';
export const getAll = (query, refresh) => {
  return async (dispatch, getState) => {
    if (getState().other_maintenance.suggestion.length > 0 && !refresh) {
      return;
    }
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await SugestionService.getAll();
    dispatch({
      type: SET_SUGGESTION,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};
