import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import clsx from 'clsx';
import { t } from 'i18next';
function DeleteAllConfirm({ modalOpen, onToggle, title, onConfirm, data, onRemoveSelected }) {
  return (
    <Modal isOpen={modalOpen} style={{ top: '25%' }} backdrop={true}>
      <div className=' modal-header'>
        <h5 className=' modal-title'>{title}</h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <h4>{t('confirm_delete')}?</h4>
        <br />
        {data.map((e, index) => (
          <div key={`waybillno-${index + 1}`} className='d-flex mb-3'>
            <span className={clsx(e.is_used && 'text-danger')}>{e.waybillnumber}</span>
            <span className='ml-4 text-danger' style={{ cursor: 'pointer' }} onClick={() => onRemoveSelected(e)}>
              X
            </span>
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button size='sm' color='success' onClick={() => onToggle(!modalOpen)}>
          {t('cancel')}
        </Button>
        <Button size='sm' color='primary' disabled={data.find((e) => e.is_used)} onClick={onConfirm}>
          {t('confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteAllConfirm;
