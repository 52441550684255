/* eslint-disable indent */
import EntryGrid from 'components/Inputs/EntryGrid';
import TextBox from 'components/Inputs/TextBox';
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, FormGroup } from 'reactstrap';
import * as InformationAction from 'store/actions/information';
import * as ShippingTypeAction from 'store/actions/shipping_type';
import * as ShippingModeAction from 'store/actions/shipping_mode';
import SelectBox from 'components/SelectBox';
import DatePicker from 'components/Inputs/DatePicker';
import { t } from 'i18next';

function BasicInfoForm({
  register,
  errors,
  control,
  editData,
  setValue,
  watch,
  getValues,
  onFetchWaybillNumber,
  // onSelectOrder,
  selectedOrder,
  selectedAddress,
}) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(ShippingTypeAction.getAll({}));
    dispatch(ShippingModeAction.getAll({}));
    dispatch(InformationAction.getBranch());
  }, [ShippingTypeAction.getAll, InformationAction.getBranch, dispatch]);

  const shipping_types = useSelector((state) => state.other_maintenance.shipping_types);

  const shippingTypeOptions = shipping_types?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  const branches = useSelector((state) => state.information.branches);
  const branchOptions = branches?.map((e) => {
    return {
      label: e.label,
      value: e._id,
    };
  });

  // const branchRawOptions = branches?.map((e) => {
  //   return {
  //     label: e.label,
  //     value: e.label,
  //   };
  // });

  const shipping_modes = useSelector((state) => state.other_maintenance.shipping_modes);
  const shippingModeOptions = shipping_modes?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  const clearences = useSelector((state) => state.information.clearences);
  const clearenceOption = clearences?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  // const marketing_persons = useSelector((state) => state.information.marketing_persons);
  // const marketingPersonOptions = marketing_persons?.map((e) => {
  //   return {
  //     label: e.label,
  //     value: e._id,
  //   };
  // });

  useEffect(() => {
    const selected = shipping_types.find((type) => type._id === watch('basicinfo.shipment_type'));
    setValue('basicinfo.shipment_type_name', selected?.name);
  }, [watch('basicinfo.shipment_type'), shipping_types]);

  return (
    <EntryGrid title={`${t('sidebar.order_entry')} : ${t('waybill_entry.order_information')}`}>
      <Row className='pt-1 pb-1'>
        <Col md={3} sm={6} xs={12}>
          <FormGroup>
            <label>{t('waybill_entry.order_number')}</label>
            <div className='d-flex'>
              <div style={{ flex: 1 }}>
                <TextBox
                  className='form-control mr-2'
                  type='text'
                  registerProps={register('basicinfo.waybill_no', {
                    required: `${t('waybill_entry.way_bill_no')} ${t('is_required')}`,
                  })}
                  errors={errors?.basicinfo?.waybill_no}
                  disabled={editData}
                />
              </div>
              <Button
                color='success'
                type='button'
                size='sm'
                style={{ maxHeight: 35 }}
                onClick={() => onFetchWaybillNumber()}
                disabled={editData || selectedOrder}
              >
                <i className='fa fa-plus text-white'></i>
              </Button>
            </div>
          </FormGroup>
        </Col>
        <Col md={3} sm={6} xs={12}>
          <FormGroup>
            <label>{t('waybill_entry.shipment_type')}</label>
            <SelectBox
              label={t('waybill_entry.shipment_type')}
              control={control}
              name='basicinfo.shipment_type'
              options={shippingTypeOptions}
              rules={{ required: true }}
            />
          </FormGroup>
        </Col>
        <Col md={3} sm={6} xs={12}>
          <FormGroup>
            <label>{t('waybill_entry.shipping_mode')}</label>
            <SelectBox
              label={t('waybill_entry.shipping_mode')}
              control={control}
              name='basicinfo.shipping_mode'
              options={shippingModeOptions}
              rules={{ required: true }}
            />
          </FormGroup>
        </Col>
        <Col md={3} sm={6} xs={12}>
          <FormGroup>
            <label>{t('waybill_entry.delivery_mode')}</label>
            <SelectBox
              control={control}
              name='basicinfo.delivery_mode'
              options={[
                { value: 'self', label: 'Self' },
                { value: 'delivery', label: 'Delivery' },
              ]}
              label={t('waybill_entry.delivery_mode')}
              rules={{ required: true }}
            />
          </FormGroup>
        </Col>
        <Col md={3} sm={6} xs={12}>
          <FormGroup>
            <label>{t('sidebar.custom_clearence')}</label>
            <SelectBox
              label={t('sidebar.custom_clearence')}
              control={control}
              name='basicinfo.custom_clearence'
              options={clearenceOption}
              rules={{ required: true }}
            />
          </FormGroup>
        </Col>
        {/* TODO: testing */}
        <Col md={2} sm={6} xs={12}>
          <FormGroup>
            <label>{t('waybill_entry.delivery_place')}</label>
            <SelectBox
              label={t('waybill_entry.delivery_place')}
              control={control}
              name='basicinfo.createdBranch'
              options={branchOptions}
              rules={{ required: true }}
            />
          </FormGroup>
        </Col>

        <Col md={2} sm={6} xs={12}>
          <FormGroup>
            <label>{t('waybill_entry.destination')}</label>
            <SelectBox
              label={t('waybill_entry.destination')}
              control={control}
              name='basicinfo.destination'
              options={branchOptions}
              disabled
              rules={{ required: true }}
            />
          </FormGroup>
        </Col>

        <Col md={2} sm={6} xs={12}>
          <FormGroup>
            <label>{t('waybill_entry.sale_person')}</label>

            <TextBox
              value={
                selectedAddress
                  ? selectedAddress?.marketing_person?.name
                  : selectedOrder
                  ? selectedOrder?.sale_person?.name
                  : editData?.sale_person?.name
              }
              disabled
            />
          </FormGroup>
        </Col>
        <Col md={2} sm={6} xs={12}>
          <FormGroup>
            <label>{t('waybill_entry.order_date')}</label>
            <DatePicker
              onChange={(date) => {
                setValue('basicinfo.shipping_date', date);
              }}
              initialValue={getValues('basicinfo.shipping_date')}
            />
          </FormGroup>
        </Col>
      </Row>
    </EntryGrid>
  );
}
export default BasicInfoForm;
