import React from 'react';
import { Button, Container } from 'reactstrap';
import { t } from 'i18next';
import ReactToPrint from 'react-to-print';
import A5ENPrintComponent from './a5_en_print_component';
function PrintButton({ selectedData }) {
  const a5Ref = React.useRef();
  const pageStyle = `
    @page {
      size: A4 landscape;
    }
  `;
  return (
    <>
      <ReactToPrint
        pageStyle={pageStyle}
        documentTitle={selectedData[0]?.waybillnumber}
        trigger={() => {
          return (
            <Button style={{ width: 100 }} size='sm' onClick={() => {}} disabled={selectedData.length !== 1}>
              <i className='fa fa-file-archive mr-2' />
              {t('print')}
            </Button>
          );
        }}
        content={() => {
          return a5Ref.current;
        }}
      />

      <Container fluid style={{ display: 'none' }}>
        <A5ENPrintComponent data={selectedData[0]} ref={a5Ref} />
      </Container>
    </>
  );
}

export default PrintButton;
