import * as SignService from 'services/sign';
import { SET_SIGN_CONFIRMS, SET_SIGN_LIST } from 'store/types/waybill';
import { saveAs } from 'file-saver';
export const getAll = (query, body) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await SignService.getAll(query, body);
    dispatch({
      type: SET_SIGN_CONFIRMS,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const sign = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await SignService.sign(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const getAllSigned = (query, body) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await SignService.getAllSigned(query, body);
    dispatch({
      type: SET_SIGN_LIST,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export function exportSignQuery() {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    const response = await SignService.exportData();

    const blob = await response;

    saveAs(blob, 'exported-signquery.csv');

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
}

export const deleteData = (id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await SignService.deleteData(id);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};
