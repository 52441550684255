import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import CheckBox from 'components/Inputs/CheckBox';

export default function WaybillPermssionDialog({ modalOpen, onToggle, editData, onPermissionChange }) {
  const branches = useSelector((state) => state.information.branches);

  const [permission, setPermission] = React.useState([]);

  React.useEffect(() => {
    if (editData) {
      setPermission(editData?.waybill_permission);
    }
  }, [editData]);

  return (
    <Modal isOpen={modalOpen} style={{ top: '25%' }} backdrop={true}>
      <div className=' modal-header'>
        <h5 className=' modal-title'>
          {t('system_user_management.waybill_permission')} - {editData?.name}
        </h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        {branches.map((branch, index) => (
          <div className='py-2' key={`branch-checkbox-${index + 1}`}>
            <CheckBox
              label={branch?.label}
              onChange={() => {
                if (permission.find((e) => e === branch?._id)) {
                  let temp = permission;
                  temp = temp.filter((e) => e !== branch?._id);
                  setPermission(temp);
                  onPermissionChange(temp);
                } else {
                  setPermission([...permission, branch?._id]);
                  onPermissionChange([...permission, branch?._id]);
                }
              }}
              checked={permission.includes(branch?._id)}
            />
          </div>
        ))}
      </ModalBody>
    </Modal>
  );
}
