// eslint-disable-next-line no-undef
export const CACHE_KEY = process.env.REACT_APP_CACHE_KEY;

const np = CACHE_KEY || '__easyThaiLiveB#$__';

export function storeCache(name, value) {
  localStorage.setItem(`${np}${name}`, value);
}

export function getCache(name) {
  return localStorage.getItem(`${np}${name}`);
}

export function removeCache(name) {
  return localStorage.removeItem(`${np}${name}`);
}
