import React from 'react';
import { Modal, ModalBody, Row, Col, FormGroup, Button } from 'reactstrap';
import Select from 'react-select';
import ReactToPrint from 'react-to-print';
import A5ENPrintComponent from './../A5PrintComponent/a5_en_print_component';
import A5CNPrintComponent from './../A5PrintComponent/a5_cn_print_component';
import { t } from 'i18next';
function ChooseVoucherTypeDialog({ modalOpen, onToggle, selectedData }) {
  const [voucherType, setVoucherType] = React.useState(null);

  const chnRef = React.useRef();
  const a5Ref = React.useRef();

  return (
    <>
      <Modal isOpen={modalOpen} size='md' backdrop={true}>
        <div>
          <button
            style={{ position: 'relative', top: 10, right: 20 }}
            aria-label='Close'
            className=' close'
            type='button'
            onClick={() => onToggle(!modalOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody style={{ overflowY: 'auto' }}>
          <Row style={{ paddingBottom: 55 }}>
            <Col md={8} sm={6} xs={12}>
              <FormGroup>
                <label>{t('voucher_type')}</label>
                <Select
                  options={[
                    { value: 'en', label: 'En-Voucher' },
                    { value: 'cn', label: 'Cn-Voucher' },
                  ]}
                  label={t('voucher_type')}
                  onChange={(value) => {
                    setVoucherType(value.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={6} xs={12}>
              <ReactToPrint
                onAfterPrint={() => {
                  onToggle(!modalOpen);
                }}
                trigger={() => {
                  return (
                    <Button disabled={voucherType == null} style={{ height: 40, marginTop: 30 }}>
                      {t('print')}
                    </Button>
                  );
                }}
                content={() => {
                  if (voucherType === 'cn') {
                    return chnRef.current;
                  } else {
                    return a5Ref.current;
                  }
                }}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <div style={{ display: 'none' }}>
        <A5ENPrintComponent ref={a5Ref} data={selectedData} />
      </div>
      <div style={{ display: 'none' }}>
        <A5CNPrintComponent ref={chnRef} data={selectedData} />
      </div>
    </>
  );
}
export default ChooseVoucherTypeDialog;
