import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import List from './list';
import AddDialog from './addDialog';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import * as ShippingModeAction from 'store/actions/shipping_mode';
import { t } from 'i18next';
import AddNewButton from 'components/ActionButtons/addNewButton';
function ShippingMode() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);

  const dispatch = useDispatch();

  const shipping_modes = useSelector((state) => state.other_maintenance.shipping_modes);

  const headers = ['no', 'name', 'created_at', ''];
  React.useEffect(() => {
    dispatch(ShippingModeAction.getAll({}));
  }, [ShippingModeAction.getAll, dispatch]);

  return (
    <>
      <List
        headers={headers}
        data={shipping_modes}
        onEdit={(data) => {
          setModalOpen(true);
          setEditData(data);
        }}
        onDelete={(data) => {
          setShowDelete(true);
          setEditData(data);
        }}
        buttons={
          <>
            <AddNewButton
              onClick={() => {
                setModalOpen(true);
                setEditData(null);
              }}
              type='shipping_mode'
            />
            <Button size='sm' style={{ width: 95 }} onClick={() => dispatch(ShippingModeAction.getAll({}, true))}>
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
          </>
        }
      />
      <AddDialog
        title={
          editData
            ? `${t('edit')} ${t('information_other_shipping_mode.shipping_mode')}`
            : t('information_other_shipping_mode.create_new_shipping_mode')
        }
        editData={editData}
        modalOpen={modalOpen}
        onToggle={(open) => setModalOpen(open)}
        onSubmit={(values) => {
          setModalOpen(false);
          if (editData)
            dispatch(
              ShippingModeAction.update({
                ...values,
                shippingModeId: editData?._id,
              })
            );
          else dispatch(ShippingModeAction.save(values));
          setTimeout(() => dispatch(ShippingModeAction.getAll({}, true)), 1000);
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`${t('delete')} ${t('information_other_shipping_mode.shipping_mode')}`}
        body={`${t('confirm_delete')} ${editData?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(ShippingModeAction.deleteData(editData?._id));
          setTimeout(() => dispatch(ShippingModeAction.getAll({}, true)), 1000);
        }}
      />
    </>
  );
}
export default ShippingMode;
