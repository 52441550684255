import React from 'react';
import * as CustomClearenceAction from 'store/actions/customClearence';
import { Button, Form, Row, Col, FormGroup } from 'reactstrap';
import * as EmployeeAction from 'store/actions/employee';
import { getCountryOption } from 'store/actions/location';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getCargoType } from 'utli';
import { fetchWaybillNumber } from 'services/order';
import * as WaybillAction from 'store/actions/waybill';
import EntryGrid from 'components/Inputs/EntryGrid';
import BasicInfoForm from '../basicInfoForm';
import ItemInfoForm from '../itemInfoForm';
import CostInfoForm from '../costInfoForm';

import ImageUploadContainer from '../ImageUploadContainer';
import OrderImageUploadContainer from 'pages/order/ImageUploadContainer';
import AdressInputForm from 'pages/information/addressbook/inputForm';
import OrderSelectModal from 'pages/order/selectModal';
import TextBox from 'components/Inputs/TextBox';
import { t } from 'i18next';
import './index.scss';
import useActionPermission from 'hooks/useActionPermission';
import { NotificationManager } from 'react-notifications';
function EntryForm({ editData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    getValues,
    watch,
  } = useForm();

  const [basicFreightError, setBasicFreightError] = React.useState(false);

  const user = useSelector((state) => state.user.userInfo);
  const submitData = async (values) => {
    if (isDeparAndDestiEqual) {
      NotificationManager.error('Departure and Destination cannot be the same');
      return;
    }

    if (values.basicinfo.shipment_type_name === 'Full Loading') {
      if (!values.costinfo.org_basic_freight) {
        setBasicFreightError(true);
        return;
      }
    }

    //reset()
    const uploadFiles = {
      orderImageFiles: orderImageFiles,
      deliveryImageFiles: deliveryImageFiles,
    };

    setDeliveryImageFiles([]);
    setOrderImageFiles([]);

    values.waybillnumber = values.basicinfo?.waybill_no;

    if (editData) {
      values.original_waybill_id = editData._id;
      values.waybillnumber = editData.basicinfo?.waybill_no;
    }
    if (selectedOrder) {
      values.basicinfo._id = selectedOrder._id;
      values.waybillnumber = selectedOrder.waybillnumber;
    }
    dispatch(WaybillAction.save(values, uploadFiles));
  };
  const [orderImageFiles, setOrderImageFiles] = React.useState([]);
  const [deliveryImageFiles, setDeliveryImageFiles] = React.useState([]);
  const [openSelectModal, setSelectModal] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState(false);
  const [selectedAddress, setSelectedAddress] = React.useState(false);
  const { canAdd } = useActionPermission('document');
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.location.countries);

  const departure = watch('basicinfo.createdBranch');
  const destination = watch('basicinfo.destination');
  const isDeparAndDestiEqual = departure !== undefined && departure === destination;

  React.useEffect(() => {
    if (isDeparAndDestiEqual) {
      NotificationManager.error('Departure and Destination cannot be the same');
    }
  }, [isDeparAndDestiEqual]);

  const setInitValue = (data) => {
    setValue('basicinfo.waybill_no', data?.waybillnumber);
    setValue('basicinfo.shipment_type', data?.shipment_type._id);
    setValue('basicinfo.shipping_mode', data?.shipping_mode._id);
    setValue('basicinfo.delivery_mode', data?.delivery_mode);
    setValue('basicinfo.delivery_place', user?.branchname);

    setValue('basicinfo.createdBranch', data?.createdBranch?._id);
    setValue('basicinfo.delivery_no', data?.delivery_no);
    setValue('basicinfo.delivery_company', data?.delivery_company);
    setValue('basicinfo.transfer_no', data?.transfer_no);
    setValue('basicinfo.custom_clearence', data?.custom_clearence._id);
    setValue('basicinfo.transfer_company', data?.transfer_company);
    setValue('basicinfo.destination', data?.destination._id);
    setValue('basicinfo.date', data?.shipping_date);
    setValue('iteminfo', data?.item);
    setValue('iteminfo.itemtype', data?.item?.itemtype?._id);

    setValue('costinfo', data?.cost);

    setValue('costinfo.paymentType', data?.cost.paymentType._id);
    setValue('senderinfo', data?.sender);
    setValue('senderinfo.code', data?.address_code);
    setValue('senderinfo.marketing_person', data?.sale_person?._id);

    setValue('receiverinfo', data?.receiver);

    let result = getCargoType(getValues('iteminfo.weight') / getValues('iteminfo.volume'));

    if (result === 'Light Cargo') {
      setValue('costinfo.qty_basic_freight_label', 'Cbm');
    } else {
      setValue('costinfo.qty_basic_freight_label', 'Kg');
    }

    setValue('iteminfo.cargo_type', result);
  };

  React.useEffect(() => {
    if (editData) {
      setInitValue(editData);
    } else {
      reset();
    }
  }, [editData]);

  React.useEffect(() => {
    dispatch(EmployeeAction.getMarketingPerson());
    dispatch(getCountryOption());
    dispatch(CustomClearenceAction.getAll({}, true));
  }, [dispatch, CustomClearenceAction.getAll, EmployeeAction.getMarketingPerson, getCountryOption]);

  React.useEffect(() => {
    // setValue('basicinfo.createdBranch', user?.branchInfo?._id)
    // setValue('basicinfo.createdBranchName', user?.branchInfo?.name)
  }, []);

  const onFetchWaybillNumber = async () => {
    const response = await fetchWaybillNumber();
    if (response) {
      setValue('generated_waybill_number', response);
      setValue('basicinfo.waybill_no', response.waybillnumber);
    }
  };

  return (
    <Form onSubmit={handleSubmit(submitData)} className='waybill-form'>
      <BasicInfoForm
        register={register}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        getValues={getValues}
        editData={editData}
        onFetchWaybillNumber={onFetchWaybillNumber}
        onSelectOrder={() => {
          setSelectModal(true);
        }}
        selectedOrder={selectedOrder}
        selectedAddress={selectedAddress}
      />
      <div className='divider'></div>
      <AdressInputForm
        register={register}
        errors={errors}
        control={control}
        options={{ countries: countries }}
        editData={editData}
        addNew={false}
        setValue={setValue}
        types={['senderinfo', 'receiverinfo']}
        getValues={getValues}
        selectedOrder={selectedOrder}
        onSelectAddress={(address) => {
          setSelectedAddress(address);
        }}
      />
      <div className='divider'></div>
      <ItemInfoForm
        register={register}
        control={control}
        errors={errors}
        selectedOrder={selectedOrder}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        editData={editData}
      />
      <div className='divider'></div>

      <Row style={{ padding: 0, margin: 0 }}>
        <Col md='12'>
          <CostInfoForm
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            basicFreightError={basicFreightError}
          />
        </Col>
      </Row>
      {selectedOrder && (
        <>
          <div className='divider'></div>
          <EntryGrid title={t('waybill_entry.order_file_upload')}>
            <Row style={{ padding: 0, margin: 0 }}>
              <Col md='4'>
                <OrderImageUploadContainer
                  networkFiles={selectedOrder?.order_file_names}
                  showUpload={false}
                  title={t('waybill_entry.packaging_list')}
                />
              </Col>
            </Row>
          </EntryGrid>
        </>
      )}

      <EntryGrid title={t('waybill_entry.file_upload')}>
        <Row style={{ padding: 0, margin: 0 }}>
          <Col md='4'>
            <ImageUploadContainer
              files={orderImageFiles}
              setFiles={setOrderImageFiles}
              showUpload={true}
              networkFiles={editData?.order_file_names}
              waybillnumber={editData?._id}
              title={t('waybill_entry.order_file_upload')}
              type='order'
            />
          </Col>
          <Col md='4'>
            <ImageUploadContainer
              files={deliveryImageFiles}
              setFiles={setDeliveryImageFiles}
              showUpload={true}
              networkFiles={editData?.delivery_file_names}
              waybillnumber={editData?._id}
              title={t('waybill_entry.delivery_file_upload')}
              type='order'
              accept='.png, .jpg, .jpeg'
            />
          </Col>
          <Col md='2' className='mt-1'>
            <FormGroup>
              <label>{t('waybill_entry.delivery_company')}</label>
              <TextBox
                className='form-control mr-2'
                type='text'
                label={t('waybill_entry.delivery_company')}
                registerProps={register('basicinfo.delivery_company')}
                errors={errors?.basicinfo?.delivery_company}
              />
            </FormGroup>
          </Col>
          <Col md='2' className='mt-1'>
            <FormGroup>
              <label>{t('waybill_entry.delivery_no')}</label>
              <TextBox
                className='form-control mr-2'
                type='text'
                label={t('waybill_entry.delivery_no')}
                registerProps={register('basicinfo.delivery_no')}
                errors={errors?.basicinfo?.delivery_no}
              />
            </FormGroup>
          </Col>
        </Row>
      </EntryGrid>

      <div className='d-flex justify-content-center'>
        <Button
          color='success'
          type='button'
          onClick={() => {
            setSelectedOrder(false);
            reset();
          }}
          size='sm'
        >
          {t('reset')}
        </Button>
        {canAdd() && (
          <Button color='primary' type='submit' size='sm'>
            {t('submit')}
          </Button>
        )}
      </div>
      <OrderSelectModal
        modalOpen={openSelectModal}
        onToggle={() => setSelectModal(!openSelectModal)}
        onSelect={(s) => {
          setSelectedOrder(s);
          setInitValue(s);
          setSelectModal(!openSelectModal);
        }}
      />
    </Form>
  );
}

export default EntryForm;
