import { getData } from './apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';
export async function getAll() {
  try {
    let response = await getData('frontend/quotes');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}
