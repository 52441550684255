import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import List from './list';
import AddDialog from './addDialog';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import * as ShippingTypeAction from 'store/actions/shipping_type';
import { t } from 'i18next';
import AddNewButton from 'components/ActionButtons/addNewButton';

function ShippingType() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);

  const dispatch = useDispatch();

  const shipping_types = useSelector((state) => state.other_maintenance.shipping_types);

  const headers = ['no', 'name', 'created_at', 'updated_at', ''];
  React.useEffect(() => {
    dispatch(ShippingTypeAction.getAll({}));
  }, [ShippingTypeAction.getAll, dispatch]);

  return (
    <>
      <List
        headers={headers}
        data={shipping_types}
        onEdit={(data) => {
          setModalOpen(true);
          setEditData(data);
        }}
        onDelete={(data) => {
          setShowDelete(true);
          setEditData(data);
        }}
        buttons={
          <>
            <AddNewButton
              onClick={() => {
                setModalOpen(true);
                setEditData(null);
              }}
              type='shipping_type'
            />
            <Button style={{ width: 95 }} size='sm' onClick={() => dispatch(ShippingTypeAction.getAll({}, true))}>
              <i className='fa fa-spinner mr-2' />
              {t('refresh')}
            </Button>
          </>
        }
      />
      <AddDialog
        title={
          editData
            ? `${t('edit')} ${t('sidebar.Shipping Type Maintenance')}`
            : `${t('add')} ${t('sidebar.Shipping Type Maintenance')}`
        }
        editData={editData}
        modalOpen={modalOpen}
        onToggle={(open) => setModalOpen(open)}
        onSubmit={(values) => {
          setModalOpen(false);
          if (editData)
            dispatch(
              ShippingTypeAction.update({
                ...values,
                shippingTypeId: editData?._id,
              })
            );
          else dispatch(ShippingTypeAction.save(values));
          setTimeout(() => dispatch(ShippingTypeAction.getAll({}, true)), 1000);
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`${t('delete')} ${t('sidebar.Shipping Type Maintenance')}`}
        body={`${t('confirm_delete')} ${editData?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(ShippingTypeAction.deleteData(editData?._id));
          setTimeout(() => dispatch(ShippingTypeAction.getAll({}, true)), 1000);
        }}
      />
    </>
  );
}

export default ShippingType;
