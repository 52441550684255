import * as QuoteService from 'services/quote';
import { SET_QUOTE } from 'store/types/other_maintenance';
export const getAll = (query, refresh) => {
  return async (dispatch, getState) => {
    if (getState().other_maintenance.quotes.length > 0 && !refresh) {
      return;
    }
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await QuoteService.getAll();
    dispatch({
      type: SET_QUOTE,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};
