import * as WayService from 'services/way';
import { saveAs } from 'file-saver';
import { SET_WAYS, SET_WAY_OPTIONS, SET_WAY_CARGOS, SET_END_WAYS } from 'store/types/cargo';
export const getAll = (body, started_way = 0) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await WayService.getAll(body, started_way);

    if (started_way) {
      dispatch({
        type: SET_WAYS,
        payload: response,
      });
    } else {
      dispatch({
        type: SET_END_WAYS,
        payload: response,
      });
    }

    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const getOptions = (started_way = 0) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await WayService.getOptions(started_way);
    dispatch({
      type: SET_WAY_OPTIONS,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const getWayCargo = (id, query) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await WayService.getWayCargo(id, query);
    dispatch({
      type: SET_WAY_CARGOS,
      payload: response.data,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const exportWayCargo = (id, filename) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    const response = await WayService.exportWayCargo(id);

    const blob = await response;

    saveAs(blob, `exported-${filename}.csv`);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const save = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await WayService.save(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteData = (id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await WayService.deleteData(id);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const update = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await WayService.update(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const confirm = (id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await WayService.confirm(id);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const end = (id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await WayService.end(id);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};
