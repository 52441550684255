/* eslint-disable indent */
import React from 'react';
import { Row, Col, Button, Input } from 'reactstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import DateRangePicker from 'components/Inputs/DateRangePicker';
import { t } from 'i18next';

function FilterBox({ onSearch, type }) {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };

  const branches = useSelector((state) => state.information.branches);
  let branchOptions = [];
  if (branches.map) {
    branchOptions = branches?.map((e) => {
      return {
        label: e.label,
        value: e._id,
      };
    });
  }

  const ways = useSelector((state) => state.cargo.way_options);
  let wayOptions = [];
  if (ways.map) {
    wayOptions = ways?.map((e) => {
      return {
        label: e.label,
        value: e._id,
      };
    });
  }

  const waybills = useSelector((state) => state.cargo.waybill_options);
  let waybillOptions = [];
  if (waybills.map) {
    waybillOptions = waybills?.map((e) => {
      return {
        label: e.label,
        value: e.value,
      };
    });
  }

  // const containers = useSelector((state) => state.other_maintenance.containers);
  // let containerOptions = [];
  // if (containers.map) {
  //   containerOptions = containers?.map((e) => {
  //     return {
  //       label: e.name,
  //       value: e.name,
  //     };
  //   });
  // }
  const [filterWaybill, setFilterWaybill] = React.useState('');
  // const waybills = useSelector((state) => state.cargo.waybill_options);
  // let waybillOptions = [];
  // if (waybills.map) {
  //   waybillOptions = waybills?.map((e) => {
  //     return {
  //       label: e.label,
  //       value: e.value,
  //     };
  //   });
  // }

  const [searchType, setSearchType] = React.useState('train_number');
  const [search, setSearch] = React.useState(null);
  const [dateSearch, setDateSearch] = React.useState();

  return (
    <>
      <Row>
        <Col className='mb-1' md='5' sm='6'>
          {searchType === 'train_number' && (
            <Select
              styles={customStyles}
              options={wayOptions}
              onChange={(value) => setSearch(value.value)}
              value={wayOptions.find((b) => b._id === search)}
              placeholder=''
            />
          )}

          {(searchType === 'waybill_number' || searchType === 'truck_number') && (
            <Input
              styles={customStyles}
              style={{ height: 34 }}
              value={filterWaybill}
              onChange={(e) => {
                setFilterWaybill(e.target.value);
                setSearch(e.target.value);
              }}
            />
          )}

          {searchType === 'waybill_number' && (
            <Select
              styles={customStyles}
              options={waybillOptions}
              onChange={(value) => setSearch(value.value)}
              value={waybillOptions.find((b) => b._id === search)}
              placeholder=''
            />
          )}

          {['destination', 'departure'].includes(searchType) && (
            <Select
              styles={customStyles}
              options={branchOptions}
              onChange={(value) => setSearch(value.value)}
              value={branchOptions.find((b) => b._id === search)}
              placeholder=''
            />
          )}
        </Col>
        <Col className='mb-1' md='2' sm='6'>
          <Select
            styles={customStyles}
            defaultValue={{
              value: 'train_number',
              label: t('cargo_loading.train_number'),
            }}
            placeholder=''
            onChange={(val) => {
              setSearch('');
              setSearchType(val.value);
            }}
            options={[
              {
                value: 'train_number',
                label: t('cargo_loading.train_number'),
              },
              { value: 'truck_number', label: t('cargo_loading.container') },
              type === 'unloading'
                ? {
                    value: 'departure',
                    label: t('waybill_entry.depature_branch'),
                  }
                : {
                    value: 'destination',
                    label: t('waybill_entry.destination_text'),
                  },
              {
                value: 'departure',
                label: t('waybill_entry.depature_branch'),
              },
              {
                value: 'waybill_number',
                label: t('cargo_loading.waybill_number'),
              },
            ]}
          />
        </Col>
        <Col className='mb-1' md='4' sm='6' xs='10'>
          <DateRangePicker
            onDateChange={(dates) => {
              setDateSearch({
                startDate: dates[0],
                endDate: dates[1],
              });
            }}
          />
        </Col>
        {searchType && (
          <Button
            size='sm'
            color='success'
            style={{ height: 35 }}
            onClick={() => {
              if (search || dateSearch) {
                // setSearch('')
                const searchData = {};
                if (search) searchData[searchType] = search;
                if (dateSearch?.startDate) {
                  searchData.startDate = dateSearch.startDate;
                  searchData.endDate = dateSearch.endDate || new Date();
                }
                onSearch(searchData);
              }
            }}
          >
            <i className='fa fa-search' />
          </Button>
        )}
      </Row>
    </>
  );
}

export default FilterBox;
