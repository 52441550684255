import React from 'react';
import { Modal, ModalBody, Button, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';

import InputForm from './inputForm';
import { t } from 'i18next';

export default function AddDialog({ modalOpen, onToggle, title, onSubmit, editData, options }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    control,
  } = useForm();

  const submitData = async (values) => {
    reset();
    if (typeof values.customer_type === 'object') {
      values.customer_type = values.customer_type.value;
    }
    onSubmit(values);
  };

  React.useEffect(() => {
    if (editData) {
      setValue('code', editData?.code);
      setValue('marketing_person', editData?.marketing_person?._id);
      setValue('branch', editData?.branch?._id);
      setValue('customer_type', editData?.customer_type?._id);
      setValue('sender.name', editData?.sender.name);
      setValue('sender.phone', editData?.sender.phone);
      setValue('sender.address', editData?.sender.address);
      setValue('sender.company', editData?.sender.company);

      setValue('receiver.name', editData?.receiver.name);
      setValue('receiver.phone', editData?.receiver.phone);
      setValue('receiver.address', editData?.receiver.address);
      setValue('receiver.company', editData?.receiver.company);

      setValue('customer_type', editData?.customer_type);
    } else {
      reset();
    }
  }, [editData]);

  return (
    <Modal isOpen={modalOpen} size='lg' style={{ maxWidth: '1000px', width: '100%', top: '2%' }} backdrop={true}>
      <div className=' modal-header'>
        <h5 className=' modal-title'>
          {title} {editData?.name}
        </h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitData)}>
          <InputForm
            register={register}
            errors={errors}
            control={control}
            options={options}
            editData={editData}
            setValue={setValue}
            getValues={getValues}
          />
          <div className='text-center'>
            <Button color='success' type='button' onClick={() => onToggle(!modalOpen)}>
              {t('cancel')}
            </Button>
            <Button color='primary' type='submit'>
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
